import { MaterialSupplier } from 'api/search'

export const MAX_NUMBER_LIMIT_FOR_CHARTS = 1000000
export const DEFAULT_UNIT = 'KGS' as const
export const DEFAULT_SELECTED_PERCENTILE = 10 as const
export enum DATE_FORMAT {
  yyyyMM = 'yyyy-MM',
  "MMM 'yy" = "MMM ''yy",
  'yyyy-MM-dd' = 'yyyy-MM-dd',
}
export const ACCEPTED_PERCENTILE_VALUES = [0, 5, 10, 15, 20, 25]
export const companyTypeAbbreviations = {
  M: 'Manufacturer',
  T: 'Trader',
  C: 'Catalog',
  CS: 'Custom Synthesis',
  CR: 'Contract Research',
  CM: 'Custom Manufacturer',
}
export const AUTH_ACCESS_TOKEN_NAME = '_mid-access-token'
export const ADMIN_GROUP = 'gpp:admin'
export const TRIAL_USER_GROUP = 'gpp:limited-user'
export const pagePaths = {
  home: '/home',
  search: '/search',
  searchResults: '/search-results',
  review: '/review',
  vendor: '/vendor',
  admin: '/admin',
  authCallback: '/auth/callback',
  login: '/login',
} as const

export const LOCALES = {
  'ar-SA': 'Arabic (Saudi Arabia)',
  'bn-BD': 'Bangla (Bangladesh)',
  'bn-IN': 'Bangla (India)',
  'cs-CZ': 'Czech (Czech Republic)',
  'de-AT': 'Austrian German',
  'de-DE': 'Standard German',
  'en-AU': 'Australian English',
  'en-GB': 'British English',
  'en-IN': 'Indian English',
  'en-US': 'US English',
  'en-ZA': 'English (South Africa)',
  'jp-JP': 'Japanese (Japan)',
  'ko-KR': 'Korean (Republic of Korea)',
}
export const CURRENCY_SYMBOLS = {
  USD: '$',
  EUR: '€',
  JPY: '￥',
  INR: '₹',
  GBP: '£',
  CNY: 'CN¥',
} as const

export type CurrencySymbols = typeof CURRENCY_SYMBOLS

export type CurrencyCode = keyof CurrencySymbols

export const CURRENCY_CODE: { [code in CurrencyCode]: code } = {
  USD: 'USD',
  EUR: 'EUR',
  JPY: 'JPY',
  INR: 'INR',
  GBP: 'GBP',
  CNY: 'CNY',
}

export const CURRENCY_CODES_LIST = Object.keys(CURRENCY_SYMBOLS) as Array<
  CurrencyCode
>

export const WEIGHT_LABELS = {
  KGS: 'KG',
  MT: 'MT',
} as const

export const WEIGHT_SYMBOLS = {
  KGS: 'KGS',
  MT: 'MT',
} as const

export type WeightSymbols = typeof WEIGHT_SYMBOLS

export type WeightCode = keyof WeightSymbols

export const WEIGHT_SYMBOLS_LIST = Object.keys(WEIGHT_SYMBOLS) as Array<
  keyof WeightSymbols
>

export const WHITELIST_NAMES = ['NOT KNOWN', 'NOT FOUND']

export const MATERIAL_SUPPLIER_PROPS: {
  [prop in keyof MaterialSupplier]: prop
} = {
  accrediation: 'accrediation',
  beDate: 'beDate',
  customers: 'customers',
  included: 'included',
  supplierCountry: 'supplierCountry',
  name: 'name',
  quantity: 'quantity',
  savingsPercentage: 'savingsPercentage',
  unitPrice: 'unitPrice',
  supplierId: 'supplierId',
  unit: 'unit',
  eximDataId: 'eximDataId',
  id: 'id',
  searchItemId: 'searchItemId',
  searchId: 'searchId',
  isExpertData: 'isExpertData',
  productDescription: 'productDescription',
  hsCode: 'hsCode',
  txnType: 'txnType',
}

export const TRIAL_USER_SEARCH_DATE_RANGE = {
  fromDateISO: '2019-01-01T00:00:00.000Z',
  toDateISO: '2019-12-31T00:00:00.000Z',
} as const
export const TRIAL_USER_MAX_SEARCH_COUNT = 10

export const DEFUALT_FOR_EMPTY_OR_NULL = 'NOT KNOWN'

export const PROD_BACKEND_SERVICE_URL = 'https://psse.mckinsey.com/api'

// Note:- Only use URL's ending with .mdl.cloud for local dev backend service
// .mckinsey.digital cant be configured to CORS allow localhost.
export const LOCAL_DEV_BACKEND_SERVICE_URL =
  'https://gpp-org-gpp-backend.alpha.mdl.cloud'

// export const LOCAL_DEV_BACKEND_SERVICE_URL = 'http://localhost:8080'
