import React from 'react'
import { Table, Row } from 'antd'
import { ColumnsType } from 'antd/lib/table'
import {
  MaterialBuyer,
  fetchMaterialBuyers,
  MaterialBuyersAPIData,
} from 'api/molecules'
import { CustomColumnFilterDropdown } from '../shared/CustomColumnFilterDropdown'
import { useQuery } from 'react-query'
import './BuyersTable.less'
import { useSearchItemDescription } from 'pages/Review/Review.hooks'

const dataIndex: { [prop in keyof MaterialBuyer]: prop } = {
  country: 'country',
  id: 'id',
  name: 'name',
}

function getColumnFilterOptions({ buyers }: { buyers: MaterialBuyer[] }) {
  const countryOptionsDraft = new Set<string>()
  for (const { country } of buyers) {
    if (country) {
      countryOptionsDraft.add(country)
    }
  }

  const [countryOptions] = [countryOptionsDraft]
    .map((set) => Array.from(set))
    .map((array) => array.map((value) => ({ value, text: value })))

  return {
    countryOptions,
  }
}

function useBuyersData() {
  const {
    molecule: { id: moleculeId },
  } = useSearchItemDescription()

  const { data } = useQuery({
    queryKey: ['material-buyers', { moleculeId }],
    queryFn: () => fetchMaterialBuyers({ moleculeId }),
    config: {
      staleTime: Infinity, // needed, otherwise frequent queries will clear the filters in the tables,
      suspense: true,
    },
  })

  return data as MaterialBuyersAPIData
}

export function BuyersTable() {
  const buyers = useBuyersData()

  const { countryOptions } = getColumnFilterOptions({ buyers })
  const columns: ColumnsType<MaterialBuyer> = [
    {
      title: 'Name',
      dataIndex: dataIndex.name,
      width: '30%',
    },
    {
      title: 'Country',
      dataIndex: dataIndex.country,
      onFilter: (value, record) => {
        return record.country === value
      },
      filters: countryOptions,
      filterDropdown: CustomColumnFilterDropdown,
    },
  ]

  return (
    <Table
      id="buyers-table"
      title={() => (
        <Row justify="space-between" align="middle" style={{ height: 40 }}>
          {`list of buyers (${buyers.length})`.toUpperCase()}
        </Row>
      )}
      columns={columns}
      dataSource={buyers}
      pagination={{
        defaultPageSize: 10,
        position: ['topRight'],
        style: {
          position: 'absolute',
          right: 150,
          zIndex: 2,
          margin: 0,
          top: 8,
        },
      }}
      size="small"
      rowKey={dataIndex.id}
    />
  )
}
