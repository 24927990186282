import { Skeleton, Space, Typography } from 'antd'
import { getSuppliersCustomerProfileData } from 'api/search'
import React, { useState } from 'react'
import { useQuery } from 'react-query'
import CountrySelectionDropdown from '../shared/CountrySelectionDropdown'
import CustomerProfileForClientStackedChart from './CustomerProfileForClientStackedChart'
import { useSearchItemDescription } from 'pages/Review/Review.hooks'
import SupplierSelectionDropdown from '../shared/SupplierSelectionDropdown'
import { SelectSupplierLabel } from './constant'

const initialSelectedSupplier = {
  value: SelectSupplierLabel,
  key: null,
  label: SelectSupplierLabel,
}
export default function CountriesExportingToIndia() {
  const { searchItemId, searchId } = useSearchItemDescription()
  const [country, setCountry] = useState(null)

  const [supplier, setSupplier] = useState<any>(initialSelectedSupplier)

  const {
    data: supplierGraphData,
    isLoading: isSupplierGraphLoading,
  }: any = useQuery({
    queryKey: [
      'suppliers-customer-profile-chart-data',
      { searchId, searchItemId, supplier, country },
    ],
    queryFn: () =>
      getSuppliersCustomerProfileData(
        searchId,
        searchItemId,
        supplier?.key || null,
        country
      ),
    config: {
      suspense: true,
      staleTime: Infinity,
      enabled: supplier.value === SelectSupplierLabel ? false : true,
    },
  })

  const onCountryChange = (country: any) => {
    setSupplier(initialSelectedSupplier)
    setCountry(country)
  }

  const onSupplierChage = (value: any) => {
    setSupplier(value)
  }

  return (
    <div>
      <Space
        style={{
          marginBottom: '15px',
          display: 'flex',
          justifyContent: 'end',
        }}
      >
        <Typography.Text>Importer Country</Typography.Text>
        <CountrySelectionDropdown onChange={onCountryChange} />
        {country ? (
          <>
            <Typography.Text>Supplier</Typography.Text>
            <SupplierSelectionDropdown
              supplier={supplier}
              country={country}
              onChange={onSupplierChage}
            ></SupplierSelectionDropdown>{' '}
          </>
        ) : null}
      </Space>

      {isSupplierGraphLoading && country != null ? (
        <Skeleton />
      ) : (
        <div style={{ marginBottom: '15px' }}>
          {supplier && supplierGraphData && supplierGraphData.length > 0 ? (
            <CustomerProfileForClientStackedChart
              data={supplierGraphData}
              country={country}
              supplier={supplier.label}
            ></CustomerProfileForClientStackedChart>
          ) : (
            <Typography.Title level={5} style={{ textAlign: 'center' }}>
              No Data
            </Typography.Title>
          )}
        </div>
      )}
    </div>
  )
}
