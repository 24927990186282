import { axios, Material } from './utils'
import { APIResponseBodyBase } from './utils'
import { CurrencyCode, DATE_FORMAT } from '../constants'
import { format, parseISO } from 'date-fns'
import { AxiosRequestConfig } from 'axios'

export type MaterialTypesAPIResponseBody = APIResponseBodyBase<
  MaterialTypesAPIData
>

export type MaterialTypesAPIData = Array<string>

export function fetchAllMaterialTypes() {
  return axios.get<MaterialTypesAPIData>('/molecules/types')
}

export type MatchedMaterialsAPIResponseBody = APIResponseBodyBase<
  MatchingMaterialNamesAPIData
>

export type MatchingMaterialNamesAPIData = Array<Material>

export function getMatchingMaterialNames({
  moleculeName,
}: {
  moleculeName: string
}) {
  return axios.get<MatchingMaterialNamesAPIData>('/molecules', {
    params: {
      name: moleculeName,
    },
  })
}

export type MaterialBuyer = {
  id: number
  name: string
  country: string | null
}

export type MaterialBuyersAPIData = Array<MaterialBuyer>

export type MaterialBuyersAPIResponseBody = APIResponseBodyBase<
  MaterialBuyersAPIData
>

export function fetchMaterialBuyers({ moleculeId }: { moleculeId: number }) {
  return axios.get<MaterialBuyersAPIData>(`/molecules/${moleculeId}/buyers`)
}

export function downloadSecondaryNamesTrackerExcel() {
  return axios.get(`/molecules/xlsx`, {
    responseType: 'blob',
    headers: {
      accept: '*/*',
    },
  })
}

export type GradesAPIResponseBody = APIResponseBodyBase<Array<string>>

export type GradesAPIData = Array<string>

export function getGrades() {
  return axios.get<GradesAPIData>('/molecules/grades')
}

export type MaterialTxnsForAllVendorsAPIData = Array<{
  id: number
  beDate: string
  quantity: number
  unit: string
  accessValue: number | null
  unitPrice: number
  supplierName: string
  supplierId: number
}>

export type MaterialTxnsForAllVendorsAPIResponseBody = APIResponseBodyBase<
  MaterialTxnsForAllVendorsAPIData
>

export function getMaterialTxnsForAllVendors({
  moleculeId,
  fromDate,
  toDate,
  grade,
  hsCode,
  currencyCode,
}: {
  moleculeId: number
  fromDate: string
  toDate: string
  grade: Array<string>
  hsCode: number | null
  currencyCode: CurrencyCode
}) {
  const fromDateString = format(parseISO(fromDate), DATE_FORMAT.yyyyMM)
  const toDateString = format(parseISO(toDate), DATE_FORMAT.yyyyMM)
  const params: AxiosRequestConfig['params'] = {
    fromDate: fromDateString,
    toDate: toDateString,
    grade,
    hsCode: hsCode ?? undefined,
    currencyCode,
  }
  return axios.get<MaterialTxnsForAllVendorsAPIData>(
    `/molecules/${moleculeId}/transactions`,
    {
      params,
    }
  )
}

export type MaterialHSCodesAPIResponseData = Array<{
  id: number
  value: number
}>

export type MaterialHSCodesAPIResponseBody = APIResponseBodyBase<
  MaterialHSCodesAPIResponseData
>

export type HSCodesAPIResponseData = Array<{
  id: number
  hsCode: number
  molecule: any
}>

export function getMaterialHSCodes({ moleculeId }: { moleculeId: number }) {
  const url = `/molecules/${moleculeId}/hs-codes`
  return axios.get<MaterialHSCodesAPIResponseData>(url)
}

export function getHSCodes({ value }: { value: string }) {
  const url = `/hs-codes`
  return axios.get<HSCodesAPIResponseData>(url, {
    params: {
      value,
    },
  })
}
