import Axios, { AxiosResponse, AxiosError } from 'axios'
import { AUTH_ACCESS_TOKEN_NAME } from '../constants'
import { getAuthHeader, getBackendServiceURL } from 'utils'

export type APIResponseBodyBase<T> = {
  data: T
}

export type Material = {
  id: number
  name: string
}

export type AxiosApiError = AxiosError<
  | {
      statusCode: number
      message: string | string[]
      error: string
    }
  | string
>

export const axios = Axios.create({
  baseURL: getBackendServiceURL(),
})

axios.interceptors.request.use(
  function (config) {
    // Do something before request is sent
    const { accessToken, authHeader } = getAuthHeader()
    if (accessToken === null) {
      throw new Error(
        `Cannot find access token "${AUTH_ACCESS_TOKEN_NAME}" for authorization in session storage`
      )
    }
    config.headers = {
      ...config.headers,
      ...authHeader,
    }
    return config
  },
  function (error) {
    // Do something with request error
    return Promise.reject(error)
  }
)
declare module 'axios' {
  export interface AxiosInstance {
    request<T = any>(config: AxiosRequestConfig): Promise<T>
    get<T = any>(url: string, config?: AxiosRequestConfig): Promise<T>
    delete<T = any>(url: string, config?: AxiosRequestConfig): Promise<T>
    head<T = any>(url: string, config?: AxiosRequestConfig): Promise<T>
    options<T = any>(url: string, config?: AxiosRequestConfig): Promise<T>
    post<T = any>(
      url: string,
      data?: any,
      config?: AxiosRequestConfig
    ): Promise<T>
    put<T = any>(
      url: string,
      data?: any,
      config?: AxiosRequestConfig
    ): Promise<T>
    patch<T = any>(
      url: string,
      data?: any,
      config?: AxiosRequestConfig
    ): Promise<T>
  }
}

axios.interceptors.response.use(
  function (response: AxiosResponse<APIResponseBodyBase<any> | Blob>) {
    if (response.data instanceof Blob) {
      handleBlobDownload({ headers: response.headers, blob: response.data })
      return response.data
    }
    const data = response.data.data
    if (data === undefined) {
      throw new Error(
        '"undefined" is returned as "data" for a successful response from server'
      )
    }
    return data
  },
  function (error) {
    return Promise.reject(error)
  }
)

export function handleBlobDownload({
  headers,
  blob,
}: {
  headers: any
  blob: Blob
}) {
  const contentDispositionHeader = headers['content-disposition'] as string
  const fileNameIndex =
    (contentDispositionHeader.match('filename=')?.index as number) +
    'filename='.length
  const fileName = contentDispositionHeader.slice(fileNameIndex)
  // 2. Create blob link to download
  const url = window.URL.createObjectURL(new Blob([blob]))
  const link = document.createElement('a')
  link.href = url
  link.setAttribute('download', `${fileName}`)
  // 3. Append to html page
  document.body.appendChild(link)
  // 4. Force download
  link.click()
  // 5. Clean up and remove the link
  link.parentNode?.removeChild(link)
}

/**
 * Determines whether the payload is an error thrown by Axios
 *
 * @param {*} payload The value to test
 * @returns {boolean} True if the payload is an error thrown by Axios, otherwise false
 */
export function isAxiosError(payload: any): payload is AxiosApiError {
  return typeof payload === 'object' && payload.isAxiosError === true
}
