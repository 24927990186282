import { APIResponseBodyBase, Material } from './utils'
import { axios } from './utils'
import { CurrencyCode } from '../constants'
import isNumber from 'lodash/isNumber'

export type VendorInfoAPIResponseBody = APIResponseBodyBase<VendorInfoAPIData>

export type VendorInfoAPIData = {
  id: number
  companyName: string
  country: string | null
  address: string | null
  telNo: string | null
  email: string | null
  website: string | null
  accrediation: string[]
  molecules: Array<Material>
}

export function fetchVendorInfo({ vendorId }: { vendorId: number }) {
  return axios.get<VendorInfoAPIData>(`/suppliers/${vendorId}/`)
}

export type VendorTransactionsAPIResponseBody = APIResponseBodyBase<
  VendorTransactionsAPIData
>

export type VendorTransaction = {
  id: number
  beDate: string
  quantity: number | null
  unit: string
  accessValue: number | null
  unitPrice: number | null
  importerName: string | null
  importerCountry: string | null
  supplierName: string | null
  txnType: string | null
  grade: Array<string>
  hsCode: number | null
  productDescription: string | null
}

export type VendorTransactionsAPIData = Array<VendorTransaction>

export function fetchVendorTransactions({
  vendorId,
  moleculeId,
  month,
  year,
  currencyCode,
}: {
  vendorId: number
  moleculeId: number
  month: number | null
  year: number
  currencyCode: CurrencyCode
}) {
  return axios.get<VendorTransactionsAPIData>(
    `/suppliers/${vendorId}/molecules/${moleculeId}/transactions`,
    {
      params: {
        month: isNumber(month) ? month + 1 : undefined, // backend API expects months to be indexed from 1, so add 1.
        year,
        currencyCode,
      },
    }
  )
}
