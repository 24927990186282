import React, { useEffect, useState, useMemo } from 'react'
import {
  Layout,
  Card,
  Button,
  List,
  Row,
  Space,
  Typography,
  message,
  Skeleton,
  Popconfirm,
  Modal,
  Input,
  AutoComplete,
  Tooltip,
  Tag,
  Col,
} from 'antd'
import {
  PercentageOutlined,
  ArrowRightOutlined,
  PlusCircleOutlined,
  DeleteTwoTone,
  SyncOutlined,
  CheckOutlined,
} from '@ant-design/icons'
import Header from 'components/Header/Header'
import { useHistory } from 'react-router-dom'
import { useMutation, useQuery, useQueryCache, QueryResult } from 'react-query'
import {
  createNewSearchEntry,
  getSearchHistoryDetails,
  deleteSearch,
  SearcheHistoryDetailsAPIData,
} from 'api/search'
import qs from 'qs'
import './Home.less'
import { ErrorBoundryAndSuspenseFallback } from 'components/Fallback/Fallback'
import { SearchPageLocationState } from 'pages/Search/Search.state'
import { SearchDatabaseSummary } from 'components/SearchDatabaseSummary/SearchDatabaseSummary'
import { pagePaths, TRIAL_USER_MAX_SEARCH_COUNT } from '../../constants'
import {
  QuantityRangeStatistic,
  QuantityStatistic,
} from 'components/Scalars/Scalars'
import create from 'zustand'
import produce from 'immer'
import { useAppWideStore } from 'App.state'
import { AxiosApiError } from 'api/utils'
import { showApiErrorMessagePopup } from 'utils'
import { LargeVerticalSpacer, HideForTrialUser } from 'components/Layout/Layout'
import { SEARCH_HISTORY_QUERY_KEY } from 'utils/reactQuery'

function useSearcheHistoryDetailsList() {
  const { data, isFetching } = useQuery({
    queryKey: SEARCH_HISTORY_QUERY_KEY,
    queryFn: getSearchHistoryDetails,
    config: {
      suspense: true,
      refetchOnMount: 'always',
    },
  }) as QueryResult<SearcheHistoryDetailsAPIData> & {
    data: SearcheHistoryDetailsAPIData
  }
  const allSearchTagOptions = useMemo(
    () => data.map(({ name }) => ({ value: name })),
    [data]
  )

  return {
    allSearchTagOptions,
    data,
    isFetching,
  }
}

type Suggestions = Array<{ value: string }>

type TagSearchInputState = {
  suggestions: Suggestions
  set: (fn: (arg0: TagSearchInputState) => void) => void
}

const [useTagSearchInputStore] = create<TagSearchInputState>((set) => ({
  set: (fn) => set(produce(fn)),
  suggestions: [],
}))

const newSearchTileData = {
  id: NaN,
  averagePercentageSavingsHigh: NaN,
  averagePercentageSavingsLow: NaN,
  noOfMoleculesExplored: NaN,
  noOfSelectedVendors: NaN,
  name: '',
}

function filterBySuggestions({
  suggestions,
  data,
}: {
  suggestions: Suggestions
  data: SearcheHistoryDetailsAPIData
}) {
  if (suggestions.length === 0) {
    return data
  }
  return data.filter(({ name }) =>
    Boolean(suggestions.find(({ value }) => value === name))
  )
}

function SearchHistoryDetailsTiles() {
  const { data } = useSearcheHistoryDetailsList()

  const suggestions = useTagSearchInputStore((state) => state.suggestions)

  const filtered = filterBySuggestions({ suggestions, data })

  const dataSource = [newSearchTileData, ...filtered]

  return (
    <List
      grid={{ gutter: 16, column: 3 }}
      dataSource={dataSource}
      renderItem={(seachDetailsItem) => {
        const { id: searchId } = seachDetailsItem

        if (isNaN(searchId)) {
          return (
            <List.Item key={searchId}>
              <NewSearchCard searchCountTillNow={data.length} />
            </List.Item>
          )
        }

        return (
          <List.Item key={searchId}>
            <SavedSearchCard {...seachDetailsItem} />
          </List.Item>
        )
      }}
    />
  )
}

function caseInsensitiveFilter(tagToSearch: string, tagOptionValue: string) {
  return tagOptionValue.toUpperCase().indexOf(tagToSearch.toUpperCase()) !== -1
}

function TagSearchInput() {
  const { allSearchTagOptions } = useSearcheHistoryDetailsList()

  const [suggestions, set] = useTagSearchInputStore((state) => [
    state.suggestions,
    state.set,
  ])

  function onChange(tagToSearch: string) {
    const suggestions = allSearchTagOptions.filter(({ value }) => {
      return caseInsensitiveFilter(tagToSearch, value)
    })
    set((state) => void (state.suggestions = suggestions))
  }

  return <AutocompleteSearchInputBox {...{ suggestions, onChange }} />
}

function AutocompleteSearchInputBox({
  disabled = false,
  suggestions,
  onChange,
}: {
  disabled?: boolean
  suggestions?: Suggestions
  onChange?: (tagToSearch: string) => void
}) {
  return (
    <AutoComplete
      size={'large'}
      placeholder="Find by search tag..."
      style={{ width: '60%' }}
      disabled={disabled}
      options={suggestions}
      onChange={onChange}
    />
  )
}

function BackgroundRefreshIndicator() {
  const { isFetching } = useSearcheHistoryDetailsList()

  if (isFetching) {
    return (
      <Tag
        icon={<SyncOutlined spin />}
        style={{ border: 'none' }}
        color="processing"
      >
        refreshing...
      </Tag>
    )
  }
  return null
}

export default function Home() {
  return (
    <Layout id="landing-page">
      <Header />
      <Layout.Content id="landing-page-content">
        <LargeVerticalSpacer>
          <Row justify="space-between" align="middle">
            <Row
              style={{ width: '30%' }}
              justify="space-between"
              align="middle"
            >
              <ErrorBoundryAndSuspenseFallback
                suspenseFallback={
                  <Skeleton.Input active size="large" style={{ width: 200 }} />
                }
                errorFallbackRender={() => (
                  <AutocompleteSearchInputBox disabled />
                )}
              >
                <TagSearchInput />
              </ErrorBoundryAndSuspenseFallback>
              <ErrorBoundryAndSuspenseFallback
                suspenseFallback={null}
                errorFallbackRender={() => null}
              >
                <BackgroundRefreshIndicator />
              </ErrorBoundryAndSuspenseFallback>
            </Row>
            <SearchDatabaseSummary />
          </Row>
          <ErrorBoundryAndSuspenseFallback noOfSuspenseFallbacks={3}>
            <SearchHistoryDetailsTiles />
          </ErrorBoundryAndSuspenseFallback>
        </LargeVerticalSpacer>
      </Layout.Content>
    </Layout>
  )
}

function DeleteSearchButton({
  searchId,
  name,
}: {
  name: string
  searchId: number
}) {
  const queryCache = useQueryCache()

  const [deleteSearchEntry, { isLoading }] = useMutation(deleteSearch, {
    onSuccess: ({ id: deletedSearchId }) => {
      queryCache.setQueryData(
        SEARCH_HISTORY_QUERY_KEY,
        (previousData: SearcheHistoryDetailsAPIData | undefined) => {
          return (
            previousData?.filter(({ id }) => {
              return deletedSearchId !== id
            }) ?? []
          )
        }
      )
      message.success('Search entry deleted.')
    },
  })

  useEffect(
    function displayMutationProgressMessage() {
      if (isLoading) {
        message.loading(`Deleting the search "${name}"...`)
      }
    },
    [isLoading, name]
  )

  return (
    <Popconfirm
      placement="bottomRight"
      title={'Are you sure delete this search?'}
      onConfirm={() => deleteSearchEntry({ searchId })}
      okText="Yes"
      cancelText="No"
    >
      <Button type="link" loading={isLoading}>
        <DeleteTwoTone twoToneColor={'red'} />
      </Button>
    </Popconfirm>
  )
}

function SavedSearchCard({
  averagePercentageSavingsHigh,
  averagePercentageSavingsLow,
  noOfMoleculesExplored,
  noOfSelectedVendors,
  id: searchId,
  name,
}: SearcheHistoryDetailsAPIData[number]) {
  const history = useHistory()

  function handleEditResultsOnClick() {
    const searchPageLocationState: SearchPageLocationState = {
      isSavedSearchJourney: true,
      searchId,
    }

    if (noOfMoleculesExplored === 0) {
      // Treat this is a new search journey
      searchPageLocationState.isSavedSearchJourney = false
    }

    const searchPageLocationStateStringified = qs.stringify(
      searchPageLocationState,
      {
        addQueryPrefix: true,
      }
    )

    history.push(
      `${pagePaths.search}${searchPageLocationStateStringified}`,
      searchPageLocationState
    )
  }

  return (
    <Card
      className="previous-search-card"
      title={
        <Row justify="space-between" align="middle">
          <Tooltip title={name}>
            <Typography.Title
              ellipsis
              level={4}
              style={{ marginBottom: 0, maxWidth: '90%' }}
            >
              {name}
            </Typography.Title>
          </Tooltip>
          <HideForTrialUser>
            <DeleteSearchButton {...{ searchId, name }} />
          </HideForTrialUser>
        </Row>
      }
    >
      <Space direction="vertical" size="middle" style={{ width: '100%' }}>
        <Space direction="vertical" size="large" style={{ width: '100%' }}>
          <Row justify="space-between">
            <QuantityStatistic
              title="Materials Explored"
              value={noOfMoleculesExplored}
            />
            <QuantityStatistic
              title="Selected Vendors"
              value={noOfSelectedVendors}
            />
          </Row>
          <Row>
            <QuantityRangeStatistic
              title="Percentage Estimated Savings"
              range={{
                low: averagePercentageSavingsLow,
                high: averagePercentageSavingsHigh,
              }}
              suffix={<PercentageOutlined />}
            />
          </Row>
        </Space>
        <Row justify="space-between">
          <Button type="link" size="large" onClick={handleEditResultsOnClick}>
            Edit the results <ArrowRightOutlined />
          </Button>
        </Row>
      </Space>
    </Card>
  )
}

function NewSearchCard({ searchCountTillNow }: { searchCountTillNow: number }) {
  const history = useHistory()
  const queryCache = useQueryCache()
  const { isTrialUser } = useAppWideStore()

  const disableNewSearch =
    isTrialUser && searchCountTillNow >= TRIAL_USER_MAX_SEARCH_COUNT

  const [isTagInputModalVisible, setTagInputModalVisibility] = useState(false)

  const [tag, setTag] = useState('')

  const [createNewSearch, { isLoading }] = useMutation(createNewSearchEntry, {
    onSuccess: (data) => {
      message.success('This new search is tagged succesfully')
      queryCache.invalidateQueries(SEARCH_HISTORY_QUERY_KEY, {
        exact: true,
        refetchInactive: true,
      })
      const searchPageLocationState: SearchPageLocationState = {
        searchId: data.id,
        isSavedSearchJourney: false,
      }
      const searchPageLocationStateStrigified = qs.stringify(
        searchPageLocationState,
        {
          addQueryPrefix: true,
        }
      )
      history.push(
        `${pagePaths.search}${searchPageLocationStateStrigified}`,
        searchPageLocationState
      )
    },
    onError: (error: AxiosApiError) => {
      if (error.response?.status === 409) {
        message.error(
          'A search with this name already exists. Please enter a different name'
        )
      } else {
        showApiErrorMessagePopup(error)
      }
    },
  })

  function handleNewSearchOnClick() {
    if (isTrialUser) {
      message.warning(
        `${TRIAL_USER_MAX_SEARCH_COUNT - searchCountTillNow} searches remaining`
      )
    }
    setTagInputModalVisibility(true)
  }

  function closeTagInputModal() {
    setTagInputModalVisibility(false)
  }

  function handleSearchTagEntrySubmission() {
    if (tag === '') {
      message.error('Tag name cannot be empty')
      return
    }
    createNewSearch({ tag })
  }

  function handleSearchTagInputOnChange(
    event: React.ChangeEvent<HTMLInputElement>
  ) {
    const tag = event.target.value
    setTag(tag)
  }

  return (
    <Card className="new-search-card">
      <Button
        onClick={handleNewSearchOnClick}
        type="link"
        size="large"
        disabled={disableNewSearch}
      >
        <PlusCircleOutlined style={{ fontSize: 32 }} />
        {disableNewSearch ? (
          <div className="disable-search-button-text">
            <Typography.Text strong>
              Cannot create a new search anymore
            </Typography.Text>
            <Typography.Text>
              You have made {TRIAL_USER_MAX_SEARCH_COUNT} searches already
            </Typography.Text>
          </div>
        ) : (
          <Typography.Text style={{ display: 'block', color: 'inherit' }}>
            Create a new search
          </Typography.Text>
        )}
      </Button>
      <Modal
        title="Enter a unique tag for this search"
        visible={isTagInputModalVisible}
        onOk={handleSearchTagEntrySubmission}
        onCancel={closeTagInputModal}
        confirmLoading={isLoading}
        okText="Submit"
      >
        <Input
          placeholder="Enter a search tag..."
          onChange={handleSearchTagInputOnChange}
          value={tag}
          autoFocus
          onPressEnter={handleSearchTagEntrySubmission}
        />
      </Modal>
    </Card>
  )
}
