import React from 'react'
import { LoginComponent } from '@mid/react-widget/redirect'
import { Typography, Space, Row } from 'antd'
import { mid } from 'utils/midBuilder'

export function LoginPage() {
  return (
    <LoginComponent
      mid={mid}
      customHeader={() => (
        <Space direction="vertical" style={{ width: '100%', color: 'white' }}>
          <Row justify="center">
            <Typography.Title style={{ color: 'white' }}>
              Login
            </Typography.Title>
          </Row>
          <Typography.Title style={{ color: 'white' }} level={4}>
            Enter Email
          </Typography.Title>
        </Space>
      )}
    />
  )
}
