import { useState } from 'react'
import { useLocation } from 'react-router-dom'
import { CurrencyCode } from '../constants'

export function useForceReRender() {
  const [, setCount] = useState(0)

  function forceReRender() {
    setCount((c) => c + 1)
  }

  return { forceReRender }
}

export function useSearchId() {
  const { searchId } = useLocation().state as { searchId: number }
  return searchId
}

export function useCurrencyCode() {
  const { currencyCode } = useLocation().state as { currencyCode: CurrencyCode }
  return currencyCode
}
