import React, { useRef } from 'react'
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  LabelList,
  ResponsiveContainer,
} from 'recharts'

import { saveAs } from 'file-saver'
import { toPng } from 'html-to-image'

import {
  addColorsForGraph,
  convertWeight,
  roundOff,
} from 'utils/helperFunctions'
import { Typography, Button, Tag } from 'antd'
import { DownloadOutlined } from '@ant-design/icons'
import { useSearchItemDescription } from 'pages/Review/Review.hooks'
import {
  WEIGHT_SYMBOLS,
  WEIGHT_LABELS,
  MAX_NUMBER_LIMIT_FOR_CHARTS,
} from '../../../../constants'
import ChartLegends from '../shared/ChartLegends'

const YEAR_KEY_NAME = 'year'

export default function CustomerProfileForClientStackedChart({
  data,
  country,
  supplier,
}: any) {
  const ref = useRef<any>(null)
  const { unit } = useSearchItemDescription()

  const keysArr = Object.keys(data[0]).filter((val) => val !== YEAR_KEY_NAME)

  let dataKeysList = keysArr.map((val, idx) => ({
    key: val,
  }))
  addColorsForGraph(dataKeysList)

  // unit conversion
  let maxQuantity: number = 0
  let dataMax: number = 0 // to set max limit on all graphs for y-axis
  const divisor = 1000
  const currentYear: number = new Date().getFullYear()
  const chartData = data
    .map((dataObj: any) => {
      let keys = Object.keys(dataObj).filter((key) => key !== YEAR_KEY_NAME)
      let newObj: any = { ...dataObj }
      if (unit === WEIGHT_SYMBOLS.MT) {
        const tempObj: any = {}
        for (let key of keys) {
          tempObj[key] = roundOff(
            convertWeight(
              dataObj[key],
              WEIGHT_SYMBOLS.KGS,
              WEIGHT_SYMBOLS.MT
            ) || 0
          )
        }
        newObj = { ...newObj, ...tempObj }
      }

      let sumOfQty: number = 0
      for (let key of keys) {
        sumOfQty += newObj[key]
        if (newObj[key] >= MAX_NUMBER_LIMIT_FOR_CHARTS) {
          maxQuantity = newObj[key]
        }
      }

      if (dataMax < sumOfQty) dataMax = sumOfQty

      return newObj
    })
    .map((dataObj: any) => {
      // convert to 000 unit if exceeds million
      let keys = Object.keys(dataObj).filter((key) => key !== YEAR_KEY_NAME)
      if (maxQuantity > 0) {
        let newObj = { ...dataObj }

        for (let key of keys) {
          newObj[key] = dataObj[key] = roundOff(dataObj[key] / divisor)
        }

        return newObj
      } else {
        return dataObj
      }
    })
    .reverse()

  // set for per million data max if it exceeds the million quantity
  if (maxQuantity > 0) dataMax = dataMax / divisor

  // for sorting the data list
  let stackKeysList: any[] = []
  chartData.forEach((dataObj: any) => {
    let keys = Object.keys(dataObj).filter((key) => key !== YEAR_KEY_NAME)
    const sortable = []

    for (let key of keys) {
      sortable.push([key, dataObj[key]])
    }

    sortable.sort(function (a, b) {
      return b[1] - a[1]
    })

    let tempKeyArr = []
    for (let tempSortArr of sortable) {
      let keyColObj: any = { key: tempSortArr[0] }
      const existingColorDef = dataKeysList.find((val: any) => {
        return val.key === tempSortArr[0]
      })

      keyColObj = { ...existingColorDef }
      tempKeyArr.push(keyColObj)
    }

    stackKeysList.push(tempKeyArr)
  })

  const handleDownload = () => {
    const filter = (node: any) => {
      if (node.tagName) {
        const exclusionClasses = ['dont-download']
        return !exclusionClasses.some((classname) =>
          node.classList.contains(classname)
        )
      }
      return true
    }

    toPng(ref.current, { filter })
      .then((dataUrl) => {
        try {
          let fileName = `customer-profile-for-${country
            .split(' ')
            .join('-')}.png`
          saveAs(dataUrl, fileName)
        } catch (e) {
          console.error('Exception ', e)
          saveAs(dataUrl, `${Date.now()}.png`)
        }
      })
      .catch(function (error) {
        console.error('oops, something went wrong!', error)
      })
  }

  const CustomizedMostPopularLabel = (props: any) => {
    const {
      fontColor,
      x,
      y,
      viewBox: { width, height },
      value,
      chartDataIdx,
    } = props

    if (!value) return null
    const dataObj = chartData[chartDataIdx]

    const clientNames: string[] = Object.keys(dataObj).filter(
      (key) => key !== YEAR_KEY_NAME
    )

    let sumOfQty = 0
    for (let key of clientNames) {
      sumOfQty += dataObj[key] || 0
    }
    const percentage = roundOff((value / sumOfQty) * 100)

    return (
      <text
        x={x + width / 2}
        y={y + height / 2}
        dy="5"
        fontSize="10"
        fontFamily="sans-serif"
        textAnchor="middle"
        fill={fontColor}
      >
        {value} ({percentage}%)
      </text>
    )
  }

  const currentYearFormatter = (label: any) => {
    if (String(label) === String(currentYear)) return `${label} (YTD)`
    return label
  }

  const RenderStackChart = ({
    chartDataIdx,
    keysList,
  }: {
    chartDataIdx: number
    keysList: any
  }) => {
    return (
      <ResponsiveContainer height={550}>
        <BarChart
          data={[chartData[chartDataIdx]]}
          margin={{
            top: 20,
            right: 0,
            left: 0,
            bottom: 5,
          }}
        >
          <XAxis
            hide={false}
            dataKey={YEAR_KEY_NAME}
            tickFormatter={currentYearFormatter}
          />
          <YAxis
            hide={true}
            domain={[0, dataMax]}
            padding={{ top: 20, bottom: 0 }}
            label={{
              value: `${maxQuantity > 0 ? '000 ' : ''}${WEIGHT_LABELS[unit]}`,
              angle: -90,
              position: 'insideLeft',
            }}
            axisLine={false}
            tickLine={false}
            tick={false}
          ></YAxis>
          <Tooltip
            labelFormatter={currentYearFormatter}
            wrapperStyle={{ zIndex: 10 }}
          />
          {/* <Legend /> */}
          {keysList.map((obj: any) => (
            <Bar
              dataKey={obj.key}
              stackId="customer"
              fill={obj.fill}
              key={obj.key}
            >
              <LabelList
                dataKey={obj.key}
                position="center"
                content={
                  <CustomizedMostPopularLabel
                    chartDataIdx={chartDataIdx}
                    fontColor={obj.color}
                  />
                }
              />
            </Bar>
          ))}
        </BarChart>
      </ResponsiveContainer>
    )
  }

  return (
    <div ref={ref} style={{ backgroundColor: '#fff' }}>
      <div
        style={{
          marginBottom: '15px',
          display: 'flex',
          justifyContent: 'space-between',
        }}
      >
        {country && supplier ? (
          <Typography.Title level={5}>
            Supplier {supplier}'s customer profile in {country}
          </Typography.Title>
        ) : null}

        <Button
          type="primary"
          onClick={handleDownload}
          size={'small'}
          className="dont-download"
        >
          <DownloadOutlined />
          Download
        </Button>
      </div>

      <div style={{ display: 'flex' }}>
        <div style={{ display: 'flex' }}>
          <p
            style={{
              whiteSpace: 'nowrap',
              transform: 'rotate(-90deg)',
              margin: 'auto',
            }}
          >
            {`${maxQuantity > 0 ? '000 ' : ''}${WEIGHT_LABELS[unit]}`}
          </p>
        </div>

        {stackKeysList.map((keysList: any, index: number) => {
          return (
            <RenderStackChart
              keysList={keysList}
              chartDataIdx={index}
              key={index}
            ></RenderStackChart>
          )
        })}
      </div>

      <ChartLegends legendsData={stackKeysList[0]}></ChartLegends>
    </div>
  )
}
