import { SearchItemResults, MaterialSupplier } from 'api/search'
import {
  pagePaths,
  DATE_FORMAT,
  MATERIAL_SUPPLIER_PROPS,
  DEFUALT_FOR_EMPTY_OR_NULL,
  WeightCode,
  WEIGHT_LABELS,
  WEIGHT_SYMBOLS,
} from '../../../../constants'
import { useHistory } from 'react-router-dom'
import { PercentageOutlined, CheckCircleTwoTone } from '@ant-design/icons'
import { ReviewPageLocationState } from 'pages/Review/Review'
import { Button, Row, Col, Statistic, List, Tooltip, Table } from 'antd'
import { materialDescription, formatUnit, TableDefaultRowProps } from 'utils'
import {
  QuantityRangeStatistic,
  QuantityScalar,
  CurrencyStatistic,
  CurrencyScalar,
} from 'components/Scalars/Scalars'
import isNumber from 'lodash/isNumber'
import React, { useEffect } from 'react'
import { ColumnsType } from 'antd/lib/table'
import { format, parseISO, isAfter } from 'date-fns'
import qs from 'qs'
import './SearchItemWiseResultsList.less'
import {
  useSearchItemsResultsStore,
  searchItemsResultsStoreAPI,
} from '../Filters/Filters.state'
import {
  VendorRowTooltip,
  PriceSuperscript,
  PriceColumnHeader,
} from 'components/Layout/Layout'
import { useSavedSearchResultsQuery } from 'utils/reactQuery'
import {
  convertPricePerUnitOfWeight,
  convertWeight,
} from 'utils/helperFunctions'

function RecommendedVendorsList({
  suppliers,
  unit,
}: {
  suppliers: MaterialSupplier[]
  unit: WeightCode
}) {
  const columns: ColumnsType<MaterialSupplier> = [
    {
      title: 'Vendor',
      dataIndex: MATERIAL_SUPPLIER_PROPS.name,
    },
    {
      title: 'Vendor Country',
      dataIndex: MATERIAL_SUPPLIER_PROPS.supplierCountry,
      render: (vendorCountry: MaterialSupplier['supplierCountry']) => {
        if (vendorCountry === null) {
          return DEFUALT_FOR_EMPTY_OR_NULL
        }

        return vendorCountry
      },
    },
    {
      title: <PriceColumnHeader unit={WEIGHT_LABELS[unit]} />,
      dataIndex: MATERIAL_SUPPLIER_PROPS.unitPrice,
      render: (unitPrice: number | null, { txnType }) => {
        return (
          <CurrencyScalar
            value={convertPricePerUnitOfWeight(
              unitPrice,
              WEIGHT_SYMBOLS.KGS,
              unit
            )}
            txnType={txnType}
          />
        )
      },
      sorter: (prev, next) => {
        if (isNumber(prev.unitPrice) && isNumber(next.unitPrice)) {
          return prev.unitPrice - next.unitPrice
        }
        return 0
      },
      defaultSortOrder: 'ascend',
      sortDirections: ['ascend', 'descend', 'ascend'],
      className: 'unit-price-column-header',
    },
    {
      title: `Qty Transacted (${WEIGHT_LABELS[unit]})`,
      dataIndex: MATERIAL_SUPPLIER_PROPS.quantity,
      render: (quantity: number | null) => {
        return (
          <QuantityScalar
            value={convertWeight(quantity, WEIGHT_SYMBOLS.KGS, unit)}
          />
        )
      },
      sorter: (prev, next) => {
        if (isNumber(prev.unitPrice) && isNumber(next.unitPrice)) {
          return prev.unitPrice - next.unitPrice
        }
        return 0
      },
      sortDirections: ['ascend', 'descend', 'ascend'],
    },
    {
      title: 'Transaction Date',
      dataIndex: MATERIAL_SUPPLIER_PROPS.beDate,
      render: (date: string) => {
        return format(parseISO(date), DATE_FORMAT['yyyy-MM-dd'])
      },
      sorter: (prev, next) => {
        if (isAfter(parseISO(next.beDate), parseISO(prev.beDate))) {
          return -1
        } else {
          return 1
        }
      },
      sortDirections: ['ascend', 'descend', 'ascend'],
    },
    {
      title: '',
      dataIndex: MATERIAL_SUPPLIER_PROPS.isExpertData,
      render: (isExpertData: boolean) => {
        return isExpertData ? (
          <Tooltip title="Data from McKinsey experts">
            <CheckCircleTwoTone style={{ fontSize: 20 }} />
          </Tooltip>
        ) : null
      },
    },
  ]

  function CustomRow({
    vendor,
    onClick,
    ...defaultProps
  }: { vendor: MaterialSupplier; onClick: () => void } & TableDefaultRowProps) {
    return (
      <VendorRowTooltip vendor={vendor} placement="left">
        <tr {...defaultProps} />
      </VendorRowTooltip>
    )
  }

  const suggestedVendors = suppliers.filter(({ included }) => included)

  return (
    <Table
      dataSource={suggestedVendors}
      id="recommeded-suppliers-table"
      size="small"
      // 🔥 IMPORTANT NOTE 🔥
      // suggestedVendors.length === 0 check is important, otherwise <CustomRow /> comp will get called with `vendor` as undefined and 🔥🔥BLOW UP THE PAGE!🔥🔥
      components={
        suggestedVendors.length === 0
          ? undefined
          : {
              body: {
                row: CustomRow,
              },
            }
      }
      // Pass data you want available in <CustomRow /> component
      onRow={(vendor) => {
        return {
          vendor,
          onClick: () => {}, // have to pass atleat one native props, Typescript complains otherwise. onClick can be replaced by any other prop
        }
      }}
      columns={columns}
      pagination={{
        hideOnSinglePage: true,
        defaultPageSize: 3,
      }}
      rowKey={(supplier) => supplier.supplierId}
    />
  )
}

export function SearchItemWiseResultsList() {
  const {
    currencyCode,
    id: searchId,
    fromDate,
    toDate,
    percentile,
    searchItems: searchItemsResults,
  } = useSavedSearchResultsQuery()

  useEffect(
    function syncSearchItemsResultsStoreWithServerState() {
      searchItemsResultsStoreAPI.dispatch({
        type: 'UPDATE SEARCH ITEMS RESULTS FROM API RESPONSE',
        payload: { searchItemsResults },
      })
    },
    [searchItemsResults]
  )

  const { searchItemsResultsToView } = useSearchItemsResultsStore(
    ({ searchItemsResultsToView }) => ({
      searchItemsResultsToView,
    })
  )

  const history = useHistory()

  function handleReviewOnClick(moleculeSearchItem: SearchItemResults) {
    const {
      currentPricingPerUnit,
      unit,
      type,
      grade,
      totalAnnualizedVolume,
      id: searchItemId,
      molecule,
      hsCode,
    } = moleculeSearchItem
    const reviewPageLocationState: ReviewPageLocationState = {
      searchId,
      searchItemId,
      fromDate,
      molecule,
      toDate,
      currentPricingPerUnit,
      grade,
      totalAnnualizedVolume,
      type,
      unit,
      hsCode,
      currencyCode,
      percentile,
    }
    const urlSearchParamsStringified = qs.stringify(reviewPageLocationState, {
      addQueryPrefix: true,
      strictNullHandling: true,
    })
    history.push(
      `${pagePaths.review}${urlSearchParamsStringified}`,
      reviewPageLocationState
    )
  }

  return (
    <List
      size="large"
      dataSource={searchItemsResultsToView}
      itemLayout="vertical"
      id="molecule-wise-search-results-list"
      renderItem={(searchItem) => {
        const {
          id: searchItemId,
          molecule,
          currentPricingPerUnit,
          estimatedPricePerUnit,
          unit,
          grade,
          searchResults: suppliers,
          estimatedSavingsHigh,
          estimatedSavingsLow,
          hsCode,
          txnType,
        } = searchItem

        const fromWtUnit = WEIGHT_SYMBOLS.KGS
        const toWtUnit = unit

        return (
          <List.Item
            key={searchItemId}
            className="single-molecule-search-result-row"
          >
            <List.Item.Meta
              title={materialDescription({
                name: molecule.name,
                grade,
                hsCode,
              })}
            />
            <Row gutter={0}>
              <Col span={3} xxl={4} className="savings-column">
                <QuantityRangeStatistic
                  title="ESTIMATED SAVINGS"
                  range={{
                    low: estimatedSavingsLow,
                    high: estimatedSavingsHigh,
                  }}
                  suffix={<PercentageOutlined />}
                />
              </Col>
              <Col span={3}>
                <CurrencyStatistic
                  title="CURRENT PRICE"
                  value={convertPricePerUnitOfWeight(
                    currentPricingPerUnit,
                    unit, //here consider the search query unit as it's the value form search query not results
                    toWtUnit
                  )}
                  suffix={
                    isNumber(currentPricingPerUnit) &&
                    `/ ${formatUnit(toWtUnit)}`
                  }
                />
              </Col>
              <Col span={3}>
                <CurrencyStatistic
                  title="ESTIMATED PRICE"
                  value={convertPricePerUnitOfWeight(
                    estimatedPricePerUnit,
                    fromWtUnit,
                    toWtUnit
                  )}
                  suffix={
                    <>
                      / {formatUnit(toWtUnit)}{' '}
                      <PriceSuperscript txnType={txnType} />
                    </>
                  }
                />
              </Col>
              <Col span={12} xxl={11}>
                <Statistic
                  title="REFERENCE VENDORS & PRICING"
                  valueRender={() => (
                    <RecommendedVendorsList
                      {...{ suppliers, unit: toWtUnit }}
                    />
                  )}
                />
              </Col>
              <Col span={3} className="place-child-in-center">
                <Button
                  onClick={() => handleReviewOnClick(searchItem)}
                  id="molecule-review-button"
                  type="primary"
                >
                  Review
                </Button>
              </Col>
            </Row>
          </List.Item>
        )
      }}
    />
  )
}
