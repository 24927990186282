import React, { useMemo, useState } from 'react'
import { useQuery } from 'react-query'
import { parseISO, format, differenceInMonths, parse } from 'date-fns'
import {
  getMaterialTxnsForAllVendors,
  MaterialTxnsForAllVendorsAPIData,
} from 'api/molecules'
import * as utils from './PriceTrendLineChart.utils'
import { Select, Typography, Result, message } from 'antd'

import {
  LineChart,
  Line,
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer,
  Legend,
} from 'recharts'
import { CURRENCY_SYMBOLS, DATE_FORMAT } from '../../../../constants'
import { QuantityScalar } from 'components/Scalars/Scalars'
import { LargeVerticalSpacer } from 'components/Layout/Layout'
import { useSearchItemDescription } from 'pages/Review/Review.hooks'

const COLOR_CODES = [
  '#00008b',
  '#00FF00',
  '#00BFFF',
  '#ff6361',
  '#ffa600',
  '#D2691E',
  '#9370db',
  '#008000',
  '#1f1f1f',
] as const

export enum AGGREGATION_KIND {
  all = 'AGGREGATE',
  top20 = 'AGGREGATE OF TOP 20% TRANSACTIONS',
  middle60 = 'AGGREGATE OF MIDDLE 60% TRANSACTIONS',
  bottom20 = 'AGGREGATE OF BOTTOM 20% TRANSACTIONS',
}

export const AGGREGATION_KINDS = Object.values(AGGREGATION_KIND)

export const AGGREGATE_OPTIONS = AGGREGATION_KINDS.map((aggregationKind) => {
  const option: VendorOption = {
    value: aggregationKind,
  }
  return option
})

export type VendorOption = {
  value: string
}

export const DEFAULT_AGGREGATION_OPTION: VendorOption = {
  value: AGGREGATION_KIND.all,
}

export const MAX_NO_OF_MONTHS_IN_CHART = 18

export default function PriceTrendLineChart() {
  const { fromDate, toDate } = useSearchItemDescription()
  const fromDateObj = parseISO(fromDate)
  const toDateObj = parseISO(toDate)

  const datesNotInRange =
    differenceInMonths(toDateObj, fromDateObj) > MAX_NO_OF_MONTHS_IN_CHART

  if (datesNotInRange) {
    return (
      <Result
        status="warning"
        title="Cannot display chart for dates that more than 18 months apart"
        subTitle="Please go back and select From and To date that are less than or equal to 18 months apart."
      />
    )
  }

  return <Chart />
}

function useMoleculeTxnsData() {
  const {
    fromDate,
    toDate,
    molecule,
    hsCode,
    currencyCode,
    grade,
  } = useSearchItemDescription()
  const { data } = useQuery({
    queryKey: [
      'material-all-vendors-transactions',
      {
        moleculeId: molecule.id,
        grade,
        fromDate,
        toDate,
        hsCode,
        currencyCode,
      },
    ],
    queryFn: () =>
      getMaterialTxnsForAllVendors({
        moleculeId: molecule.id,
        fromDate,
        toDate,
        grade,
        hsCode,
        currencyCode,
      }),
    config: {
      staleTime: Infinity,
      suspense: true,
    },
  })

  return data as MaterialTxnsForAllVendorsAPIData
}

function Chart() {
  const txns = useMoleculeTxnsData()

  const { currencyCode } = useSearchItemDescription()

  const [selectedVendors, setSelectedVendors] = useState<string[]>([
    DEFAULT_AGGREGATION_OPTION.value,
  ])

  const vendorOptions = useMemo(
    () => utils.getUniqueVendorOptionsSortedAlphabetically({ txns }),
    [txns]
  )

  const txnsByAggregateKinds = useMemo(() => utils.getAggregateTxns({ txns }), [
    txns,
  ])

  const lineChartDataSortedByDate = utils.getChartData({
    txns,
    txnsByAggregateKinds,
    selectedVendors,
  })

  const noOfDataPoints = lineChartDataSortedByDate.length

  const widthToUse = utils.calulateWidthOfChart({ noOfDataPoints })

  return (
    <LargeVerticalSpacer>
      <label style={{ width: '100%' }} id="multiple-vendor-select-label">
        <Typography.Text strong style={{ marginRight: '10px' }}>
          Vendor
        </Typography.Text>
        <Select
          style={{ width: '80%' }}
          mode="multiple"
          options={vendorOptions}
          value={selectedVendors}
          defaultValue={[DEFAULT_AGGREGATION_OPTION.value]}
          showSearch
          onChange={(selectedVendorNames: Array<string>) => {
            // selectedVendorNames can be a combination of AGGREGATES and normal vendors,
            // Do not allow more than 5 normal vendors to select
            const normalVendors = selectedVendorNames.filter(
              (vendorName) =>
                !AGGREGATION_KINDS.includes(vendorName as AGGREGATION_KIND)
            )
            if (normalVendors.length > 5) {
              message.warning('Can only select a maximum of 5 vendor names')
            } else if (selectedVendorNames.length < 1) {
              message.warning('Atleast 1 option should be selected')
            } else {
              setSelectedVendors(selectedVendorNames)
            }
          }}
          getPopupContainer={() =>
            document.getElementById(
              'multiple-vendor-select-label'
            ) as HTMLElement
          }
        />
      </label>
      {noOfDataPoints <= 1 ? (
        <Result status="warning" title="Insufficient data points" />
      ) : (
        <div style={{ overflowX: 'scroll' }}>
          <ResponsiveContainer height={400} width={`${widthToUse}%`}>
            <LineChart data={lineChartDataSortedByDate} margin={{ right: 50 }}>
              <CartesianGrid stroke="#ccc" strokeDasharray="5 5" />
              <XAxis
                dataKey="date"
                tickMargin={5}
                tickFormatter={(date: string) =>
                  format(
                    parse(date, DATE_FORMAT.yyyyMM, new Date()),
                    DATE_FORMAT["MMM 'yy"]
                  )
                }
              />
              <YAxis
                label={{
                  value: `${CURRENCY_SYMBOLS[currencyCode]} / Kg`,
                  angle: -90,
                  position: 'insideLeft',
                }}
              />
              <Legend
                align={'left'}
                wrapperStyle={{ left: 60 }}
                iconSize={20}
              />
              <Tooltip
                formatter={(value, name, props) => {
                  return <QuantityScalar value={value as number} />
                }}
              />
              {selectedVendors.map((vendorName, idx) => {
                return (
                  <Line
                    type="monotone"
                    name={vendorName}
                    key={vendorName}
                    dataKey={(dataPoint: utils.LineChartDatum) =>
                      dataPoint.vendorWeightedPrices[vendorName]
                    }
                    stroke={COLOR_CODES[idx]}
                    connectNulls
                  />
                )
              })}
            </LineChart>
          </ResponsiveContainer>
        </div>
      )}
    </LargeVerticalSpacer>
  )
}
