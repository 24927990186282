import { Skeleton, Space, Typography } from 'antd'
import { getSupplierTableData, getSupplierGraphData } from 'api/search'
import React, { useState } from 'react'
import { useQuery } from 'react-query'
import CountriesExportingToIndiaBarGraph from './CountriesExportingToIndiaBarGraph'
import CountriesExportingToIndiaTable from './CountriexExportingToIndiaTable'
import CountrySelectionDropdown from '../shared/CountrySelectionDropdown'
import { useSearchItemDescription } from 'pages/Review/Review.hooks'
export default function CountriesExportingToIndia() {
  const { searchItemId, searchId } = useSearchItemDescription()
  const [country, setCountry] = useState(null)

  const {
    data: supplierTableData,
    isLoading: isSupplierTableLoading,
  }: any = useQuery({
    queryKey: ['countries-exporting-table', { searchItemId, country }],
    queryFn: () => getSupplierTableData(searchId, searchItemId, country),
    config: {
      suspense: true,
      staleTime: Infinity,
    },
  })

  const {
    data: supplierGraphData,
    isLoading: isSupplierGraphLoading,
  }: any = useQuery({
    queryKey: ['countries-exporting-graph', { searchItemId, country }],
    queryFn: () => getSupplierGraphData(searchId, searchItemId, country),
    config: {
      suspense: true,
      staleTime: Infinity,
    },
  })

  const onCountryChange = (country: any) => {
    console.log('onCountryChange ', country)
    setCountry(country)
  }

  return (
    <div>
      <Space
        style={{
          marginBottom: '15px',
          display: 'flex',
          justifyContent: 'end',
        }}
      >
        <Typography>Importer Country</Typography>{' '}
        <CountrySelectionDropdown onChange={onCountryChange} />
      </Space>

      {isSupplierGraphLoading && country != null ? (
        <Skeleton />
      ) : (
        <div style={{ marginBottom: '15px' }}>
          {supplierGraphData.exporters &&
          supplierGraphData.exporters.length > 0 ? (
            <CountriesExportingToIndiaBarGraph
              country={country}
              chartData={supplierGraphData.exporters}
            ></CountriesExportingToIndiaBarGraph>
          ) : null}
        </div>
      )}
      {isSupplierTableLoading ? (
        <Skeleton />
      ) : (
        <CountriesExportingToIndiaTable
          country={country}
          tableData={supplierTableData.suppliers}
          fromDate={supplierTableData.comparisonRange.from}
          toDate={supplierTableData.comparisonRange.to}
        ></CountriesExportingToIndiaTable>
      )}
    </div>
  )
}
