import React, { ReactNode } from 'react'
import { Space, Button, Tooltip, Descriptions } from 'antd'
import { ButtonProps } from 'antd/lib/button'
import { InfoCircleFilled } from '@ant-design/icons'
import { MaterialSupplier } from 'api/search'
import { TooltipProps } from 'antd/lib/tooltip'
import './Layout.less'
import { unitPriceColumnTitle } from 'utils'
import { useCurrencyCode } from 'utils/hooks'
import { useAppWideStore } from 'App.state'
import { WEIGHT_LABELS, WEIGHT_SYMBOLS } from '../../constants'

export function LargeVerticalSpacer({ children }: { children: ReactNode[] }) {
  return (
    <Space direction="vertical" style={{ width: '100%' }} size="large">
      {children}
    </Space>
  )
}

export function LinkButton(props: Omit<ButtonProps, 'type' | 'style'>) {
  return <Button style={{ paddingLeft: 0 }} type="link" {...props} />
}

export function InfoTooltip({ message }: { message: string }) {
  return (
    <Tooltip title={message}>
      <InfoCircleFilled style={{ marginLeft: 10 }} />
    </Tooltip>
  )
}

type VendorRowTooltipInfo = {
  hsCode: number | null
  productDescription: string | null
}

export function VendorRowTooltip({
  vendor,
  placement,
  ...props
}: { vendor: VendorRowTooltipInfo; children: ReactNode } & Omit<
  TooltipProps,
  'title'
>) {
  return (
    <Tooltip
      title={
        <Descriptions column={1}>
          <Descriptions.Item label="HS Code">{vendor.hsCode}</Descriptions.Item>
          <Descriptions.Item label="Description">
            {vendor.productDescription}
          </Descriptions.Item>
        </Descriptions>
      }
      overlayClassName={'vendor-txn-row-tooltip'}
      placement={placement}
      {...props}
    />
  )
}

export function PriceSuperscript({ txnType }: { txnType: string | null }) {
  const superscipt = txnType && `(${txnType[0]})`
  return (
    <sup className="price-superscript">
      <Tooltip title={txnType}>{superscipt}</Tooltip>
    </sup>
  )
}

export function PriceColumnHeader({
  capitalize,
  unit = WEIGHT_LABELS[WEIGHT_SYMBOLS.KGS],
}: {
  capitalize?: boolean
  unit?: string
}) {
  const currencyCode = useCurrencyCode()
  return <>{unitPriceColumnTitle({ currencyCode, unit: unit, capitalize })}</>
}

export function HideForTrialUser({ children }: { children: JSX.Element }) {
  const { isTrialUser } = useAppWideStore()

  if (isTrialUser) {
    return null
  }

  return children
}
