import { enUS } from 'date-fns/locale'
import { message } from 'antd'
import {
  DEFAULT_UNIT,
  CURRENCY_SYMBOLS,
  CurrencyCode,
  WHITELIST_NAMES,
  AUTH_ACCESS_TOKEN_NAME,
  PROD_BACKEND_SERVICE_URL,
  LOCAL_DEV_BACKEND_SERVICE_URL,
} from '../constants'
import capitalize from 'lodash/capitalize'
import isNumber from 'lodash/isNumber'
import { CSSProperties, ReactChildren } from 'react'
import { AxiosApiError } from 'api/utils'
import { UploadListProgressProps } from 'antd/lib/upload/interface'

export function convertPropsFromNullToUndefined<T>(object: T) {
  const result = Object.fromEntries(
    Object.entries(object).map(([key, value]) => {
      if (value === null) {
        return [key, undefined]
      }
      return [key, value]
    })
  ) as {
    [key in keyof T]: null extends T[key]
      ? Exclude<T[key], null> | undefined
      : T[key]
  }

  return result
}

export function convertPropsFromUndefinedToNull<T>(object: T) {
  const result = Object.fromEntries(
    Object.entries(object).map(([key, value]) => {
      if (value === undefined) {
        return [key, null]
      }
      return [key, value]
    })
  ) as {
    [key in keyof T]: undefined extends T[key]
      ? Exclude<T[key], undefined> | null
      : T[key]
  }

  return result
}

export function getCalenderMonthOptions() {
  let monthNames: Array<{ value: string; id: number }> = []
  for (let id = 0; id < 12; id++) {
    monthNames.push({
      value: enUS?.localize?.month(id),
      id,
    })
  }
  return monthNames
}

export function convertRangeToString({
  low,
  high,
  locale,
}: {
  low: number | null
  high: number | null
  locale: string
}) {
  const [lowStringified, highStringified] = [low, high].map((value) =>
    isNumber(value) ? formatCurrency({ value, locale }) : ''
  )
  return `${lowStringified} - ${highStringified}`
}

export async function handleDownloadOnClick({
  downloadAPI,
}: {
  downloadAPI: () => Promise<void>
}) {
  message.loading('Downloading...')
  try {
    await downloadAPI()
    message.success('Download Successful!')
  } catch (error) {
    const statusCode = error.response?.status
    const statusText = error.response?.statusText
    if (statusText) {
      message.error(
        `Download failed due to an error with code ${statusCode} ${statusText}`
      )
    } else {
      message.error(`Download failed due to an error`)
    }
  }
}

export function formatUnit(unit: string) {
  if (unit === DEFAULT_UNIT) {
    return capitalize(unit).slice(0, unit.length - 1)
  }
  return unit
}

export function formatCurrency({
  value,
  locale,
}: {
  value: number
  locale: string
}) {
  return value.toLocaleString(locale, {
    maximumFractionDigits: 2,
  })
}

export function formatQuantity({
  quantity,
  locale,
}: {
  quantity: number
  locale: string
}) {
  return quantity.toLocaleString(locale, {
    maximumFractionDigits: 2,
  })
}

export function unitPriceColumnTitle({
  currencyCode,
  unit,
  capitalize = false,
}: {
  currencyCode: CurrencyCode
  unit: string
  capitalize?: boolean
}) {
  const title = `Unit price (${CURRENCY_SYMBOLS[currencyCode]} / ${unit})`
  if (capitalize) {
    return title.toUpperCase()
  }
  return title
}

export type TableDefaultRowProps = {
  className: string
  'data-row-key': string
  id: string
  onClick: () => void
  style: CSSProperties
  children: ReactChildren
}

export function materialDescription({
  name,
  grade,
  hsCode,
}: {
  name: string
  grade: Array<string>
  hsCode: number | null
}) {
  return [name, hsCode, grade.join(' , ')]
    .filter((value) => value !== null && value !== '')
    .join(' - ')
}

const BANK_CUSTOMER_REPLACEMENT = {
  single: 'BANK/GUARANTOR REPORTED',
  multiple: 'MULTIPLE BANKS/GUARANTORS REPORTED',
}

export function filterCustomers({ customers }: { customers: string[] }) {
  let noOfBanks = 0
  const filteredCustomers = customers.filter((name) => {
    if (WHITELIST_NAMES.includes(name)) {
      return false
    }
    if (name.search(/bank/i) !== -1) {
      noOfBanks += 1
      return false
    }
    return true
  })
  if (noOfBanks > 0) {
    if (noOfBanks === 1) {
      filteredCustomers.push(BANK_CUSTOMER_REPLACEMENT.single)
    } else {
      filteredCustomers.push(BANK_CUSTOMER_REPLACEMENT.multiple)
    }
  }
  return { filteredCustomers, noOfBanks }
}

export function showApiErrorMessagePopup(error: AxiosApiError) {
  if (error.response) {
    // The request was made and the server responded with a status code
    // that falls out of the range of 2xx
    const { status, statusText, data } = error.response
    message.error(`Something went wrong with code: ${status} ${statusText}`)
    if (typeof data === 'string') {
      message.error(data)
    } else {
      if (Array.isArray(data.message)) {
        data.message.forEach((errorMessage) => {
          message.error(errorMessage)
        })
      } else {
        message.error(data.message)
      }
    }
  } else if (error.request) {
    // The request was made but no response was received
    // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
    // http.ClientRequest in node.js
    message.error(`Something went wrong: ${error.message}`)
  } else {
    // Something happened in setting up the request that triggered an Error
    message.error(`Something went wrong: ${error.message}`)
  }
}

export const uploadProgressConfig: UploadListProgressProps = {
  strokeColor: {
    '0%': '#108ee9',
    '100%': '#87d068',
  },
  strokeWidth: 3,
  format: (percent) => `${percent ? percent.toFixed(2) : ''}%`,
}

export function getAuthHeader() {
  const accessToken = sessionStorage.getItem(AUTH_ACCESS_TOKEN_NAME)
  const authHeader = {
    Authorization: `Bearer ${accessToken}`,
  }
  return { authHeader, accessToken }
}

export function getBackendServiceURL() {
  let backendServiceURL: string
  const frontendOrigin = window.location.origin
  if (frontendOrigin.includes('localhost')) {
    backendServiceURL = LOCAL_DEV_BACKEND_SERVICE_URL
  } else if (frontendOrigin.includes('psse.mckinsey')) {
    backendServiceURL = `${frontendOrigin}/api`
  } else {
    backendServiceURL = frontendOrigin.replace('frontend', 'backend')
  }
  return backendServiceURL
}
