import React from 'react'
import { DownloadOutlined, InfoCircleFilled } from '@ant-design/icons'
import { SmartChemSupplier, SmartChemSuppliersAPIData } from 'api/search'
import {
  Table,
  Typography,
  Space,
  Button,
  Row,
  Popover,
  Descriptions,
} from 'antd'
import { ColumnsType } from 'antd/lib/table'
import './SmartChemVendorsTable.less'
import { CustomColumnFilterDropdown } from '../shared/CustomColumnFilterDropdown'
import { handleDownloadOnClick } from 'utils'
import { downloadSmartChemVendorsList, getSmartChemSuppliers } from 'api/search'
import { useQuery } from 'react-query'
import { companyTypeAbbreviations } from '../../../../constants'
import { useSearchItemDescription } from 'pages/Review/Review.hooks'
import { HideForTrialUser } from 'components/Layout/Layout'

const dataIndex: {
  [prop in keyof SmartChemSupplier]: prop
} = {
  id: 'id',
  accrediation: 'accrediation',
  address: 'address',
  companyName: 'companyName',
  country: 'country',
  email: 'email',
  telNo: 'telNo',
  typeOfCompany: 'typeOfCompany',
  website: 'website',
  dataSource: 'dataSource',
}

function getColumnFilterOptions({ vendors }: { vendors: SmartChemSupplier[] }) {
  const accrediationOptionsDraft = new Set<string>()
  const countryOptionsDraft = new Set<string>()
  const companyTypeOptionsDraft = new Set<string>()
  const sourceOptionsDraft = new Set<string>()
  for (const {
    accrediation: accrediations,
    country,
    dataSource,
    typeOfCompany,
  } of vendors) {
    if (country && countryOptionsDraft.has(country) === false) {
      countryOptionsDraft.add(country)
    }
    if (accrediations) {
      accrediations.forEach((accrediation) => {
        if (accrediationOptionsDraft.has(accrediation) === false) {
          accrediationOptionsDraft.add(accrediation)
        }
      })
    }
    if (typeOfCompany && companyTypeOptionsDraft.has(typeOfCompany) === false) {
      companyTypeOptionsDraft.add(typeOfCompany)
    }

    if (dataSource && !sourceOptionsDraft.has(dataSource)) {
      sourceOptionsDraft.add(dataSource)
    }
  }

  const [
    accrediationOptions,
    countryOptions,
    companyTypesOptions,
    sourceOptions,
  ] = [
    accrediationOptionsDraft,
    countryOptionsDraft,
    companyTypeOptionsDraft,
    sourceOptionsDraft,
  ]
    .map((set) => Array.from(set))
    .map((array) => array.map((value) => ({ value, text: value })))

  return {
    accrediationOptions,
    countryOptions,
    companyTypesOptions,
    sourceOptions,
  }
}

function useSmartChemVendors() {
  const { searchId, searchItemId } = useSearchItemDescription()

  const { data } = useQuery({
    queryKey: ['smartchem-exim-suppliers', { searchId, searchItemId }],
    queryFn: () => getSmartChemSuppliers({ searchId, searchItemId }),
    config: {
      staleTime: Infinity, // needed, otherwise frequent queries will clear the filters in the tables
      suspense: true,
    },
  })

  return data as SmartChemSuppliersAPIData
}

function SmartChemVendorsListDownloadLink() {
  const { searchId, searchItemId } = useSearchItemDescription()

  return (
    <Button
      type="link"
      size="large"
      onClick={() =>
        handleDownloadOnClick({
          downloadAPI: () =>
            downloadSmartChemVendorsList({ searchId, searchItemId }),
        })
      }
    >
      Download <DownloadOutlined />
    </Button>
  )
}

export default function SmartChemVendorsTable() {
  const vendors = useSmartChemVendors()

  const {
    accrediationOptions,
    countryOptions,
    companyTypesOptions,
    sourceOptions,
  } = getColumnFilterOptions({ vendors })

  const columns: ColumnsType<SmartChemSupplier> = [
    {
      title: 'Source',
      dataIndex: dataIndex.dataSource,
      onFilter: (value, record) => {
        return record.dataSource === value
      },
      className: 'text-capitalize',
      filters: sourceOptions,
      filterDropdown: (props) => (
        <CustomColumnFilterDropdown
          {...props}
          selectClassName="source-select"
          dropdownClassName="source-filter-dropdown"
        ></CustomColumnFilterDropdown>
      ),
    },
    {
      title: 'Vendor',
      dataIndex: dataIndex.companyName,
    },
    {
      title: 'Country',
      dataIndex: dataIndex.country,
      onFilter: (value, record) => {
        return record.country === value
      },
      filters: countryOptions,
      filterDropdown: CustomColumnFilterDropdown,
    },
    {
      title: 'Address',
      dataIndex: dataIndex.address,
    },
    {
      title: 'Accrediation',
      dataIndex: dataIndex.accrediation,
      render: (accrediationList: string | null) => {
        return accrediationList
      },
      onFilter: (value, record) => {
        return Boolean(record.accrediation?.includes(value as string))
      },
      filters: accrediationOptions,
      filterDropdown: CustomColumnFilterDropdown,
    },
    {
      title: 'Email',
      dataIndex: dataIndex.email,
    },
    {
      title: 'Tel. No',
      dataIndex: dataIndex.telNo,
    },
    {
      title: (
        <Space>
          Type Of Company
          <Popover
            title="Type Of Company"
            content={
              <Descriptions style={{ width: 200 }} column={1}>
                {Object.entries(companyTypeAbbreviations).map(
                  ([type, abbreviation]) => (
                    <Descriptions.Item label={type} key={type}>
                      {abbreviation}
                    </Descriptions.Item>
                  )
                )}
              </Descriptions>
            }
          >
            <InfoCircleFilled />
          </Popover>
        </Space>
      ),
      dataIndex: dataIndex.typeOfCompany,
      onFilter: (value, record) => {
        return record.typeOfCompany === value
      },
      filters: companyTypesOptions,
      filterDropdown: CustomColumnFilterDropdown,
    },
    {
      title: 'Website',
      dataIndex: dataIndex.website,
      render: (websiteURL: string | null) => {
        return <Typography.Link underline>{websiteURL}</Typography.Link>
      },
    },
  ]

  return (
    <Table
      id="smart-chem-vendors-table"
      title={() => (
        <Row
          justify="space-between"
          align="middle"
          className="table-custom-comp"
        >
          {`Complete list of vendors (${vendors.length})`.toUpperCase()}
          <HideForTrialUser>
            <SmartChemVendorsListDownloadLink />
          </HideForTrialUser>
        </Row>
      )}
      columns={columns}
      dataSource={vendors}
      pagination={{
        defaultPageSize: 10,
        position: ['topRight'],
        style: {
          position: 'absolute',
          right: 150,
          zIndex: 2,
          margin: 0,
          top: 8,
        },
      }}
      size="small"
      rowKey={dataIndex.id}
      scroll={{
        x: 'fit-content',
      }}
    />
  )
}
