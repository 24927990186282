import React, { useState, useMemo, useEffect, ReactNode } from 'react'
import {
  useSearchFormStore,
  SearchItemBulkUploadResponseErrors,
  ColumnIndex,
  SearchItem,
  searchFormStoreAPI,
  searchItemPropertySelector,
  unitSelector,
} from 'pages/Search/Search.state'
import { Form, InputNumber, Empty, Select, Space, Spin } from 'antd'
import { RuleObject } from 'antd/lib/form'
import { COLUMN_DATA_INDEX } from 'pages/Search/Search.state'
import { useQuery } from 'react-query'
import {
  getMatchingMaterialNames,
  getGrades,
  getMaterialHSCodes,
  fetchAllMaterialTypes as getAllMaterialTypes,
  MatchingMaterialNamesAPIData,
  GradesAPIData,
  MaterialHSCodesAPIResponseData,
} from 'api/molecules'
import { useDebounceFn } from 'ahooks'
import isNumber from 'lodash/isNumber'
import Fallback from 'components/Fallback/Fallback'
import { AxiosApiError } from 'api/utils'
import { WEIGHT_LABELS } from '../../../../constants'

import HSCodeInputField from './HSCodeInputField'
import eventBus, { EventKeys } from 'utils/EventBus'

type BulkUploadColumnResponse = NonNullable<
  SearchItemBulkUploadResponseErrors[string]
>[ColumnIndex]

const bulkUploadValidationRule = (
  bulkUploadResponse: BulkUploadColumnResponse
) => ({
  validator: (_: RuleObject | any, valueToValidate: any): Promise<void> => {
    // for hsCode even if it's string or number mark is as valid
    if (_.field === 'hsCode' && valueToValidate) {
      return Promise.resolve()
    }

    if (
      bulkUploadResponse &&
      bulkUploadResponse.error &&
      valueToValidate === bulkUploadResponse.value
    ) {
      return Promise.reject(bulkUploadResponse.error)
    }
    return Promise.resolve()
  },
})

export function NameFormItem(_: any, searchItem: SearchItem) {
  const { rowKey } = searchItem
  const columnName = COLUMN_DATA_INDEX.name
  const bulkUploadResponse = useSearchFormStore(
    (state) => state.searchItemBulkUploadResponseErrors[rowKey]?.[columnName]
  )
  const hsCode = useSearchFormStore((state) => {
    return searchItemPropertySelector(state, { rowKey, propName: 'hsCode' })
  })

  return (
    <Form.Item
      name={COLUMN_DATA_INDEX.name}
      rules={[
        {
          required: hsCode ? false : true,
          message: `Name of material or HS Code is required`,
        },
        bulkUploadValidationRule(bulkUploadResponse),
      ]}
      hasFeedback
    >
      <NameSelectField rowKey={rowKey} />
    </Form.Item>
  )
}

export function TypeFormItem(_: any, searchItem: SearchItem) {
  const { rowKey } = searchItem
  const columnName = COLUMN_DATA_INDEX.type
  const bulkUploadResponse = useSearchFormStore(
    (state) => state.searchItemBulkUploadResponseErrors[rowKey]?.[columnName]
  )
  return (
    <Form.Item
      rules={[bulkUploadValidationRule(bulkUploadResponse)]}
      name={COLUMN_DATA_INDEX.type}
      hasFeedback
    >
      <TypeSelectField />
    </Form.Item>
  )
}

export function GradeFormItem(_: any, searchItem: SearchItem) {
  const { rowKey } = searchItem
  const columnName = COLUMN_DATA_INDEX.grade
  const bulkUploadResponse = useSearchFormStore(
    (state) => state.searchItemBulkUploadResponseErrors[rowKey]?.[columnName]
  )
  return (
    <Form.Item
      name={COLUMN_DATA_INDEX.grade}
      rules={[bulkUploadValidationRule(bulkUploadResponse)]}
      hasFeedback
    >
      <GradeSelectField rowKey={rowKey} />
    </Form.Item>
  )
}

export function CurrentPriceFormItem(_: any, searchItem: SearchItem) {
  const { rowKey } = searchItem
  const columnName = COLUMN_DATA_INDEX.currentPricingPerUnit
  const bulkUploadResponse = useSearchFormStore(
    (state) => state.searchItemBulkUploadResponseErrors[rowKey]?.[columnName]
  )
  const unit = useSearchFormStore(unitSelector)
  return (
    <Form.Item
      rules={[
        { type: 'number', message: 'Should be a number' },
        {
          type: 'number',
          max: Number.MAX_SAFE_INTEGER,
          message: `Please use value less than ${Number.MAX_SAFE_INTEGER} only`,
        },
        bulkUploadValidationRule(bulkUploadResponse),
      ]}
      name={COLUMN_DATA_INDEX.currentPricingPerUnit}
      hasFeedback
    >
      <InputNumber
        placeholder={`Enter current pricing per ${WEIGHT_LABELS[unit]}`}
      />
    </Form.Item>
  )
}

export function TotalAnnualVolumeFormItem(_: any, searchItem: SearchItem) {
  const { rowKey } = searchItem
  const columnName = COLUMN_DATA_INDEX.totalAnnualizedVolume
  const bulkUploadResponse = useSearchFormStore(
    (state) => state.searchItemBulkUploadResponseErrors[rowKey]?.[columnName]
  )
  return (
    <Form.Item
      rules={[
        { type: 'number', message: 'Should be a number' },
        {
          type: 'number',
          max: Number.MAX_SAFE_INTEGER,
          message: `Please use value less than ${Number.MAX_SAFE_INTEGER} only`,
        },
        {
          type: 'number',
          min: 0.001,
          message: 'Please use value above 1 gram (0.001 Kg) only',
        },
        {
          pattern: /^[0-9]*(?:\.[0-9]{1,3})?$/,
          message: 'Please use value upto 3 decimal places only',
        },
        bulkUploadValidationRule(bulkUploadResponse),
      ]}
      name={COLUMN_DATA_INDEX.totalAnnualizedVolume}
      hasFeedback
    >
      <InputNumber placeholder={'Enter total annualized volume'} />
    </Form.Item>
  )
}

export function HSCodeFormItem(_: any, searchItem: SearchItem) {
  const { rowKey } = searchItem

  const columnName = COLUMN_DATA_INDEX.hsCode
  const bulkUploadResponse = useSearchFormStore(
    (state) => state.searchItemBulkUploadResponseErrors[rowKey]?.[columnName]
  )

  const moleculeId = useSearchFormStore((state) => {
    return searchItemPropertySelector(state, { rowKey, propName: 'moleculeId' })
  })

  const setMoleculeByHSCode = (obj: any) => {
    console.log('[On EMIT MOL] ', obj)
  }

  return (
    <Form.Item
      name={COLUMN_DATA_INDEX.hsCode}
      data-arrtibute={moleculeId}
      rules={[
        {
          required: moleculeId ? false : true,
          message: `Name of material or HS Code is required`,
        },
        bulkUploadValidationRule(bulkUploadResponse),
      ]}
      hasFeedback
    >
      {/* <HSCodeSelectField rowKey={rowKey} /> */}
      <HSCodeInputField rowKey={rowKey} emitMolecule={setMoleculeByHSCode} />
    </Form.Item>
  )
}

const { dispatch } = searchFormStoreAPI

export function TypeSelectField({
  value,
  onChange,
}: {
  value?: string
  onChange?: (newValue: typeof value) => void
}) {
  const { status, data } = useQuery('all-materail-types', getAllMaterialTypes, {
    staleTime: Infinity,
  })
  const options = useMemo(
    () =>
      data?.map((materialType) => ({
        value: materialType,
      })),
    [data]
  )

  return (
    <Select
      allowClear
      loading={status === 'loading'}
      value={value}
      onChange={onChange}
      options={options}
      placeholder="Enter material type"
    />
  )
}

export function NameSelectField({
  value,
  onChange,
  rowKey,
}: {
  value?: string
  onChange?: (newValue: typeof value) => void
  rowKey: string
}) {
  console.log('[NAMEFi] ', value, rowKey)
  const [searchValue, setSearchValue] = useState('')

  const { status, data, isFetching } = useQuery<MatchingMaterialNamesAPIData>({
    queryKey: ['matching-material-names', { moleculeName: searchValue }],
    queryFn: () => getMatchingMaterialNames({ moleculeName: searchValue }),
    config: {
      enabled: searchValue !== '',
      staleTime: Infinity,
    },
  })

  const options = useMemo(
    () =>
      data?.map(({ name, id }) => ({
        value: name,
        id,
      })),
    [data]
  )

  const { run: handleOnSearch } = useDebounceFn(
    (searchValue: string) => {
      setSearchValue(searchValue)
    },
    { wait: 500 }
  )

  function handleOnChange(
    selectedValue: string | undefined,
    optionData: { value: string; id: number } | undefined
  ) {
    onChange?.(selectedValue)
    if (optionData === undefined) {
      return
    }
    dispatch({
      type: 'UPDATE_MATERIAL_ID',
      payload: {
        rowKey,
        id: optionData.id,
      },
    })
    eventBus.dispatch(EventKeys.OnMaterialChange, {
      rowKey,
      id: optionData.id,
    })
  }

  let notFoundContent = null
  if (options?.length === 0) {
    notFoundContent = (
      <Empty
        description="Did not find any material that match this name"
        image={Empty.PRESENTED_IMAGE_SIMPLE}
      />
    )
  }

  return (
    <Select
      loading={status === 'loading' || isFetching}
      showSearch
      notFoundContent={notFoundContent}
      value={value}
      filterOption={false}
      // @ts-ignore
      onChange={handleOnChange}
      onSearch={handleOnSearch}
      options={options}
      placeholder="Enter name of material"
    />
  )
}

export function GradeSelectField({
  value,
  onChange,
  rowKey,
}: {
  value?: Array<string>
  onChange?: (newValue: typeof value) => void
  rowKey: string
}) {
  const { data, isLoading } = useQuery<GradesAPIData>({
    queryKey: 'grades',
    queryFn: getGrades,
    config: {
      staleTime: Infinity, // Setting to Infinity does not cause unnecessary fetching when a duplicate of this component is mounted when add new molecule is pressed.
    },
  })

  const gradeOptions = useMemo(
    () =>
      data?.map((grade) => ({
        value: grade,
      })),
    [data]
  )

  function handleOnChange(selectedGrades: Array<string>) {
    onChange?.(selectedGrades)
  }

  return (
    <Select
      loading={isLoading}
      allowClear
      options={gradeOptions}
      onChange={handleOnChange}
      placeholder={'Enter grade'}
      mode="multiple"
      defaultValue={value}
    />
  )
}

export function HSCodeSelectField({
  value,
  onChange,
  rowKey,
}: {
  value?: number
  onChange?: (newValue: typeof value) => void
  rowKey: string
}) {
  const moleculeId = useSearchFormStore((state) =>
    searchItemPropertySelector(state, { rowKey, propName: 'moleculeId' })
  )

  const [moleculeIdUsedCurrrently, setMoleculeIdToUseCurrently] = useState(
    moleculeId
  )

  const { data: hsCodeOptions, isLoading, status, error } = useQuery<
    MaterialHSCodesAPIResponseData,
    AxiosApiError
  >({
    queryKey: ['hs-codes', moleculeIdUsedCurrrently],
    queryFn: () =>
      getMaterialHSCodes({ moleculeId: moleculeIdUsedCurrrently as number }),
    config: {
      enabled: isNumber(moleculeIdUsedCurrrently),
    },
  })

  useEffect(
    function resetWhenMoleculeIdChanges() {
      if (moleculeIdUsedCurrrently !== moleculeId) {
        setMoleculeIdToUseCurrently(moleculeId)
        onChange?.(undefined)
      }
    },
    [moleculeId, moleculeIdUsedCurrrently, onChange]
  )

  let fallback: ReactNode
  if (hsCodeOptions?.length === 0) {
    fallback = (
      <Empty
        description="Did not find any HS codes for this material"
        image={Empty.PRESENTED_IMAGE_SIMPLE}
      />
    )
  } else {
    fallback = (
      <Fallback
        status={status}
        error={error}
        idleStateContent={<>Select a material first to get HS Codes for it</>}
        customLoader={
          <Space>
            <>Fetching HS codes for this material...</>
            <Spin />
          </Space>
        }
      />
    )
  }
  return (
    <Select
      loading={isLoading}
      options={hsCodeOptions}
      value={value}
      onChange={onChange}
      placeholder={'Enter HS Code'}
      notFoundContent={fallback}
      allowClear
    />
  )
}
