import { useMutation, useQuery, useQueryCache } from 'react-query'
import {
  toggleVendorInclusionStatus,
  SearchResultsAPIData,
  getSearchItemResults,
  SearchItemResults,
} from 'api/search'
import {
  getSavedSearchResultsQueryKey,
  getSearchItemQueryKey,
  useSearchResultsDataFromQueryCache,
} from 'utils/reactQuery'
import produce from 'immer'
import { message } from 'antd'
import { useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import { ReviewPageLocationState } from './Review'

export function useToggleVendorInclusionStatus() {
  const queryCache = useQueryCache()

  const returnValue = useMutation(toggleVendorInclusionStatus, {
    onSuccess: ({ id: searchId, searchItems, summary }) => {
      const updatedSearchItem = searchItems[0]
      queryCache.setQueryData(
        getSearchItemQueryKey({
          searchId,
          searchItemId: updatedSearchItem.id,
        }),
        updatedSearchItem
      )

      const searchResultsAPIDataInQueryCache = queryCache.getQueryData<
        SearchResultsAPIData
      >(getSavedSearchResultsQueryKey({ searchId }))

      if (searchResultsAPIDataInQueryCache) {
        const updated = produce(searchResultsAPIDataInQueryCache, (draft) => {
          draft.summary = summary
          const indexToReplaceAt = draft.searchItems.findIndex(
            ({ id }) => id === updatedSearchItem.id
          )
          if (indexToReplaceAt === -1) {
            return
          }
          draft.searchItems[indexToReplaceAt] = updatedSearchItem
        })
        queryCache.setQueryData(
          getSavedSearchResultsQueryKey({ searchId }),
          updated
        )
      }
      message.success('Successfully changed inclusion status')
    },
  })

  useEffect(function displayLoadingIndicator() {
    const { status } = returnValue[1]
    if (status === 'loading') {
      message.loading('Updating inclusion status...')
    }
  })

  return returnValue
}

export function useSearchItemDescription() {
  return useLocation<ReviewPageLocationState>().state
}

export function useSearchItemResults() {
  const { searchId, searchItemId } = useSearchItemDescription()
  const moleculeSearchResultsQueryKey = getSearchItemQueryKey({
    searchId,
    searchItemId,
  })
  const searchItemRResultsDataFromQueryCache = useSearchResultsDataFromQueryCache()?.searchItems.find(
    ({ id }) => id === searchItemId
  )
  const { data } = useQuery({
    queryKey: moleculeSearchResultsQueryKey,
    queryFn: () =>
      getSearchItemResults({
        searchId,
        searchItemId,
      }),
    config: {
      initialData: () => {
        return searchItemRResultsDataFromQueryCache
      },
      staleTime: Infinity, // needed, otherwise frequent queries will clear the filters in the tables,
      suspense: true,
    },
  })

  return data as SearchItemResults
}
