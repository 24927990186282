import { Space, Typography, Input } from 'antd'
import { WeightCode, WEIGHT_LABELS } from '../../constants'
import React, { useState, useEffect } from 'react'

const WeightRangeInput = ({
  onInputChange,
  unit,
  excludeMin: initialFromWeight = undefined,
  excludeMax: initialToWeight = undefined,
}: {
  onInputChange: Function
  unit: WeightCode
  excludeMin: number | undefined
  excludeMax: number | undefined
}) => {
  const [excludeMin, setFromWeight] = useState<number | undefined>(
    initialFromWeight
  )
  const [excludeMax, setToWeight] = useState<number | undefined>(
    initialToWeight
  )

  useEffect(() => {
    if (onInputChange) onInputChange({ excludeMin, excludeMax })
  }, [excludeMin, excludeMax])

  const onInputNumberChange = (
    e: React.FormEvent<HTMLInputElement>,
    forInput: 'from' | 'to'
  ) => {
    let n =
      e.currentTarget.value === '' || e.currentTarget.value === undefined
        ? undefined
        : Number(e.currentTarget.value)
    switch (forInput) {
      case 'from': {
        setFromWeight(n)
        break
      }
      case 'to': {
        setToWeight(n)
        break
      }
    }
  }

  const onInputBlur = (forInput: 'from' | 'to') => {
    console.log('onInputBlur ', forInput, excludeMin, excludeMax)
    switch (forInput) {
      case 'from': {
        if (excludeMin !== undefined && excludeMin < 0) {
          setFromWeight(undefined)
        }
        break
      }
      case 'to': {
        if (excludeMax !== undefined && excludeMax < 0) {
          setToWeight(undefined)
        }
        break
      }
    }
  }

  return (
    <Space>
      <Typography.Text strong>Exclude </Typography.Text>
      <Typography.Text strong>{'<'} </Typography.Text>
      <Input
        style={{ width: '70px' }}
        type="number"
        value={excludeMin}
        min={0}
        onChange={(e) => onInputNumberChange(e, 'from')}
        onBlur={() => onInputBlur('from')}
      ></Input>
      <Typography.Text strong>{WEIGHT_LABELS[unit]} </Typography.Text>
      <Typography.Text strong> And </Typography.Text>
      <Typography.Text strong> {'>'} </Typography.Text>
      <Input
        style={{ width: '70px' }}
        type="number"
        min={0}
        value={excludeMax}
        onChange={(e) => onInputNumberChange(e, 'to')}
        onBlur={() => onInputBlur('to')}
      ></Input>
      <Typography.Text strong>{WEIGHT_LABELS[unit]}</Typography.Text>
    </Space>
  )
}

export default WeightRangeInput
