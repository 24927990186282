import React, { ReactNode, Suspense } from 'react'
import { Choose } from 'react-extras'
import { Skeleton, Result, Button } from 'antd'
import { AxiosError } from 'axios'
import { useHistory } from 'react-router-dom'
import { FallbackProps, ErrorBoundary } from 'react-error-boundary'
import { ResultProps } from 'antd/lib/result'
import { isAxiosError } from 'api/utils'

const { When } = Choose

export function DefaultErrorFallback({
  error,
  customMessage,
}: FallbackProps & { customMessage?: string }) {
  let title: ResultProps['title'] = '',
    subTitle: ResultProps['subTitle'] = '',
    status: ResultProps['status'] = 'error'
  if (error) {
    title = error.name
    subTitle = error.message
    if (customMessage) {
      subTitle = customMessage
    }
    if (isAxiosError(error)) {
      const statusCode = Number(error.response?.status)
      status = [403, 404, 500].includes(statusCode)
        ? (statusCode as 403 | 404 | 500)
        : 'error'
      title = `${error.name}: ${error.message}`
      if (error.response) {
        const { data } = error.response
        if (typeof data === 'string') {
          subTitle = data
        } else {
          subTitle = data.message
        }
      }
    }
  }

  return <Result status={status} title={title} subTitle={subTitle} />
}

export function ErrorBoundryAndSuspenseFallback({
  children,
  noOfSuspenseFallbacks = 1,
  errorFallbackRender = DefaultErrorFallback,
  suspenseFallbackTitle = true,
  suspenseFallback = <Skeleton active title={suspenseFallbackTitle} />,
  resetKeys,
}: {
  children: ReactNode
  suspenseFallback?: ReactNode
  noOfSuspenseFallbacks?: number
  errorFallbackRender?: (
    props: FallbackProps
  ) => React.ReactElement<any, any> | null
  suspenseFallbackTitle?: boolean
  resetKeys?: any[]
}) {
  return (
    <ErrorBoundary fallbackRender={errorFallbackRender} resetKeys={resetKeys}>
      <Suspense
        fallback={
          <>
            {Array.from({ length: noOfSuspenseFallbacks }).map(
              (_) => suspenseFallback
            )}
          </>
        }
      >
        {children}
      </Suspense>
    </ErrorBoundary>
  )
}

export default function Fallback({
  status,
  error,
  customLoader,
  customSuccessStateButton,
  idleStateContent,
}: {
  status: 'loading' | 'error' | 'success' | 'idle'
  error: AxiosError | null | Error
  customLoader?: ReactNode
  customSuccessStateButton?: ReactNode
  idleStateContent?: ReactNode
}) {
  const history = useHistory()
  const coercedError = error as AxiosError | null
  const statusCode = Number(coercedError?.response?.status)
  return (
    <Choose>
      <When condition={status === 'idle'}>{idleStateContent}</When>
      <When condition={status === 'loading'}>
        {customLoader ?? <Skeleton active />}
      </When>
      <When condition={status === 'error'}>
        <Result
          status={
            [403, 404, 500].includes(statusCode)
              ? (statusCode as 403 | 404 | 500)
              : 'error'
          }
          title={`ERROR: ${coercedError?.message}`}
          subTitle={`${coercedError?.response?.data.message}`}
        />
      </When>
      <When condition={status === 'success'}>
        <Result
          status="warning"
          title="No data found"
          extra={
            customSuccessStateButton ?? (
              <Button type="primary" onClick={history.goBack}>
                Go Back
              </Button>
            )
          }
        />
      </When>
    </Choose>
  )
}
