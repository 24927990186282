import { InfoCircleFilled, SyncOutlined } from '@ant-design/icons'
import { Descriptions, Popover, Tag } from 'antd'
import { TagProps } from 'antd/lib/tag'
import Fallback from 'components/Fallback/Fallback'
import { DateStatistic, QuantityStatistic } from 'components/Scalars/Scalars'
import isString from 'lodash/isString'
import React from 'react'
import { useSearchDatabaseSummary } from 'utils/reactQuery'
import { DATE_FORMAT } from '../../constants'

const summaryValueStyle: React.CSSProperties = {
  fontSize: 18,
}

export function SearchDatabaseSummary() {
  const { data, error, status } = useSearchDatabaseSummary()

  let content

  if (data === undefined) {
    content = <Fallback status={status} error={error} />
  } else {
    const {
      earliestTransactionDate,
      latestTransactionDate,
      noOfMolecules,
      filesCount,
      eximTransactionsCount,
      eximToBeReparsedCount,
    } = data

    const eximReparsePercentage = (
      ((eximTransactionsCount - eximToBeReparsedCount) * 100) /
      eximTransactionsCount
    ).toFixed(2)

    content = (
      <Descriptions column={2} size="small" style={{ width: 400 }}>
        <Descriptions.Item>
          <DateStatistic
            title="Earliest transaction date"
            ISODateString={earliestTransactionDate}
            formatString={DATE_FORMAT['yyyy-MM-dd']}
            valueStyle={summaryValueStyle}
          />
        </Descriptions.Item>
        <Descriptions.Item>
          <DateStatistic
            title="Latest transaction date"
            ISODateString={latestTransactionDate}
            formatString={DATE_FORMAT['yyyy-MM-dd']}
            valueStyle={summaryValueStyle}
          />
        </Descriptions.Item>
        <Descriptions.Item>
          <QuantityStatistic
            title="Total number of materials"
            value={noOfMolecules}
            valueStyle={summaryValueStyle}
          />
        </Descriptions.Item>
        <Descriptions.Item>
          <QuantityStatistic
            title="DataSets in Parse Queue"
            value={filesCount}
            valueStyle={summaryValueStyle}
          />
        </Descriptions.Item>
      </Descriptions>
    )
  }

  let color: TagProps['color']
  if (status === 'loading') {
    color = 'processing'
  } else if (status === 'error') {
    color = 'error'
  } else {
    if (isString(data?.latestTransactionDate) === false) {
      color = 'error'
    } else if (isString(data?.earliestTransactionDate) === false) {
      color = 'warning'
    } else {
      color = 'success'
    }
  }

  return (
    <Popover title="Input data summary" content={content}>
      <Tag
        icon={
          status === 'loading' ? <SyncOutlined spin /> : <InfoCircleFilled />
        }
        color={color}
      >
        Input data summary {color === 'error' && `(error)`}
      </Tag>
    </Popover>
  )
}
