import { Skeleton, Space, Typography, Button, Select } from 'antd'
import { getCustomerPricePointsData } from 'api/search'
import React, { useState, useRef, useEffect } from 'react'
import { useQuery } from 'react-query'
import CountrySelectionDropdown from '../shared/CountrySelectionDropdown'
import CustomerPricePointsTable from './CustomerPricePointsTable'
import CustomerPricePointLineChart from './CustomerPriceLineChart'
import SupplierSelectionDropdown from '../shared/SupplierSelectionDropdown'
import { DownloadOutlined } from '@ant-design/icons'
import { cloneDeep } from 'lodash'
import { toPng } from 'html-to-image'
import { saveAs } from 'file-saver'

import { useSearchItemDescription } from 'pages/Review/Review.hooks'
import { convertPricePerUnitOfWeight, roundOff } from 'utils/helperFunctions'
import { WEIGHT_LABELS, WEIGHT_SYMBOLS } from '../../../../constants'
import { SelectSupplierLabel } from './constants'

const initialSelectedSupplier = {
  value: SelectSupplierLabel,
  key: null,
  label: SelectSupplierLabel,
}
const { Option } = Select

export default function CustomerPricePointForSupplierLine() {
  const {
    searchItemId,
    searchId,
    unit,
    currencyCode,
  } = useSearchItemDescription()
  const [country, setCountry] = useState<string | null>(null)
  const [supplier, setSupplier] = useState<any>(initialSelectedSupplier)
  const [customer, setCustomer] = useState<any>(SelectSupplierLabel)
  const ref = useRef<any>(null)

  const {
    data: supplierGraphData,
    isLoading: isSupplierGraphLoading,
  }: any = useQuery({
    queryKey: [
      'supplier-points-graph',
      { searchId, searchItemId, supplier, country },
    ],
    queryFn: () =>
      getCustomerPricePointsData(searchId, searchItemId, supplier.key, country),
    config: {
      suspense: true,
      staleTime: Infinity,
      enabled: supplier.key ? true : false,
    },
  })

  const onCountryChange = (country: any) => {
    console.log('onCountryChange ', country)
    setSupplier(initialSelectedSupplier)
    setCustomer(SelectSupplierLabel)
    setCountry(country)
  }

  const onSupplierChange = (data: any) => {
    console.log('onSupplierChange ', data)
    setCustomer(SelectSupplierLabel)
    setSupplier(data)
  }

  // convert the units
  const clonedDataArray = cloneDeep(
    supplierGraphData ? supplierGraphData.data : []
  )
  for (let dataObj of clonedDataArray) {
    for (let pricePoint of dataObj.pricePoints) {
      if (pricePoint.price) {
        pricePoint.price = roundOff(
          convertPricePerUnitOfWeight(
            pricePoint.price,
            WEIGHT_SYMBOLS.KGS,
            WEIGHT_SYMBOLS[unit]
          ) || 0
        )
      }
    }
  }

  const allCustomers = [
    SelectSupplierLabel,
    ...(supplierGraphData ? supplierGraphData.data : []).map(
      (val: any) => val.customer
    ),
  ]

  const handleDownload = () => {
    const filter = (node: any) => {
      if (node.tagName) {
        const exclusionClasses = ['dont-download']
        return !exclusionClasses.some((classname) =>
          node.classList.contains(classname)
        )
      }
      return true
    }

    toPng(ref.current, { filter })
      .then((dataUrl) => {
        // console.log(dataUrl)
        try {
          saveAs(
            dataUrl,
            `customer-price-points-for-${supplier.label}-${
              country ? country.split(' ').join('-') : ''
            }.png`
          )
        } catch (e) {
          saveAs(dataUrl, `${Date.now()}.png`)
        }
      })
      .catch(function (error) {
        console.error('oops, something went wrong!', error)
      })
  }

  useEffect(() => {
    return () => {
      setSupplier(initialSelectedSupplier)
      setCountry(null)
      setCustomer(SelectSupplierLabel)
    }
  }, [])

  return (
    <div ref={ref} style={{ backgroundColor: '#fff' }}>
      <Space
        className="dont-download"
        style={{
          marginBottom: '15px',
          display: 'flex',
          justifyContent: 'end',
        }}
      >
        <Typography.Text>Importer Country</Typography.Text>
        <CountrySelectionDropdown onChange={onCountryChange} />

        {country ? (
          <>
            <Typography.Text>Supplier</Typography.Text>
            <SupplierSelectionDropdown
              supplier={supplier}
              country={country}
              onChange={onSupplierChange}
            ></SupplierSelectionDropdown>
          </>
        ) : null}

        {allCustomers.length > 0 ? (
          <>
            <Typography.Text>Customer</Typography.Text>
            <Select
              value={customer}
              style={{ width: 200 }}
              onChange={(e) => {
                setCustomer(e)
              }}
            >
              {allCustomers.map((val: string) => (
                <Option value={val}>{val}</Option>
              ))}
            </Select>
          </>
        ) : null}
      </Space>

      {isSupplierGraphLoading && country != null ? (
        <Skeleton />
      ) : (
        <div>
          {supplierGraphData && supplier ? (
            <>
              {clonedDataArray.length > 0 ? (
                <>
                  <div
                    style={{
                      marginBottom: '15px',
                      display: 'flex',
                      justifyContent: 'space-between',
                    }}
                  >
                    {country && supplier ? (
                      <Typography.Title level={5}>
                        Supplier {supplier.label}'s pricing trend in {country}
                      </Typography.Title>
                    ) : null}

                    <Button
                      type="primary"
                      onClick={handleDownload}
                      size={'small'}
                      className="dont-download"
                    >
                      <DownloadOutlined />
                      Download
                    </Button>
                  </div>

                  <div
                    style={{
                      marginBottom: '15px',
                      display: 'flex',
                      justifyContent: 'space-between',
                    }}
                  >
                    {country && supplier ? (
                      <Typography.Title level={5}>
                        Comparison of prices in {currencyCode} per{' '}
                        {WEIGHT_LABELS[unit]}
                      </Typography.Title>
                    ) : null}
                  </div>

                  <CustomerPricePointsTable
                    customer={
                      customer !== SelectSupplierLabel ? customer : null
                    }
                    chartData={clonedDataArray}
                    supplier={supplier}
                  ></CustomerPricePointsTable>

                  <CustomerPricePointLineChart
                    customer={
                      customer !== SelectSupplierLabel ? customer : null
                    }
                    chartRawData={clonedDataArray}
                  ></CustomerPricePointLineChart>
                </>
              ) : (
                <Typography.Title level={5} style={{ textAlign: 'center' }}>
                  No Data
                </Typography.Title>
              )}
            </>
          ) : null}
        </div>
      )}
    </div>
  )
}
