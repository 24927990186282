import React, { useState, useRef } from 'react'
import { Layout, Button, Divider, Space, Progress, message } from 'antd'
import { DownloadOutlined } from '@ant-design/icons'
import Header from 'components/Header/Header'
import { FileType } from 'api/files'
import { handleDownloadOnClick, uploadProgressConfig } from 'utils'
import { downloadSecondaryNamesTrackerExcel } from 'api/molecules'
import { axios } from 'api/utils'
import create from 'zustand'
import produce from 'immer'
import Axios, { Canceler } from 'axios'
import { LargeVerticalSpacer } from 'components/Layout/Layout'

const CancelToken = Axios.CancelToken

type ProgressState = {
  [fileType in FileType]: {
    progress: number
    error: null | Error
  }
} & {
  set: (fn: (state: ProgressState) => void) => void
}

const DEFAULT_VALUE = {
  progress: 0,
  error: null,
}

const [useProgressIndicatorStore] = create<ProgressState>((set) => ({
  article57: DEFAULT_VALUE,
  dmf: DEFAULT_VALUE,
  exim: DEFAULT_VALUE,
  expert: DEFAULT_VALUE,
  moleculeMapping: DEFAULT_VALUE,
  orangeBook: DEFAULT_VALUE,
  smartChem: DEFAULT_VALUE,
  set: (fn) => set(produce(fn)),
}))

function ProgressIndicator({ fileType }: { fileType: FileType }) {
  const { error, progress } = useProgressIndicatorStore(
    (state) => state[fileType]
  )

  return (
    <Progress
      percent={progress}
      status={Boolean(error) ? 'exception' : undefined}
      {...uploadProgressConfig}
    />
  )
}

function Uploader({ fileType }: { fileType: FileType }) {
  const [file, setFile] = useState<File>()
  const cancelFn = useRef<Canceler>()
  const set = useProgressIndicatorStore((state) => state.set)

  const isUploadStarted = useProgressIndicatorStore(
    (state) => state[fileType].progress > 0
  )
  const isUploadEnded = useProgressIndicatorStore(
    (state) => state[fileType].progress >= 100
  )

  function handleFileInput(e: React.ChangeEvent<HTMLInputElement>) {
    const ext = e.target.value.match(/\.([^\.]+)$/)?.[1]

    if (fileType === 'moleculeMapping') {
      if (ext !== 'xlsx') {
        message.error('Only .xlsx files are accepted')
        // @ts-ignore
        e.target.value = null
        return
      }
    } else if (ext !== 'zip') {
      message.error('Only .zip files are accepted')
      // @ts-ignore
      e.target.value = null
      return
    }
    setFile(e.target.files?.[0])
  }

  function submitForm(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
    e.preventDefault()
    if (file === undefined) {
      message.error('Unexpected error occurred while uploading file')
      return
    }
    const formData = new FormData()
    formData.append('type', fileType)
    formData.append('file', file)
    axios
      .post('/files', formData, {
        onUploadProgress: (progressEvent) => {
          set((state) => {
            state[fileType].progress =
              (progressEvent.loaded / progressEvent.total) * 100
          })
        },
        cancelToken: new CancelToken(function executor(cancelerFn) {
          // An executor function receives a cancel function as a parameter
          cancelFn.current = cancelerFn
        }),
      })
      .then(() => {
        message.success('Successfully uploaded file')
      })
      .catch((error) => {
        if (Axios.isCancel(error)) {
          message.warning(`Upload cancelled`)
          set((state) => {
            state[fileType].error = null
            state[fileType].progress = 0
          })
          return
        }
        set((state) => {
          state[fileType].error = error
        })
        message.error(`Error uploading file ${error}`)
      })
  }

  const isFileChosen = Boolean(file)

  return (
    <LargeVerticalSpacer>
      <input
        accept={
          '.zip,application/zip,application/x-zip,application/x-zip-compressed'
        }
        onChange={handleFileInput}
        type="file"
      />
      {isFileChosen && (
        <LargeVerticalSpacer>
          <Space>
            <Button onClick={submitForm}>
              {isUploadStarted && !isUploadEnded ? 'Uploading...' : 'Upload'}
            </Button>
            {isUploadStarted && !isUploadEnded && (
              <Button
                onClick={() => {
                  cancelFn.current?.()
                }}
                danger
              >
                Cancel
              </Button>
            )}
          </Space>
          {isUploadStarted && <ProgressIndicator fileType={fileType} />}
        </LargeVerticalSpacer>
      )}
    </LargeVerticalSpacer>
  )
}

export default function Admin() {
  const filesConfig: { [file in FileType]: { headerTitle: string } } = {
    exim: {
      headerTitle: 'Exim',
    },
    dmf: {
      headerTitle: 'DMF',
    },
    expert: {
      headerTitle: 'Expert Price Input',
    },
    smartChem: {
      headerTitle: 'Smart Chem',
    },
    article57: {
      headerTitle: 'Article 57',
    },
    orangeBook: {
      headerTitle: 'Orange Book',
    },
    moleculeMapping: {
      headerTitle: 'Master Material List',
    },
  }

  return (
    <Layout id="admin-page">
      <Header />
      <Layout.Content>
        <Space
          direction="vertical"
          style={{ width: '100%', height: '100%' }}
          size="large"
        >
          {(Object.entries(filesConfig) as Array<
            [FileType, { headerTitle: string }]
          >).map(([fileType, { headerTitle }]) => {
            return (
              <Space
                direction="vertical"
                style={{ width: '100%' }}
                key={fileType}
              >
                <Divider orientation="left">
                  {`Upload ${headerTitle} file (.${
                    fileType === 'moleculeMapping' ? 'xlsx' : 'zip'
                  })`}
                </Divider>
                {fileType === 'moleculeMapping' ? (
                  <Space size="large">
                    <Uploader fileType={fileType} />
                    <Button
                      onClick={() =>
                        handleDownloadOnClick({
                          downloadAPI: downloadSecondaryNamesTrackerExcel,
                        })
                      }
                      icon={<DownloadOutlined />}
                    >
                      Download Previous Version
                    </Button>
                  </Space>
                ) : (
                  <Uploader fileType={fileType} />
                )}
              </Space>
            )
          })}
        </Space>
      </Layout.Content>
    </Layout>
  )
}
