import React, { useState } from 'react'
import {
  Layout,
  PageHeader,
  Descriptions,
  Tabs,
  Button,
  Row,
  Col,
  Space,
} from 'antd'
import { PercentageOutlined, LineChartOutlined } from '@ant-design/icons'
import Header from 'components/Header/Header'
import { useHistory } from 'react-router-dom'
import './Review.less'
import SmartChemVendorsTable from './components/SmartChemVendorsTable/SmartChemVendorsTable'
import { ErrorBoundryAndSuspenseFallback } from 'components/Fallback/Fallback'
import SuggestedVendorsTable from './components/SuggestedVendorsTable/SuggestedVendorsTable'
import TotalIdentifiedVendorsTable from './components/TotalIdentifiedVendorsTable/TotalIdentifiedVendorsTable'
import { materialDescription } from 'utils'
import { BuyersTable } from './components/BuyersTable/BuyersTable'
import Modal from 'antd/lib/modal/Modal'
import PriceTrendLineChart from './components/PriceTrendLineChart/PriceTrendLineChart'
import {
  QuantityRangeStatistic,
  CurrencyStatistic,
  QuantityStatistic,
  DateRangeStatistic,
  TextualStatictic,
} from 'components/Scalars/Scalars'
import {
  CurrencyCode,
  DATE_FORMAT,
  WeightCode,
  WEIGHT_LABELS,
  WEIGHT_SYMBOLS,
} from '../../constants'
import { InfoTooltip } from 'components/Layout/Layout'
import { useSearchItemDescription, useSearchItemResults } from './Review.hooks'
import { Material } from 'api/utils'
import CountriesExportingToIndia from './components/CountriesExportingToIndia'
import CustomerProfileForClientChart from './components/CustomerProfileForClientChart'
import ImporterPricePoints from './components/ImporterPricePoints'
import CustomerPricePointForSupplierLine from './components/CustomerPricePointForSupplierLine'

import {
  convertPricePerUnitOfWeight,
  convertWeight,
} from 'utils/helperFunctions'

const { TabPane } = Tabs

function SearchItemDesciption() {
  const {
    type,
    grade,
    hsCode,
    currentPricingPerUnit,
    totalAnnualizedVolume,
    unit,
    percentile,
    fromDate,
    toDate,
  } = useSearchItemDescription()
  return (
    <Descriptions column={10} className="molecule-descriptions">
      <Descriptions.Item>
        <TextualStatictic title="TYPE" value={type} />
      </Descriptions.Item>
      <Descriptions.Item>
        <TextualStatictic title="GRADE" value={grade.join(', ')} />
      </Descriptions.Item>
      <Descriptions.Item>
        <TextualStatictic title="HS CODE" value={hsCode} />
      </Descriptions.Item>
      <Descriptions.Item span={2}>
        <CurrencyStatistic
          title={`CURRENT PRICE PER ${WEIGHT_LABELS[unit]}`}
          value={convertPricePerUnitOfWeight(currentPricingPerUnit, unit, unit)}
          suffix={`/ ${WEIGHT_LABELS[unit]}`}
        />
      </Descriptions.Item>
      <Descriptions.Item span={2}>
        <QuantityStatistic
          title="CURRENT ANNUALIZED VOLUME"
          value={convertWeight(totalAnnualizedVolume, unit, unit)}
          suffix={WEIGHT_LABELS[unit]}
        />
      </Descriptions.Item>
      <Descriptions.Item>
        <QuantityStatistic
          title={
            <>
              PERCENTILE
              <InfoTooltip message="Please go to the last screen to change filters" />
            </>
          }
          value={percentile}
        />
      </Descriptions.Item>
      <Descriptions.Item span={2}>
        <DateRangeStatistic
          title={
            <>
              DATE RANGE
              <InfoTooltip message="Please go to the last screen to change filters" />
            </>
          }
          formatString={DATE_FORMAT.yyyyMM}
          ISODateStringRange={{
            fromDate,
            toDate,
          }}
        />
      </Descriptions.Item>
    </Descriptions>
  )
}

export type ReviewPageLocationState = {
  searchId: number
  searchItemId: number
  molecule: Material
  fromDate: string
  toDate: string
  grade: Array<string>
  totalAnnualizedVolume: number | null
  currentPricingPerUnit: number | null
  type: string | null
  unit: WeightCode
  hsCode: number | null
  currencyCode: CurrencyCode
  percentile: number
}

function SuggestedVendorsAndEstimatedSavingsBlock() {
  const {
    estimatedSavingsHigh,
    estimatedSavingsLow,
    unit,
  } = useSearchItemResults()
  console.log('[SEARCH RES] unit ', unit)
  return (
    <Row style={{ backgroundColor: '#bcc4ef' }}>
      <Col span={18}>
        <SuggestedVendorsTable />
      </Col>
      <Col className="estimated-savings-column" span={6}>
        <QuantityRangeStatistic
          title="ESTIMATED SAVINGS"
          range={{ low: estimatedSavingsLow, high: estimatedSavingsHigh }}
          suffix={<PercentageOutlined />}
        />
      </Col>
    </Row>
  )
}

export default function Review() {
  const history = useHistory()

  const {
    molecule,
    hsCode,
    grade,
    searchItemId,
    unit,
  } = useSearchItemDescription()

  const [showChartModal, setShowChartModal] = useState(false)
  const [showSupplierCountriesModal, setShowSupplierCountriesModal] = useState(
    false
  )
  const [showImporterPriceModal, setImporterPriceModal] = useState(false)
  const [showSupplierProfileModal, setShowSupplierProfileModal] = useState(
    false
  )
  const [showSupplierPointsModal, setShowSupplierPointsModal] = useState(false)

  return (
    <Layout id="molecule-review-page">
      <Header />
      <Layout.Content style={{ height: 'fit-content' }}>
        <PageHeader onBack={history.goBack} title={molecule.name} />
        <SearchItemDesciption />
        <ErrorBoundryAndSuspenseFallback>
          <SuggestedVendorsAndEstimatedSavingsBlock />
        </ErrorBoundryAndSuspenseFallback>
      </Layout.Content>
      <Tabs
        className="vendors-table-tabs"
        defaultActiveKey="1"
        tabBarExtraContent={
          <Space>
            <Button
              type="primary"
              onClick={() => setShowSupplierCountriesModal(true)}
              // icon={<LineChartOutlined />}
            >
              Target geography
            </Button>
            <Button
              type="primary"
              onClick={() => setImporterPriceModal(true)}
              // icon={<LineChartOutlined />}
            >
              Price benchmarks by buyer
            </Button>
            <Button
              type="primary"
              onClick={() => setShowSupplierProfileModal(true)}
              // icon={<LineChartOutlined />}
            >
              Supplier profile
            </Button>
            <Button
              type="primary"
              onClick={() => setShowSupplierPointsModal(true)}
              // icon={<LineChartOutlined />}
            >
              Price benchmarks by supplier
            </Button>

            <Button
              type="primary"
              onClick={() => setShowChartModal(true)}
              icon={<LineChartOutlined />}
            >
              Price Trend Analysis
            </Button>
          </Space>
        }
      >
        <TabPane tab="Price references" key="1">
          <ErrorBoundryAndSuspenseFallback>
            <TotalIdentifiedVendorsTable />
          </ErrorBoundryAndSuspenseFallback>
        </TabPane>
        <TabPane tab="See all vendors" key="2">
          <ErrorBoundryAndSuspenseFallback>
            <SmartChemVendorsTable />
          </ErrorBoundryAndSuspenseFallback>
        </TabPane>
        <TabPane tab="See buyers" key="3">
          <ErrorBoundryAndSuspenseFallback>
            <BuyersTable />
          </ErrorBoundryAndSuspenseFallback>
        </TabPane>
      </Tabs>
      <Modal
        title={materialDescription({ name: molecule.name, hsCode, grade })}
        visible={showChartModal}
        onCancel={() => setShowChartModal(false)}
        footer={null}
        width={'70%'}
      >
        <ErrorBoundryAndSuspenseFallback>
          <PriceTrendLineChart />
        </ErrorBoundryAndSuspenseFallback>
      </Modal>

      <Modal
        title="Target geography"
        visible={showSupplierCountriesModal}
        onCancel={() => setShowSupplierCountriesModal(false)}
        footer={null}
        width={'70%'}
      >
        <ErrorBoundryAndSuspenseFallback>
          <CountriesExportingToIndia />
        </ErrorBoundryAndSuspenseFallback>
      </Modal>

      <Modal
        title="Price benchmarks by buyer"
        visible={showImporterPriceModal}
        onCancel={() => setImporterPriceModal(false)}
        footer={null}
        width={'85%'}
      >
        <ErrorBoundryAndSuspenseFallback>
          <ImporterPricePoints></ImporterPricePoints>
        </ErrorBoundryAndSuspenseFallback>
      </Modal>

      <Modal
        title="Supplier profile"
        visible={showSupplierProfileModal}
        onCancel={() => setShowSupplierProfileModal(false)}
        footer={null}
        width={'70%'}
      >
        <ErrorBoundryAndSuspenseFallback>
          <CustomerProfileForClientChart></CustomerProfileForClientChart>
        </ErrorBoundryAndSuspenseFallback>
      </Modal>

      <Modal
        title="Price benchmarks by supplier"
        visible={showSupplierPointsModal}
        onCancel={() => setShowSupplierPointsModal(false)}
        footer={null}
        width={'85%'}
      >
        <ErrorBoundryAndSuspenseFallback>
          <CustomerPricePointForSupplierLine></CustomerPricePointForSupplierLine>
        </ErrorBoundryAndSuspenseFallback>
      </Modal>
    </Layout>
  )
}
