import { useSearchItemDescription } from 'pages/Review/Review.hooks'
import { Button, Tag, Typography, Spin } from 'antd'
import { DownloadOutlined } from '@ant-design/icons'
import React, { useCallback, useState } from 'react'
import { ArrowRightOutlined } from '@ant-design/icons'

import {
  Bar,
  XAxis,
  YAxis,
  // CartesianGrid,
  Tooltip,
  Legend,
  BarChart,
} from 'recharts'
import {
  addColorsForGraph,
  convertWeight,
  roundOff,
} from 'utils/helperFunctions'
// import { DummySupplierGraphData } from './DummySupplierGraphData'
import { useCurrentPng } from 'recharts-to-png'
import { saveAs } from 'file-saver'
import {
  MAX_NUMBER_LIMIT_FOR_CHARTS,
  WEIGHT_LABELS,
  WEIGHT_SYMBOLS,
} from '../../../../constants'

// const dataKeys = addColorsForGraph([
//   { id: '2019', fill: '#000' },
//   { id: '2020', fill: '#000' },
//   { id: '2021', fill: '#000' },
// ])

const CustomizedLabel = (props: any) => {
  const {
    x,
    y,
    fill,
    value,
    viewBox: { width },
  } = props

  return (
    <text
      x={x + width / 2}
      y={y}
      dy={-4}
      fontSize="9"
      fontFamily="sans-serif"
      fill={fill}
      textAnchor="middle"
    >
      {value}
    </text>
  )
}

export default function CountriesExportingToIndiaBarGraph({
  chartData,
  country,
}: {
  chartData: any[]
  country: string | null
}) {
  const [getPng, { ref, isLoading }] = useCurrentPng()
  const { unit, molecule } = useSearchItemDescription()
  const [showLegend, setShowLegend] = useState<boolean>(false)
  const [showLoader, setShowLoader] = useState<boolean>(false)
  const barWidth: number = 40

  // const data = DummySupplierGraphData

  let tempKeys: any[] = []
  let currentYear = new Date().getFullYear()
  const keysNotRepresentingYear = ['country']
  let maxQuantity = 0
  let sanitizedDataForGraph = chartData.map((val: any) => {
    const keys = Object.keys(val)
    const tempObj: any = {}
    for (let key of keys) {
      let newKey =
        String(key) === String(currentYear)
          ? `${currentYear} (YTD)`
          : String(key)
      // convet kg to mt or keep as it is for keys representing the year's quantity
      tempObj[newKey] = !keysNotRepresentingYear.includes(String(key))
        ? roundOff(convertWeight(val[key], WEIGHT_SYMBOLS.KGS, unit) || 0)
        : val[key]

      if (tempObj[newKey] >= MAX_NUMBER_LIMIT_FOR_CHARTS) {
        maxQuantity = tempObj[newKey]
      }
    }

    if (tempObj.country === 'null') {
      tempObj.country = 'NOT KNOWN'
    }

    return tempObj
  })

  if (maxQuantity > 0) {
    // if max quantity exceeds max limit ie. 1 million convert the values to per 1000 unit
    sanitizedDataForGraph = sanitizedDataForGraph.map((val: any) => {
      const keys = Object.keys(val).filter((key) => key !== 'country')
      const tempObj: any = { ...val }
      for (let key of keys) {
        tempObj[key] = roundOff(tempObj[key] / 1000)
      }
      return tempObj
    })
  }

  if (chartData.length > 0) {
    tempKeys = Object.keys(chartData[0])
      .filter((val) => !['country'].includes(val))
      .map((val) => Number(val))
      .sort((a, b) => a - b)
  }

  const dataKeys = addColorsForGraph(
    tempKeys.map((val) => ({
      id:
        String(val) === String(currentYear)
          ? `${currentYear} (YTD)`
          : String(val),
      fill: '#000',
    }))
  )

  const handleDownload = useCallback(async () => {
    setShowLoader(true)
    setShowLegend(true)

    setTimeout(async () => {
      const png = await getPng()
      // Verify that png is not undefined
      if (png) {
        console.log(png)
        // Download with FileSaver
        saveAs(
          png,
          `countries-exporting-to-${country?.split(' ').join('-')}.png`
        )
        setShowLegend(false)
        setTimeout(() => {
          setShowLoader(false)
        }, 1000)
      }
    }, 1000)
  }, [getPng, country])

  return (
    // <ResponsiveContainer height={400} width={`100%`}>
    <div style={{ position: 'relative' }}>
      {showLoader ? (
        <div
          style={{
            position: 'absolute',
            backgroundColor: 'rgba(255,255,255,0.9)',
            width: '100%',
            height: '100%',
            zIndex: 100,
            display: 'flex',
          }}
        >
          <Spin style={{ margin: 'auto' }} size="large" />
        </div>
      ) : null}

      <div
        style={{
          marginBottom: '15px',
          display: 'flex',
          justifyContent: 'space-between',
        }}
      >
        <Typography.Title level={5} style={{ marginBottom: 0 }}>
          Countries exporting {molecule.name} to {country}
        </Typography.Title>
        <Button type="primary" onClick={handleDownload} size={'small'}>
          <DownloadOutlined />
          Download
        </Button>
      </div>

      <div style={{ display: 'flex', justifyContent: 'center' }}>
        {dataKeys.map((val: any) => (
          <Tag color={val.fill}>{val.id}</Tag>
        ))}
        <span style={{ position: 'absolute', right: 0 }}>
          Scroll to view more &nbsp;
          <ArrowRightOutlined></ArrowRightOutlined>
        </span>
      </div>

      <div
        className="scroll"
        style={{ overflowX: 'auto', overflowY: 'hidden' }}
      >
        <BarChart
          ref={ref}
          width={(barWidth + 30) * dataKeys.length * chartData.length}
          height={500}
          data={sanitizedDataForGraph}
          // barCategoryGap={'50px'}
          barGap={1}
        >
          {/* <CartesianGrid strokeDasharray="3 3" /> */}
          <XAxis
            interval={0}
            hide={false}
            dataKey="country"
            minTickGap={40}
          ></XAxis>
          <YAxis
            hide={false}
            domain={[0, 'dataMax']}
            padding={{ top: 20, bottom: 0 }}
            label={{
              value: `${maxQuantity > 0 ? '000 ' : ''}${WEIGHT_LABELS[unit]}`,
              angle: -90,
              position: 'insideLeft',
            }}
            axisLine={false}
            tickLine={false}
            tick={false}
          ></YAxis>
          <Tooltip />
          {showLegend ? <Legend verticalAlign="top" /> : null}
          {dataKeys.map((val: any) => (
            <Bar
              barSize={barWidth}
              dataKey={val.id}
              fill={val.fill}
              unit={maxQuantity > 0 ? `` : ` ${WEIGHT_LABELS[unit]}`}
              label={<CustomizedLabel></CustomizedLabel>}
            />
          ))}
        </BarChart>
      </div>
    </div>
    // </ResponsiveContainer>
  )
}
