import React, { useEffect } from 'react'
import './App.less'
import { QueryCache, ReactQueryCacheProvider } from 'react-query'
import { LoginPage } from 'pages/Login/Login'
import Search from 'pages/Search/Search'
import Admin from 'pages/Admin/Admin'
import SearchResults from 'pages/SearchResults/SearchResults'
import Review from 'pages/Review/Review'
import Home from 'pages/Home/Home'
import VendorDetails from 'pages/VendorDetails/VendorDetails'
import {
  BrowserRouter as Router,
  Switch,
  Route,
  useHistory,
  Redirect,
} from 'react-router-dom'
import { Result, Button, Spin, ConfigProvider, message } from 'antd'
import { pagePaths } from './constants'
import { useAppWideStore } from 'App.state'
import {
  ReactQueryDevtoolsPanel,
  ReactQueryDevtools,
} from 'react-query-devtools'
import { AxiosApiError } from 'api/utils'
import { showApiErrorMessagePopup } from 'utils'

function NotFoundPage() {
  const history = useHistory()
  return (
    <Result
      status="404"
      title="404"
      subTitle="Sorry, the page you visited does not exist."
      extra={
        <Button type="primary" onClick={() => history.push(pagePaths.home)}>
          Back Home
        </Button>
      }
    />
  )
}

function ProtectedRoutes() {
  const { isAuthed, isAdmin } = useAppWideStore()

  if (isAuthed === 'NOT_AUTHED') {
    return <Redirect to={pagePaths.login} />
  }

  return (
    <Switch>
      <Route path={pagePaths.authCallback}>
        <Redirect to={pagePaths.home} />
      </Route>
      <Route exact path={'/'}>
        <Redirect to={pagePaths.home} />
      </Route>
      <Route path={pagePaths.home}>
        <Home />
      </Route>
      <Route path={pagePaths.search}>
        <Search />
      </Route>
      <Route path={pagePaths.searchResults}>
        <SearchResults />
      </Route>
      <Route path={pagePaths.review}>
        <Review />
      </Route>
      <Route path={pagePaths.vendor}>
        <VendorDetails />
      </Route>
      {isAdmin && (
        <Route path={pagePaths.admin}>
          <Admin />
        </Route>
      )}
      <Route>
        <NotFoundPage />
      </Route>
    </Switch>
  )
}

const queryCache = new QueryCache({
  defaultConfig: {
    mutations: {
      onError: (unknownError) => {
        const error = unknownError as AxiosApiError
        showApiErrorMessagePopup(error)
        console.error(error)
      },
    },
    queries: {
      retry: (failureCount, unknownError) => {
        const error = unknownError as AxiosApiError
        if (error.request) {
          return false
        }
        if (error.response) {
          const statusCode = error.response.status
          if (statusCode < 500) {
            return false
          } else if (failureCount > 2) {
            return false
          } else {
            return true
          }
        }
        return false
      },
    },
  },
})

export default function App() {
  const { isAuthed, info, checkIfAuth, login } = useAppWideStore()

  useEffect(function performAuthenticationOnMount() {
    checkIfAuth()
    login()
    //eslint-disable-next-line
  }, [])

  if (isAuthed === 'LOADING' || (isAuthed === 'AUTHED' && info === null)) {
    return (
      <Spin
        size="large"
        style={{ display: 'grid', placeItems: 'center', height: '100%' }}
      />
    )
  }

  return (
    <ReactQueryCacheProvider queryCache={queryCache}>
      <Router forceRefresh={true}>
        <Switch>
          <Route
            path={pagePaths.login}
            render={({ location }) => {
              if (isAuthed === 'AUTHED') {
                return <Redirect to={pagePaths.home} />
              }
              return <LoginPage />
            }}
          />
          <Route>
            <ProtectedRoutes />
          </Route>
        </Switch>
      </Router>
      <ReactQueryDevtools initialIsOpen />
    </ReactQueryCacheProvider>
  )
}
