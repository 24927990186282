import { createReduxStyleStore } from 'utils/zustand'
import produce from 'immer'
import { v4 as uuidv4 } from 'uuid'
import { FieldError } from 'rc-field-form/lib/interface'
import {
  SearchResultsAPIRequestBody,
  SearchResultsAPIData,
  XlsxUploadSearchItemsAPIData,
} from 'api/search'
import {
  DEFAULT_UNIT,
  CURRENCY_CODE,
  CurrencyCode,
  WeightCode,
  WEIGHT_SYMBOLS,
} from '../../constants'
import pick from 'lodash/pick'
import omit from 'lodash/omit'
import { useLocation } from 'react-router-dom'

export interface SearchItemValidationErrors {
  rowKey: string
  fieldErrors: FieldError[]
}

export type SearchItem = {
  [prop in keyof SearchResultsAPIRequestBody['data'][number]]:
    | SearchResultsAPIRequestBody['data'][number][prop]
    | null
} & {
  rowKey: string
  name: string | null
  grade: string[]
}

export type NonNullableSearchFilters = 'name' | 'moleculeId' | 'unit' | 'grade'

export type ValidatedSearchItem = {
  [propName in keyof SearchItem]: propName extends NonNullableSearchFilters
    ? NonNullable<SearchItem[propName]>
    : SearchItem[propName]
}

export type ColumnIndex = keyof Omit<
  SearchItem,
  'rowKey' | 'duration' | 'unit' | 'moleculeId'
>

export const COLUMN_DATA_INDEX: Readonly<
  {
    [columnIndex in ColumnIndex]: columnIndex
  }
> = {
  currentPricingPerUnit: 'currentPricingPerUnit',
  grade: 'grade',
  name: 'name',
  totalAnnualizedVolume: 'totalAnnualizedVolume',
  type: 'type',
  hsCode: 'hsCode',
}

const EXCEPT_NAME = omit(COLUMN_DATA_INDEX, ['name'])

const COLUMN_KEYS_EXCEPT_NAME = Object.keys(EXCEPT_NAME) as Array<
  keyof typeof EXCEPT_NAME
>

export type SearchItemBulkUploadResponseErrors = {
  [rowKey: string]:
    | {
        [columnName in ColumnIndex]:
          | {
              value: any
              error: string
            }
          | undefined
      }
    | undefined
}

export type FlowType =
  | 'DIRECT_FORM_SEARCH'
  | 'BULK_UPLOAD_SEARCH'
  | 'NOT_YET_CHOOSEN'

type SearchFormState = {
  searchItems: SearchItem[]
  searchItemValidationErrors: SearchItemValidationErrors[]
  searchItemBulkUploadResponseErrors: SearchItemBulkUploadResponseErrors
  flowType: FlowType
  dataVersionFromFileUpload: number
  currencyCode: CurrencyCode
  unit: WeightCode
  excludeMin: number | undefined
  excludeMax: number | undefined
  searchId: number | null
}

type ChangeCurrencyCode = {
  type: 'CHANGE_CURRENCY_CODE'
  payload: {
    currencyCode: CurrencyCode
  }
}

type AddNewSearchItem = {
  type: 'ADD_NEW_SEARCH_ITEM'
}

type DeleteSearchItem = {
  type: 'DELETE_SEARCH_ITEM'
  payload: {
    rowKey: string
  }
}

type UpdateSearchItem = {
  type: 'UPDATE_SEARCH_ITEM'
  payload: {
    updatedSearchItem: SearchItem
    updatedSearchItemValidationErrors: SearchItemValidationErrors
  }
}

type UpdateMaterialId = {
  type: 'UPDATE_MATERIAL_ID'
  payload: {
    rowKey: string
    id: number
  }
}

type UpdateHsCode = {
  type: 'UPDATE_HS_CODE'
  payload: {
    rowKey: string
    id: number | null
  }
}

type ResetState = {
  type: 'RESET_STATE'
}

type SET_FLOW_TYPE = {
  type: 'SET_FLOW_TYPE'
  payload: {
    flowType: FlowType
  }
}

type HydrateFromSavedSearchAPIData = {
  type: 'HYDRATE_FROM_SAVED_SEARCH_API_DATA'
  payload: SearchResultsAPIData
}

type HydrateFromFileUploadResponseData = {
  type: 'HYDRATE_FROM_FILE_UPLOAD_RESPONSE_DATA'
  payload: XlsxUploadSearchItemsAPIData
}

type SET_SEARCH_ID = {
  type: 'SET_SEARCH_ID'
  payload: {
    searchId: number
  }
}

type CHANGE_WEIGHT_RANGE = {
  type: 'CHANGE_WEIGHT_RANGE'
  payload: {
    excludeMin: number
    excludeMax: number
  }
}

type CHANGE_WEIGHT_UNIT = {
  type: 'CHANGE_WEIGHT_UNIT'
  payload: {
    unit: WeightCode
  }
}

export type SearchFormAction =
  | AddNewSearchItem
  | DeleteSearchItem
  | UpdateSearchItem
  | UpdateMaterialId
  | UpdateHsCode
  | ResetState
  | SET_FLOW_TYPE
  | ChangeCurrencyCode
  | HydrateFromSavedSearchAPIData
  | HydrateFromFileUploadResponseData
  | SET_SEARCH_ID
  | CHANGE_WEIGHT_RANGE
  | CHANGE_WEIGHT_UNIT

export function searchFormReducer(
  state: SearchFormState,
  action: SearchFormAction
) {
  return produce(state, (draft) => {
    console.log('[SET STATE] ', action)
    switch (action.type) {
      case 'ADD_NEW_SEARCH_ITEM': {
        const rowKey = uuidv4()
        const newSearchItem: SearchItem = {
          rowKey,
          type: null,
          name: null,
          grade: [],
          currentPricingPerUnit: null,
          totalAnnualizedVolume: null,
          unit: draft.unit,
          moleculeId: null,
          hsCode: null,
        }
        const newSearchItemValidationErrors: SearchItemValidationErrors = {
          rowKey,
          fieldErrors: [],
        }
        draft.searchItems.push(newSearchItem)
        draft.searchItemValidationErrors.push(newSearchItemValidationErrors)
        break
      }

      case 'DELETE_SEARCH_ITEM': {
        const indexOfSearchItemToDelete = draft.searchItems.findIndex(
          (searchItem) => searchItem.rowKey === action.payload.rowKey
        )
        draft.searchItems.splice(indexOfSearchItemToDelete, 1)
        draft.searchItemValidationErrors.splice(indexOfSearchItemToDelete, 1)
        break
      }

      case 'UPDATE_SEARCH_ITEM': {
        const { payload } = action
        const searchItemIndex = draft.searchItems.findIndex(
          (searchItem) => searchItem.rowKey === payload.updatedSearchItem.rowKey
        )
        if (searchItemIndex === -1) {
          console.error(
            `search item to update not found for UPDATE_SEARCH_ITEM action`
          )
          break
        }
        const searchItemToUpdate = draft.searchItems[searchItemIndex]
        const searchItemValidationErrorsToUpdate =
          draft.searchItemValidationErrors[searchItemIndex]
        Object.assign(searchItemToUpdate, payload.updatedSearchItem)
        Object.assign(
          searchItemValidationErrorsToUpdate,
          payload.updatedSearchItemValidationErrors
        )
        break
      }

      case 'UPDATE_MATERIAL_ID': {
        const { payload } = action
        const searchItem = draft.searchItems.find(
          ({ rowKey }) => rowKey === payload.rowKey
        )
        if (searchItem === undefined) {
          console.error(
            `searchItem to update not found for UPDATE_MATERIAL_ID action`
          )
          break
        }
        searchItem.moleculeId = payload.id
        break
      }

      case 'UPDATE_HS_CODE': {
        const { payload } = action
        const searchItem = draft.searchItems.find(
          ({ rowKey }) => rowKey === payload.rowKey
        )
        if (searchItem === undefined) {
          console.error(
            `searchItem to update not found for UPDATE_HS_CODE action`
          )
          break
        }
        searchItem.hsCode = payload.id
        break
      }

      case 'SET_FLOW_TYPE': {
        const { payload } = action
        draft.flowType = payload.flowType
        break
      }

      case 'RESET_STATE': {
        Object.assign(draft, initialState)
        break
      }

      case 'CHANGE_CURRENCY_CODE': {
        const { payload } = action
        draft.currencyCode = payload.currencyCode
        break
      }

      case 'CHANGE_WEIGHT_RANGE': {
        const { payload } = action
        draft.excludeMin = payload.excludeMin
        draft.excludeMax = payload.excludeMax
        console.log('[chagne_weight] ', draft)
        break
      }

      case 'CHANGE_WEIGHT_UNIT': {
        const { payload } = action
        draft.unit = payload.unit
        for (let val of draft.searchItems) {
          val.unit = payload.unit
        }
        break
      }

      case 'HYDRATE_FROM_SAVED_SEARCH_API_DATA': {
        const { payload } = action
        const savedSearchItems: Array<SearchItem> = []
        const validationErrors: Array<SearchItemValidationErrors> = []
        for (const searchItem of payload.searchItems) {
          const rowKey = uuidv4()
          const {
            molecule: { id, name },
            unit,
          } = searchItem
          savedSearchItems.push({
            ...pick(searchItem, COLUMN_KEYS_EXCEPT_NAME),
            moleculeId: id,
            name,
            unit,
            rowKey,
          })
          validationErrors.push({
            rowKey,
            fieldErrors: [],
          })
        }
        draft.searchItems = savedSearchItems
        draft.searchItemValidationErrors = validationErrors
        draft.currencyCode = payload.currencyCode
        draft.excludeMax = payload.excludeMax
        draft.excludeMin = payload.excludeMin
        draft.unit = payload.unit
        break
      }

      case 'HYDRATE_FROM_FILE_UPLOAD_RESPONSE_DATA': {
        const { payload } = action
        for (const { searchItem, columns } of payload.errors) {
          const rowKey = uuidv4()
          draft.searchItems.push({
            moleculeId: null,
            ...searchItem,
            rowKey,
          })

          draft.searchItemValidationErrors.push({
            rowKey,
            fieldErrors: [],
          })

          draft.searchItemBulkUploadResponseErrors[rowKey] = columns.reduce(
            (rowErrors, columnName) => {
              rowErrors[columnName] = {
                value: searchItem[columnName],
                error: 'Invalid value',
              }
              return rowErrors
            },
            {} as NonNullable<SearchItemBulkUploadResponseErrors[string]>
          )
        }

        for (const validSearchItem of payload.validatedSearchItems) {
          const rowKey = uuidv4()
          draft.searchItems.push({
            ...validSearchItem,
            rowKey,
          })
          draft.searchItemValidationErrors.push({
            rowKey,
            fieldErrors: [],
          })
        }

        draft.dataVersionFromFileUpload += 1
        break
      }

      case 'SET_SEARCH_ID': {
        const { payload } = action
        draft.searchId = payload.searchId
      }
    }
  })
}

const initialState: SearchFormState = {
  searchItems: [],
  searchItemValidationErrors: [],
  searchItemBulkUploadResponseErrors: {},
  flowType: 'NOT_YET_CHOOSEN',
  dataVersionFromFileUpload: 0,
  currencyCode: CURRENCY_CODE.USD,
  unit: WEIGHT_SYMBOLS.MT,
  excludeMin: undefined,
  excludeMax: undefined,
  searchId: null,
}

const storeName = 'SearchForm'

export const [useSearchFormStore, searchFormStoreAPI] = createReduxStyleStore({
  reducer: searchFormReducer,
  initialState,
  storeName,
  options: { persist: true },
})

function calculateTotalErrorCountForASearchItem(
  formErrors: SearchItemValidationErrors
) {
  const totalErrorCountForASearchItem = formErrors.fieldErrors.reduce(
    (fieldErrorsAcc, fieldError) => {
      return fieldErrorsAcc + fieldError.errors.length
    },
    0
  )

  return totalErrorCountForASearchItem
}

export function totalErrorCountSelector(state: SearchFormState) {
  const totalErrorCountAcrossAllSearchItems = state.searchItemValidationErrors.reduce(
    (formErrorsAcc, formErrors) => {
      const errorCountForThisSearchItem = calculateTotalErrorCountForASearchItem(
        formErrors
      )
      return formErrorsAcc + errorCountForThisSearchItem
    },
    0
  )

  return totalErrorCountAcrossAllSearchItems
}

export function formLengthSelector(state: SearchFormState) {
  return state.searchItems.length
}

export function checkIfEmptySelector(state: SearchFormState) {
  return formLengthSelector(state) === 0
}

export function searchItemPropertySelector<PropName extends keyof SearchItem>(
  state: SearchFormState,
  { rowKey, propName }: { rowKey: string; propName: PropName }
) {
  const searchItem = state.searchItems.find(
    (searchItem) => searchItem.rowKey === rowKey
  )
  return searchItem?.[propName]
}

export function currencyCodeSelector(state: SearchFormState) {
  return state.currencyCode
}

export function weightRangeSelector(state: SearchFormState) {
  console.log('[weightRangeSelector] ', state)
  const { unit, excludeMin, excludeMax } = state
  return { unit, excludeMin, excludeMax }
}

export function unitSelector(state: SearchFormState) {
  const { unit } = state
  return unit
}

export type ValidMaterialId = {
  rowKey: string
  id: number
}

export type SearchPageLocationState = {
  searchId: number
  isSavedSearchJourney: boolean
}

export function useSearchPageDescription() {
  return useLocation<SearchPageLocationState>().state
}
