import { Space, Table, Tag, Typography } from 'antd'
import React, { useEffect, useRef, useState } from 'react'
import { roundOff } from 'utils/helperFunctions'
import {
  CustomerNameColumnName,
  DiffToLowestLabel,
  LowestPriceLabel,
} from './constants'

import './table.css'

export default function CustomerPricePointsTable({
  customer,
  chartData,
  supplier,
}: any) {
  const tableWrapperRef = useRef<any>(null)
  const [tableWidth, settableWidth] = useState(0)

  useEffect(() => {
    settableWidth(tableWrapperRef.current.getBoundingClientRect().width)
  }, [])

  const newArr = chartData.map((val: any) => {
    const tmepObj: any = { [CustomerNameColumnName]: val.customer }
    for (let monthObj of val.pricePoints) {
      tmepObj[monthObj.month] = monthObj.price
    }
    return tmepObj
  })

  const monthNamesWithCustomerKey = Object.keys(newArr[0]) //.filter((key:string) => key !== "customer");

  // for calculating lowest
  const lowestPriceObj: any = {
    [CustomerNameColumnName]: LowestPriceLabel,
  }
  const onlyMonthNames = monthNamesWithCustomerKey.filter(
    (key: string) => key !== CustomerNameColumnName
  )

  for (let month of onlyMonthNames) {
    let tempArr: any = []
    for (let custObj of chartData) {
      const tempDataObj = custObj.pricePoints.find(
        (val: any) => val.month === month
      )
      if (tempDataObj) tempArr.push(tempDataObj.price)
    }
    const filteredArr = tempArr.filter(
      (val: any) => ![null, undefined].includes(val)
    )
    const minValue = Math.min.apply(Math, filteredArr)
    lowestPriceObj[month] = filteredArr.includes(minValue) ? minValue : null

    // const minValue = Math.min.apply(Math, tempArr);
    // lowestPriceObj[month] = minValue;
  }
  newArr.push(lowestPriceObj)

  // for calculating difference
  const selectedCustomer = customer

  if (selectedCustomer) {
    const diffToLowsestObj: any = {
      [CustomerNameColumnName]: DiffToLowestLabel,
    }

    const selectedCustomerObj = newArr.find(
      (val: any) => val[CustomerNameColumnName] === customer
    )

    if (selectedCustomerObj) {
      for (let month of onlyMonthNames) {
        if (lowestPriceObj[month] && selectedCustomerObj[month]) {
          const diff = Math.abs(
            lowestPriceObj[month] - selectedCustomerObj[month]
          )
          const total = (lowestPriceObj[month] + selectedCustomerObj[month]) / 2
          const percent = roundOff((diff / total) * 100)
          diffToLowsestObj[month] = `${percent === 0 ? '0.0' : percent}%`
        } else {
          diffToLowsestObj[month] = null
        }
      }
      newArr.push(diffToLowsestObj)
    }
  }

  const columns = monthNamesWithCustomerKey.map((monthNameOrKey) => ({
    title: monthNameOrKey,
    dataIndex: monthNameOrKey,
    key: monthNameOrKey,
    className: onlyMonthNames.includes(monthNameOrKey) ? 'month-col' : '',
    render: (value: any) => {
      if (
        onlyMonthNames.includes(monthNameOrKey) &&
        lowestPriceObj[monthNameOrKey] === value &&
        ![null, undefined].includes(value)
      ) {
        return <Tag>{value === 0 ? '0.0' : value}</Tag>
      }
      return value === 0 ? '0.0' : value
    },
  }))

  const addRowClassName = (record: any, index: number) => {
    console.log('AddRowClassName ', record, selectedCustomer)
    if (record[CustomerNameColumnName] === selectedCustomer) {
      return 'selected-row'
    } else if (record[CustomerNameColumnName] === DiffToLowestLabel) {
      return 'green-row'
    }
    return ''
  }

  return (
    <div ref={tableWrapperRef}>
      <Space
        style={{
          display: 'flex',
          marginBottom: '10px',
          flexDirection: 'row-reverse',
        }}
      >
        <Typography.Text strong>* Box indicates lowest price</Typography.Text>
      </Space>
      <Table
        rowClassName={addRowClassName}
        pagination={false}
        size="small"
        dataSource={newArr}
        columns={columns}
        scroll={{ x: tableWidth }}
        bordered={true}
      />
    </div>
  )
}
