import React from 'react'
import { Layout, PageHeader, Tabs, Button, Descriptions, Space } from 'antd'
import { PercentageOutlined, DownloadOutlined } from '@ant-design/icons'
import Header from 'components/Header/Header'
import {
  downloadSummary,
  downloadMoleculeSearchResults as downloadSearchResults,
} from 'api/search'
import { useHistory } from 'react-router-dom'
import './SearchResults.less'
import { ErrorBoundryAndSuspenseFallback } from 'components/Fallback/Fallback'
import { handleDownloadOnClick } from 'utils'
import { SearchDatabaseSummary } from 'components/SearchDatabaseSummary/SearchDatabaseSummary'
import {
  QuantityRangeStatistic,
  CurrencyRangeStatistic,
  CurrencyStatistic,
} from 'components/Scalars/Scalars'
import {
  InfoTooltip,
  LargeVerticalSpacer,
  HideForTrialUser,
} from 'components/Layout/Layout'
import { SearchItemWiseResultsList } from './components/SearchItemWiseResultsList/SearchItemWiseResultsList'
import {
  GradeSelectorWrapper,
  MaterialSelectorWrapper,
  ToDateWrapper,
  FromDateWrapper,
  PercentileFilterWrapper,
} from './components/Filters/Filters'
import { CurrencyCode, WeightCode } from '../../constants'
import { useSearchDeescription } from './SearchResults.hooks'
import { useSavedSearchResultsQuery } from 'utils/reactQuery'

const { TabPane } = Tabs

function SearchResultsSummaryDescription() {
  const { summary, searchItems } = useSavedSearchResultsQuery()
  const {
    currentSpend,
    averagePercentageSavingsHigh,
    averagePercentageSavingsLow,
    projectedSpendHigh,
    projectedSpendLow,
  } = summary

  const noOfMolecules = searchItems.length

  const descripitionTitleSuffix = `SPEND ON ${noOfMolecules} ${
    noOfMolecules > 1 ? 'MATERIALS' : 'MATERIAL'
  }`

  return (
    <Descriptions>
      <Descriptions.Item>
        <CurrencyStatistic
          value={currentSpend}
          title={
            <>
              CURRENT {descripitionTitleSuffix}
              <InfoTooltip message="Based on information provided by the user" />
            </>
          }
        />
      </Descriptions.Item>
      <Descriptions.Item>
        <CurrencyRangeStatistic
          title={
            <>
              PROJECTED {descripitionTitleSuffix}
              <InfoTooltip message="Based on information generated by the search" />
            </>
          }
          range={{
            low: projectedSpendLow,
            high: projectedSpendHigh,
          }}
        />
      </Descriptions.Item>
      <Descriptions.Item>
        <QuantityRangeStatistic
          title={`ESTIMATED SAVINGS`}
          suffix={<PercentageOutlined />}
          range={{
            low: averagePercentageSavingsLow,
            high: averagePercentageSavingsHigh,
          }}
        />
      </Descriptions.Item>
    </Descriptions>
  )
}

function SummaryDownloadLink() {
  const { searchId } = useSearchDeescription()

  return (
    <Button
      type="link"
      onClick={() =>
        handleDownloadOnClick({
          downloadAPI: () => downloadSummary({ searchId }),
        })
      }
    >
      Download Summary <DownloadOutlined />
    </Button>
  )
}

function SearchResultsListDownloadLink() {
  const { searchId } = useSearchDeescription()

  return (
    <Button
      type="link"
      onClick={() =>
        handleDownloadOnClick({
          downloadAPI: () => downloadSearchResults({ searchId }),
        })
      }
    >
      Download Material List <DownloadOutlined />
    </Button>
  )
}

export type SearchResultsPageParamsInURL = {
  searchId: number
  currencyCode: CurrencyCode
  unit: WeightCode
}

export default function SearchResults() {
  const history = useHistory()

  return (
    <Layout id="search-results-page">
      <Header />
      <Layout.Content>
        <PageHeader
          onBack={history.goBack}
          title={'Summary'}
          subTitle={
            '(The summary reflects the available data from all resulting vendors)'
          }
          className={'summary-page-header'}
          extra={
            <Space size="large">
              <SearchDatabaseSummary />
              <HideForTrialUser>
                <Button onClick={history.goBack} type="link">
                  Edit Search
                </Button>
              </HideForTrialUser>
              <HideForTrialUser>
                <SummaryDownloadLink />
              </HideForTrialUser>
            </Space>
          }
        />
        <LargeVerticalSpacer>
          <ErrorBoundryAndSuspenseFallback suspenseFallbackTitle={false}>
            <SearchResultsSummaryDescription />
          </ErrorBoundryAndSuspenseFallback>
          <Tabs
            defaultActiveKey="1"
            tabBarExtraContent={
              <Space size="large">
                <PercentileFilterWrapper />
                <FromDateWrapper />
                <ToDateWrapper />
                <MaterialSelectorWrapper />
                <GradeSelectorWrapper />
                <HideForTrialUser>
                  <SearchResultsListDownloadLink />
                </HideForTrialUser>
              </Space>
            }
          >
            <TabPane tab="Material wise list" key="1">
              <ErrorBoundryAndSuspenseFallback noOfSuspenseFallbacks={2}>
                <SearchItemWiseResultsList />
              </ErrorBoundryAndSuspenseFallback>
            </TabPane>
          </Tabs>
        </LargeVerticalSpacer>
      </Layout.Content>
    </Layout>
  )
}
