import React from 'react'
import { Typography } from 'antd'

export default function ChartLegends({
  legendsData,
}: {
  legendsData: {
    key: string
    fill: string
    color?: string
  }[]
}) {
  return (
    <>
      <div
        style={{
          textAlign: 'center',
          padding: '10px 20px 0px 20px',
          zIndex: 0,
          display: 'flex',
          flexWrap: 'wrap',
          alignContent: 'center',
          justifyContent: 'center',
        }}
      >
        {legendsData.map((val: any) => {
          return (
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                marginRight: '10px',
                marginBottom: '5px',
              }}
            >
              <div
                style={{
                  width: '25px',
                  height: '17px',
                  backgroundColor: val.fill,
                  borderRadius: '2px',
                  marginRight: '5px',
                }}
              ></div>
              <Typography.Text>{val.key}</Typography.Text>
              {/* <span>{val.key}</span> */}
            </div>
          )
        })}
      </div>
    </>
  )
}
