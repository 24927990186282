import { Space, Typography, Select } from 'antd'
import { WeightCode, WEIGHT_SYMBOLS_LIST, WEIGHT_LABELS } from '../../constants'
import React, { useState, useEffect } from 'react'

const WEIGHT_OPTIONS = WEIGHT_SYMBOLS_LIST.map((val) => ({
  value: val,
  label: WEIGHT_LABELS[val],
}))

const ToggleWeightUnit = ({
  onInputChange,
  unit: initialWeightUnit = null,
}: any) => {
  const [unit, setWeightUnit] = useState<WeightCode>(
    initialWeightUnit || WEIGHT_OPTIONS[0].value
  )

  useEffect(() => {
    if (onInputChange) onInputChange({ unit })
  }, [unit])

  return (
    <Space>
      <Typography.Text strong>Unit of measure</Typography.Text>
      <Select
        options={WEIGHT_OPTIONS}
        value={unit}
        onSelect={(selection) => {
          setWeightUnit(selection)
        }}
      />
    </Space>
  )
}

export default ToggleWeightUnit
