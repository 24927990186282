import { WeightCode, WEIGHT_SYMBOLS } from '../constants'
import { MCK_CAHRT_COLORS } from '../constants/ChartColors'

export const convertPricePerUnitOfWeight = (
  val: number | null,
  formWtUnit: WeightCode | string,
  toWtUnit: WeightCode | string
) => {
  if (!val) return val
  if (formWtUnit === toWtUnit) return val
  else if (
    formWtUnit === WEIGHT_SYMBOLS.KGS &&
    toWtUnit === WEIGHT_SYMBOLS.MT
  ) {
    return val * 1000
  } else if (
    formWtUnit === WEIGHT_SYMBOLS.MT &&
    toWtUnit === WEIGHT_SYMBOLS.KGS
  ) {
    return val / 1000
  } else return 0
}

export const convertWeight = (
  val: number | null,
  formWtUnit: WeightCode | string,
  toWtUnit: WeightCode | string
) => {
  if (!val) return val
  if (formWtUnit === toWtUnit) return val
  else if (
    formWtUnit === WEIGHT_SYMBOLS.KGS &&
    toWtUnit === WEIGHT_SYMBOLS.MT
  ) {
    return val / 1000
  } else if (
    formWtUnit === WEIGHT_SYMBOLS.MT &&
    toWtUnit === WEIGHT_SYMBOLS.KGS
  ) {
    return val * 1000
  } else return 0
}

export const addColorsForGraph = (data: any) => {
  for (let i = 0; i < data.length; i++) {
    const totalLen = MCK_CAHRT_COLORS.length - 1
    const index = i % totalLen
    const colorObj = MCK_CAHRT_COLORS[index]
    data[i]['fill'] = colorObj.fill
    data[i]['color'] = colorObj.color
  }
  return data
}

export const getMonthNameFromDateString = (dtStr: string) => {
  const monthNames = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ]

  const d = new Date(dtStr)
  return monthNames[d.getMonth()]
}

export const roundOff = (value: number, decimals: number = 1) => {
  return Number(
    Math.round(Number(String(value) + 'e' + String(decimals))) +
      'e-' +
      String(decimals)
  )
}

export const getRankFromDotGraph = (
  list: any[],
  forClient: string,
  month: string,
  allSuppliers: string[]
): { rank: number | null; rankOutOf: number | null } => {
  const tempObj = list.find((val) => val.month === month)
  // if client not exist
  if (!tempObj[forClient]) return { rank: null, rankOutOf: null }

  let totalClients = 0
  if (tempObj) {
    totalClients = Object.keys(tempObj).filter(
      (key: string) => !['month', 'monthlyAveragePrice'].includes(key)
    ).length
  }

  const arrayToFilter = []
  for (let supplier of allSuppliers) {
    if (tempObj.hasOwnProperty(supplier)) {
      arrayToFilter.push({ name: supplier, val: tempObj[supplier] })
    }
  }

  arrayToFilter.sort((a, b) => a.val - b.val)

  let rank = 0

  const alreadyRankedValues = new Set()
  for (let val of arrayToFilter) {
    if (!alreadyRankedValues.has(val.val)) {
      rank++
    }
    alreadyRankedValues.add(val.val)
    if (val.name === forClient) {
      return { rank, rankOutOf: totalClients }
    }
  }

  return { rank: null, rankOutOf: null }
}

export const getPremiumToL1 = (
  list: any[],
  forClient: string,
  month: string,
  allSuppliers: string[]
) => {
  const tempObj = list.find((val) => val.month === month)
  const arrayToFilter = []
  for (let supplier of allSuppliers) {
    // arrayToFilter.push({ name: val, val: tempObj[val] || 0 })
    if (tempObj.hasOwnProperty(supplier)) {
      arrayToFilter.push({ name: supplier, val: tempObj[supplier] })
    }
  }

  if (arrayToFilter.length <= 0) return null
  arrayToFilter.sort((a, b) => a.val - b.val)
  const clientAvgPoint = tempObj[forClient] || 0
  const lowestAvgPoint = arrayToFilter[0].val || 0

  const premiumToL1 = roundOff(
    ((clientAvgPoint - lowestAvgPoint) / clientAvgPoint) * 100,
    0
  )
  return premiumToL1
}

export const getPremiumToAverage = (
  list: any[],
  forClient: string,
  month: string,
  allSuppliers: string[]
) => {
  const tempObj = list.find((val) => val.month === month)

  const clientAvgPoint = tempObj[forClient] || 0
  const monthlyAveragePrice = tempObj['monthlyAveragePrice']

  const premiumToAvg = roundOff(
    ((clientAvgPoint - monthlyAveragePrice) / clientAvgPoint) * 100,
    0
  )
  return premiumToAvg
}
