import { Material } from 'api/utils'
import { SearchItemResults } from 'api/search'
import produce from 'immer'
import { createReduxStyleStore } from 'utils/zustand'
import sortBy from 'lodash/sortBy'

export type State = {
  selectedMaterial: Material | null
  selectedGrade: string | null
  searchItemsResultsToView: SearchItemResults[]
  searchItemsResultsWithNoFilters: SearchItemResults[]
}

type FilterByMaterialName = {
  type: 'FILTER BY MATERIAL NAME'
  payload: {
    material: Material
  }
}

type ResetMaterialNameFilter = {
  type: 'RESET MATERIAL NAME FILTER'
}

type FilterByGrade = {
  type: 'FILTER BY GRADE'
  payload: {
    grade: string
  }
}

type ResetGradeFilter = {
  type: 'RESET GRADE FILTER'
}

type UpdateSearchItemsResultsFromAPIResponse = {
  type: 'UPDATE SEARCH ITEMS RESULTS FROM API RESPONSE'
  payload: {
    searchItemsResults: SearchItemResults[]
  }
}

export type Action =
  | FilterByMaterialName
  | ResetMaterialNameFilter
  | FilterByGrade
  | ResetGradeFilter
  | UpdateSearchItemsResultsFromAPIResponse

function filterByMaterial({
  searchItemsResultsToView,
  selectedMaterial,
}: {
  searchItemsResultsToView: SearchItemResults[]
  selectedMaterial: Material
}) {
  return searchItemsResultsToView.filter(({ molecule }) => {
    return molecule.name === selectedMaterial.name
  })
}

function filterByGrade({
  searchItemsResultsToView,
  selectedGrade,
}: {
  searchItemsResultsToView: SearchItemResults[]
  selectedGrade: string
}) {
  return searchItemsResultsToView.filter(({ grade }) => {
    return grade.includes(selectedGrade)
  })
}

function reducer(state: State, action: Action): State {
  const newState = produce(state, (draft) => {
    switch (action.type) {
      case 'FILTER BY MATERIAL NAME': {
        const { payload } = action
        draft.selectedMaterial = payload.material
        let searchItemsResultsToView = draft.searchItemsResultsWithNoFilters
        let { selectedMaterial, selectedGrade } = draft
        if (selectedGrade) {
          searchItemsResultsToView = filterByGrade({
            searchItemsResultsToView,
            selectedGrade,
          })
        }
        searchItemsResultsToView = filterByMaterial({
          searchItemsResultsToView,
          selectedMaterial,
        })
        draft.searchItemsResultsToView = searchItemsResultsToView
        break
      }

      case 'RESET MATERIAL NAME FILTER': {
        draft.selectedMaterial = null
        let searchItemsResultsToView = draft.searchItemsResultsWithNoFilters
        const { selectedGrade } = draft
        if (selectedGrade) {
          searchItemsResultsToView = filterByGrade({
            searchItemsResultsToView,
            selectedGrade,
          })
        }
        draft.searchItemsResultsToView = searchItemsResultsToView
        break
      }

      case 'FILTER BY GRADE': {
        const { payload } = action
        draft.selectedGrade = payload.grade
        let searchItemsResultsToView = draft.searchItemsResultsWithNoFilters
        const { selectedGrade, selectedMaterial } = draft
        if (selectedMaterial) {
          searchItemsResultsToView = filterByMaterial({
            searchItemsResultsToView,
            selectedMaterial,
          })
        }
        searchItemsResultsToView = filterByGrade({
          searchItemsResultsToView,
          selectedGrade,
        })
        draft.searchItemsResultsToView = searchItemsResultsToView
        break
      }

      case 'RESET GRADE FILTER': {
        draft.selectedGrade = null
        let searchItemsResultsToView = draft.searchItemsResultsWithNoFilters
        const { selectedMaterial } = draft
        if (selectedMaterial) {
          searchItemsResultsToView = filterByMaterial({
            searchItemsResultsToView,
            selectedMaterial,
          })
        }
        draft.searchItemsResultsToView = searchItemsResultsToView
        break
      }

      case 'UPDATE SEARCH ITEMS RESULTS FROM API RESPONSE': {
        draft.searchItemsResultsWithNoFilters = sortBy(
          action.payload.searchItemsResults,
          ['molecule.name']
        )
        let searchItemsResultsToView = draft.searchItemsResultsWithNoFilters
        const { selectedGrade, selectedMaterial } = draft
        if (selectedMaterial) {
          searchItemsResultsToView = filterByMaterial({
            searchItemsResultsToView,
            selectedMaterial,
          })
        }
        if (selectedGrade) {
          searchItemsResultsToView = filterByGrade({
            searchItemsResultsToView,
            selectedGrade,
          })
        }
        draft.searchItemsResultsToView = searchItemsResultsToView
        break
      }
    }
  })

  return newState
}

const initialState: State = {
  selectedMaterial: null,
  selectedGrade: null,
  searchItemsResultsToView: [],
  searchItemsResultsWithNoFilters: [],
}

export const [
  useSearchItemsResultsStore,
  searchItemsResultsStoreAPI,
] = createReduxStyleStore({
  initialState,
  reducer,
  storeName: 'Search Results Page Store',
})
