import { Select, Skeleton } from 'antd'
import { getAllSuppliers } from 'api/search'
import React, { useState, useEffect } from 'react'
import { useQuery } from 'react-query'
import { useSearchItemDescription } from 'pages/Review/Review.hooks'
import { SelectSupplierLabel } from '../CustomerProfileForClientChart/constant'

export default function SupplierSelectionDropdown({
  supplier = '',
  country,
  onChange = (c: any) => {},
}: any) {
  const { searchItemId, searchId } = useSearchItemDescription()

  const { data, isLoading }: any = useQuery({
    queryKey: ['get-all-suppliers', { searchItemId, searchId, country }],
    queryFn: () => getAllSuppliers(searchId, searchItemId, country),
    config: {
      suspense: true,
      staleTime: Infinity,
    },
  })

  const allSuppliesrs = data.map((val: any) => {
    return { value: val.id, key: val.id, label: val.companyName }
  })
  // const [supplier, setSupplier] = useState<any>(allSuppliesrs[0] || initalSupplier)

  console.log('all suppliers ', data)

  const handleChange = (e: any) => {
    console.log('Supplier Chagned ', e)
    setTimeout(() => {
      // to avoid the freezing of dropdown set state after dropdown close animation finishes
      // setSupplier(e)
      onChange(e)
    }, 300)
  }

  useEffect(() => {
    if (supplier.value === SelectSupplierLabel && allSuppliesrs.length > 0) {
      onChange(allSuppliesrs[0])
    }
  }, [data])

  return isLoading ? (
    <Skeleton.Input />
  ) : (
    <Select
      labelInValue
      style={{ width: 180 }}
      value={supplier}
      onChange={handleChange}
      options={allSuppliesrs}
    ></Select>
  )
}
