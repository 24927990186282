import create, { UseStore, StoreApi } from 'zustand'
import { devtools, redux } from 'zustand/middleware'

type Dispatch<T> = (action: T) => void

export function createReduxStyleStore<
  TReducer extends (state: TState, action: any) => TState,
  TState
>({
  reducer,
  initialState,
  storeName,
  options,
}: {
  reducer: TReducer
  initialState: TState
  storeName: string
  options?: {
    persist: boolean
  }
}) {
  type TAction = Parameters<TReducer>[1]

  // zustand merges the state with a dispatch function
  type TReduxStyleState = TState & {
    dispatch: Dispatch<TAction>
  }

  let storeInitialState: TState = initialState

  if (options?.persist) {
    const persistedStateSerialized = localStorage.getItem(storeName)
    if (persistedStateSerialized !== null) {
      const persistedState = JSON.parse(persistedStateSerialized) as TState
      storeInitialState = { ...initialState, ...persistedState }
    }
  }

  const [useStore, api] = create(
    devtools(redux(reducer, storeInitialState), storeName)
  ) as [
    UseStore<TReduxStyleState>,
    StoreApi<TReduxStyleState> & { dispatch: Dispatch<TAction> }
  ]

  if (options?.persist) {
    // Attach a listener for state change by calling .subscribe(callBack) where callBack does whatever
    // work you wanna do when state changes.
    api.subscribe<TReduxStyleState>(function persistStoreStateToLocalStorage(
      reduxStyleState
    ) {
      if (reduxStyleState === null) {
        return
      }
      const { dispatch, ...state } = reduxStyleState
      try {
        localStorage.setItem(storeName, JSON.stringify(state))
      } catch (error) {
        console.error(
          `error in persisting ${storeName} state to localStorage`,
          error
        )
      }
    })
  }

  return [useStore, api] as const
}
