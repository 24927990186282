import { WEIGHT_LABELS } from '../../../../constants'
import { MCK_CAHRT_COLORS } from 'constants/ChartColors'
import { useSearchItemDescription } from 'pages/Review/Review.hooks'
import React from 'react'
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer,
} from 'recharts'
import ChartLegends from '../shared/ChartLegends'

export default function CustomerPricePointLineChart({
  chartRawData,
  customer,
}: any) {
  const { currencyCode, unit } = useSearchItemDescription()
  const firstObj = chartRawData[0]
  const monthNames = firstObj.pricePoints.map((val: any) => val.month)

  const chartData: any[] = []
  const clientNames = new Set()
  for (let month of monthNames) {
    const tempObj: any = {
      month,
    }
    for (const dataObj of chartRawData) {
      for (let pricePoint of dataObj.pricePoints) {
        if (pricePoint.month === month) {
          const customerName: string = dataObj.customer
          tempObj[customerName] = pricePoint.price || 0
          clientNames.add(customerName)
        }
      }
    }
    chartData.push(tempObj)
  }

  // const suppliersNames = Object.keys(data[0]).filter((key) => key !== 'month')
  // console.log('suppliers ', suppliersNames)

  const suppliers: { key: string; fill: string; color: string }[] = []
  const clientNamesArr: any[] = [...clientNames]
  for (let i = 0; i < clientNamesArr.length; i++) {
    const totalLen = MCK_CAHRT_COLORS.length - 1
    const index = i % totalLen
    const colorObj = MCK_CAHRT_COLORS[index]

    suppliers.push({
      key: clientNamesArr[i],
      fill: colorObj.fill,
      color: colorObj.color,
    })
  }

  return (
    <>
      <ResponsiveContainer width="100%" height={400}>
        <LineChart
          data={chartData}
          margin={{
            top: 50,
            right: 30,
            left: 35,
            bottom: 0,
          }}
        >
          <XAxis type="category" dataKey="month" />
          <YAxis
            hide={false}
            type="number"
            name="price"
            domain={['dataMin', 'dataMax']}
            padding={{ top: 40, bottom: 20 }}
            label={{
              stroke: 2,
              value: `${currencyCode}/${WEIGHT_LABELS[unit]}`,
              // angle: -90,
              position: 'insideTopLeft',
              offset: -5,
            }}
            axisLine={true}
            tickLine={true}
            tick={true}
          ></YAxis>

          <Tooltip />
          {/* <Legend /> */}
          {suppliers.map((val) => (
            <Line
              key={val.key}
              type="monotone"
              dataKey={val.key}
              name={val.key}
              stroke={val.fill}
              connectNulls={true}
              strokeWidth={val.key === customer ? 3 : 2}
            />
          ))}
        </LineChart>
      </ResponsiveContainer>
      <ChartLegends legendsData={suppliers}></ChartLegends>
    </>
  )
}
