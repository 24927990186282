import React, { useState, useEffect } from 'react'
import {
  useSearchFormStore,
  searchItemPropertySelector,
  searchFormStoreAPI,
} from 'pages/Search/Search.state'
import { Empty, Select } from 'antd'
import { getHSCodes, getMaterialHSCodes } from 'api/molecules'
import { useDebounceFn } from 'ahooks'
import eventBus, { EventKeys } from '../../../../utils/EventBus'

const { dispatch } = searchFormStoreAPI
const { Option } = Select
const HSCodeInputField = function HSCodeInputField({
  value,
  onChange,
  rowKey,
}: {
  value?: string
  onChange?: (newValue: typeof value) => void
  rowKey: string
  emitMolecule?: (obj: any) => void
}) {
  const [searchValue, setSearchValue] = useState('')
  const [hsOptions, setHsOptions] = useState<any>([])
  const hsCode = useSearchFormStore((state) => {
    return searchItemPropertySelector(state, { rowKey, propName: 'hsCode' })
  })
  const moleculeId = useSearchFormStore((state) => {
    return searchItemPropertySelector(state, { rowKey, propName: 'moleculeId' })
  })
  const [isFetching, setIsFetching] = useState<boolean>(false)
  const [rawData, setRawData] = useState<any>([])

  useEffect(
    function resetWhenMoleculeIdChanges() {
      console.log('[USE EFFECT] ', hsCode)
      // if (hsCodeCurrentlyUsed !== hsCode) {
      //   setHcCodeToUseCurrently(hsCode)
      //   onChange?.(undefined)
      // }
    },
    [
      hsCode,
      // hsCodeCurrentlyUsed,
      onChange,
    ]
  )

  const { run: handleOnSearch } = useDebounceFn(
    (searchValue: string) => {
      setSearchValue(searchValue)
    },
    { wait: 500 }
  )

  function handleOnChange(
    selectedValue: string | undefined,
    optionData: { value: number; key: string } | undefined
  ) {
    onChange?.(selectedValue)
    if (optionData === undefined) {
      return
    }
    dispatch({
      type: 'UPDATE_HS_CODE',
      payload: {
        rowKey,
        id: optionData.value,
      },
    })
    // const tempHsCodeObj = rawData?.find(
    //   (val: any) => String(val.id) === String(optionData.key)
    // )
    eventBus.dispatch(EventKeys.OnHsCodeChange, {
      rowKey,
      hsCode: hsCode,
      // molecule: tempHsCodeObj?.molecule,
    })
    // if (tempHsCodeObj?.molecule) {
    // }
  }

  function getHSCodesFromServer(serchForMolecule: boolean = false) {
    if (serchForMolecule && moleculeId) {
      setIsFetching(true)
      setHsOptions([])
      getMaterialHSCodes({ moleculeId }).then(
        (res: any) => {
          // rawData = res;
          const options = res?.map(({ value, id }: any) => ({
            value: value,
            id,
          }))
          setRawData([...options])
          setHsOptions(options)
          setIsFetching(false)
        },
        (err) => {
          console.log('ERR ', err)
          setIsFetching(false)
        }
      )
    } else if (searchValue && !moleculeId) {
      setIsFetching(true)
      setHsOptions([])
      getHSCodes({ value: searchValue }).then(
        (res) => {
          setRawData([...res])
          const options = res?.map(({ hsCode, id }: any) => ({
            value: hsCode,
            id,
          }))

          // const temp = [...new Map(options.map(item => [item.value, item])).values()]

          setHsOptions(options)
          setIsFetching(false)
        },
        (err) => {
          setIsFetching(false)
        }
      )
    }
  }
  useEffect(() => {
    if (moleculeId && rawData.length > 0) {
      const options = rawData?.filter((val: { value: any; id: any }) =>
        String(val.value).includes(searchValue.trim())
      )
      setHsOptions(options)
    } else {
      getHSCodesFromServer(false)
    }
  }, [searchValue])

  let notFoundContent = null
  if (hsOptions?.length === 0) {
    notFoundContent = (
      <Empty
        description="Did not find any HS codes for this material"
        image={Empty.PRESENTED_IMAGE_SIMPLE}
      />
    )
  }

  useEffect(() => {
    if (moleculeId) {
      getHSCodesFromServer(true)
    }
  }, [moleculeId])

  return (
    <Select
      loading={isFetching}
      showSearch
      notFoundContent={notFoundContent}
      value={value}
      filterOption={false}
      // @ts-ignore
      onChange={handleOnChange}
      onSearch={handleOnSearch}
      // options={hsOptions}
      placeholder="Enter HS Code"
    >
      {hsOptions.map((val: any) => (
        <Option value={val.value} key={val.id} id={val.id}>
          {val.value}
        </Option>
      ))}
    </Select>
  )
}

export default HSCodeInputField
