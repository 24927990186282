import uniqBy from 'lodash/uniqBy'
import groupBy from 'lodash/groupBy'
import sortBy from 'lodash/sortBy'
import { MaterialTxnsForAllVendorsAPIData } from 'api/molecules'
import { parseISO, format, parse, isAfter } from 'date-fns'
import { DATE_FORMAT } from '../../../../constants'
import {
  AGGREGATION_KIND,
  VendorOption,
  AGGREGATE_OPTIONS,
  AGGREGATION_KINDS,
  DEFAULT_AGGREGATION_OPTION,
} from './PriceTrendLineChart'

export type VendorTransaction = MaterialTxnsForAllVendorsAPIData[number]

export function sortTxnsByPrice({ txns }: { txns: VendorTransaction[] }) {
  const txnsSortedByPrice = [...txns].sort((txnA, txnB) => {
    return txnA.unitPrice - txnB.unitPrice
  })

  return txnsSortedByPrice
}

const MAX_NO_OF_DATA_POINTS_TO_SHOW_IN_VIEW = 10

export function calulateWidthOfChart({
  noOfDataPoints,
}: {
  noOfDataPoints: number
}) {
  const widthToUse = Math.max(
    Math.floor((100 / MAX_NO_OF_DATA_POINTS_TO_SHOW_IN_VIEW) * noOfDataPoints),
    100
  )

  return widthToUse
}

export function getUniqueVendorOptionsSortedAlphabetically({
  txns,
}: {
  txns: MaterialTxnsForAllVendorsAPIData
}) {
  const uniqueSortedVendorOptions: Array<VendorOption> = sortBy(
    // TODO: remoce uniqBy supplierNamee when  backend fixes this.
    uniqBy(uniqBy(txns, 'supplierId'), 'supplierName').map(
      ({ supplierName }) => ({
        value: supplierName,
      })
    ),
    ['value']
  )

  const vendorOptionsIncludingAggregateOptions: Array<VendorOption> = [
    ...AGGREGATE_OPTIONS,
    ...uniqueSortedVendorOptions,
  ]

  return vendorOptionsIncludingAggregateOptions
}

type TxnsByVendorName = {
  [vendorName: string]: VendorTransaction[]
}

export function getSelectedVendorsTxns({
  txns,
  selectedVendors,
  txnsByAggregateKinds,
}: {
  txns: VendorTransaction[]
  selectedVendors: string[]
  txnsByAggregateKinds: TxnsByVendorName
}) {
  const selectedVendorsTxns: TxnsByVendorName = {}

  for (const vendorName of selectedVendors) {
    if (AGGREGATION_KINDS.includes(vendorName as AGGREGATION_KIND)) {
      selectedVendorsTxns[vendorName] = txnsByAggregateKinds[vendorName]
    } else {
      selectedVendorsTxns[vendorName] = txns.filter(
        ({ supplierName }) => supplierName === vendorName
      )
    }
  }

  return selectedVendorsTxns
}

export function getAggregateTxns({ txns }: { txns: VendorTransaction[] }) {
  const aggregateTxns: TxnsByVendorName = {
    [AGGREGATION_KIND.all]: txns,
    [AGGREGATION_KIND.top20]: [],
    [AGGREGATION_KIND.middle60]: [],
    [AGGREGATION_KIND.bottom20]: [],
  }

  const txnsGroupedByMonth = groupBy(txns, ({ beDate }) =>
    format(parseISO(beDate), DATE_FORMAT.yyyyMM)
  )

  for (const month in txnsGroupedByMonth) {
    const txnsOfThisMonth = txnsGroupedByMonth[month]
    const txnsOfThisMonthSortedByPrice = sortTxnsByPrice({
      txns: txnsOfThisMonth,
    })
    const noOfTxnsOfThisMonth = txnsOfThisMonthSortedByPrice.length
    const noOfTxnsIn20percent = Math.round(noOfTxnsOfThisMonth * 0.2)
    const noOfTxnsIn60Percent = Math.round(noOfTxnsOfThisMonth * 0.6)
    // SPECIAL CASE
    if (noOfTxnsOfThisMonth < 3) {
      if (noOfTxnsOfThisMonth === 1) {
        aggregateTxns[AGGREGATION_KIND.top20].push(
          txnsOfThisMonthSortedByPrice[0]
        )
      } else {
        aggregateTxns[AGGREGATION_KIND.top20].push(
          txnsOfThisMonthSortedByPrice[0]
        )
        aggregateTxns[AGGREGATION_KIND.bottom20].push(
          txnsOfThisMonthSortedByPrice[1]
        )
      }
    } else {
      for (const aggregationKind of AGGREGATION_KINDS) {
        switch (aggregationKind) {
          case AGGREGATION_KIND.top20:
            aggregateTxns[aggregationKind].push(
              ...txnsOfThisMonthSortedByPrice.slice(0, noOfTxnsIn20percent)
            )
            break

          case AGGREGATION_KIND.middle60:
            aggregateTxns[aggregationKind].push(
              ...txnsOfThisMonthSortedByPrice.slice(
                noOfTxnsIn20percent,
                noOfTxnsIn20percent + noOfTxnsIn60Percent
              )
            )
            break

          case AGGREGATION_KIND.bottom20:
            aggregateTxns[aggregationKind].push(
              ...txnsOfThisMonthSortedByPrice.slice(
                noOfTxnsOfThisMonth - noOfTxnsIn20percent
              )
            )
            break
        }
      }
    }
  }
  return aggregateTxns
}

type VendorTxnsGroupedByDate = {
  [vendorName: string]: { [date: string]: VendorTransaction[] }
}

export function groupTxnsByData({
  selectedVendorsTxns,
}: {
  selectedVendorsTxns: TxnsByVendorName
}) {
  const vendorTxnsGroupedByDate: VendorTxnsGroupedByDate = {}

  for (const selectedVendor in selectedVendorsTxns) {
    const groupedByDate = groupBy(
      selectedVendorsTxns[selectedVendor],
      ({ beDate }) => format(parseISO(beDate), DATE_FORMAT.yyyyMM)
    )
    vendorTxnsGroupedByDate[selectedVendor] = groupedByDate
  }

  return vendorTxnsGroupedByDate
}

type VendorTxnsWeightedAverageGroupedByDate = {
  [vendorName: string]: { [date: string]: number }
}

export function calculateWeightedAverage({
  vendorTxnsGroupedByDate,
}: {
  vendorTxnsGroupedByDate: VendorTxnsGroupedByDate
}) {
  const vendorTxnsWeightedAverageGroupedByDate: VendorTxnsWeightedAverageGroupedByDate = {}

  for (const vendorName in vendorTxnsGroupedByDate) {
    for (const date in vendorTxnsGroupedByDate[vendorName]) {
      let weightedPriceSum = 0
      let quantitySum = 0
      for (const { quantity, unitPrice } of vendorTxnsGroupedByDate[vendorName][
        date
      ]) {
        weightedPriceSum += unitPrice * quantity
        quantitySum += quantity
      }
      const weightedPrice = weightedPriceSum / quantitySum
      vendorTxnsWeightedAverageGroupedByDate[vendorName] = {
        ...vendorTxnsWeightedAverageGroupedByDate[vendorName],
        [date]: weightedPrice,
      }
    }
  }

  return vendorTxnsWeightedAverageGroupedByDate
}

export type LineChartData = Array<{
  date: string
  vendorWeightedPrices: {
    [vendorName: string]: number
  }
}>

export type LineChartDatum = LineChartData[number]

export function massageToChartDataFormat({
  vendorTxnsWeightedAverageGroupedByDate,
}: {
  vendorTxnsWeightedAverageGroupedByDate: VendorTxnsWeightedAverageGroupedByDate
}) {
  const byDateVendorWeightedPrices: {
    [date: string]: { [vendorName: string]: number }
  } = {}

  for (const vendorName in vendorTxnsWeightedAverageGroupedByDate) {
    for (const date in vendorTxnsWeightedAverageGroupedByDate[vendorName]) {
      byDateVendorWeightedPrices[date] = {
        ...byDateVendorWeightedPrices[date],
        [vendorName]: vendorTxnsWeightedAverageGroupedByDate[vendorName][date],
      }
    }
  }

  const lineChartData: LineChartData = Object.entries(
    byDateVendorWeightedPrices
  ).map(([date, vendorWeightedPrices]) => {
    const lineChartDatum: LineChartData[number] = {
      date,
      vendorWeightedPrices,
    }
    return lineChartDatum
  })

  return lineChartData
}

export function sortlineChartDataByDate({
  lineChartData,
}: {
  lineChartData: LineChartData
}) {
  const lineChartDataSortedByDate = [...lineChartData].sort(
    (datumA, datumB) => {
      const dateA = parse(datumA.date, DATE_FORMAT.yyyyMM, new Date())
      const dateB = parse(datumB.date, DATE_FORMAT.yyyyMM, new Date())
      if (isAfter(dateA, dateB)) {
        return 1
      }
      return -1
    }
  )

  return lineChartDataSortedByDate
}

export function getChartData({
  txns,
  selectedVendors,
  txnsByAggregateKinds,
}: {
  txnsByAggregateKinds: TxnsByVendorName
  txns: VendorTransaction[]
  selectedVendors: string[]
}) {
  const selectedVendorsTxns = getSelectedVendorsTxns({
    txns,
    selectedVendors,
    txnsByAggregateKinds,
  })
  const vendorTxnsGroupedByDate = groupTxnsByData({ selectedVendorsTxns })

  const vendorTxnsWeightedAverageGroupedByDate = calculateWeightedAverage({
    vendorTxnsGroupedByDate,
  })
  const lineChartData = massageToChartDataFormat({
    vendorTxnsWeightedAverageGroupedByDate,
  })
  const lineChartDataSortedByDate = sortlineChartDataByDate({ lineChartData })
  return lineChartDataSortedByDate
}
