import { Select } from 'antd'
import { getImporterCountryList } from 'api/search'
import React, { useState, useEffect } from 'react'
import { useQuery } from 'react-query'
import { useSearchItemDescription } from 'pages/Review/Review.hooks'
import CountriesMapping from './CountriesAlpha3'
const { Option } = Select

export default function CountriesExportingToIndia({
  onChange = (c: any) => {},
}) {
  const { searchItemId, searchId } = useSearchItemDescription()
  const { data, isLoading }: any = useQuery({
    queryKey: ['importing-countries-table', { searchItemId }],
    queryFn: () => getImporterCountryList(searchId, searchItemId),
    config: {
      suspense: true,
      staleTime: Infinity,
    },
  })

  const [country, setCountry] = useState<any>(
    data && data.length > 0 ? data[0] : undefined
  )

  const handleCountryChange = (e: any) => {
    console.log('Country Chagned ', e)
    setTimeout(() => {
      // to avoid the freezing of dropdown set state after dropdown close animation finishes
      setCountry(e)
    }, 300)
  }

  useEffect(() => {
    if (onChange) onChange(country)
  }, [country])

  const getCountryName = (key: string) => {
    return CountriesMapping.hasOwnProperty(key) ? CountriesMapping[key] : key
  }

  return (
    <Select
      value={country}
      style={{ width: 180 }}
      onChange={handleCountryChange}
    >
      {data.map((val: string) => (
        <Option key={val} value={val}>
          {getCountryName(val)}
        </Option>
      ))}
    </Select>
  )
}
