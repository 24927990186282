import { FilterDropdownProps } from 'antd/lib/table/interface'
import { Space, Select, Button } from 'antd'
import { SearchOutlined } from '@ant-design/icons'
import React from 'react'

export function CustomColumnFilterDropdown({
  visible,
  setSelectedKeys,
  clearFilters,
  confirm,
  filters,
  selectClassName = '',
  dropdownClassName = '',
}: FilterDropdownProps | any) {
  return (
    <Space style={{ padding: 8 }} size="large">
      <Select
        className={selectClassName}
        dropdownClassName={dropdownClassName}
        open={visible}
        mode="multiple"
        size="large"
        style={{ width: 500 }}
        placeholder="Please select"
        onChange={setSelectedKeys}
        // @ts-ignore
        options={filters}
        allowClear
        filterOption={(input, option) => {
          return option?.value.toLowerCase().indexOf(input.toLowerCase()) === 0
        }}
      />
      <Space>
        <Button type="primary" onClick={confirm} icon={<SearchOutlined />}>
          Search
        </Button>
        <Button onClick={clearFilters}>Reset</Button>
      </Space>
    </Space>
  )
}
