import React, { useRef, useState, useEffect } from 'react'
import { Button, Tag, Typography, Spin } from 'antd'
import { DownloadOutlined } from '@ant-design/icons'
import {
  ComposedChart,
  Scatter,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer,
  Legend,
} from 'recharts'
import { saveAs } from 'file-saver'
import { toPng } from 'html-to-image'

import { MCK_CAHRT_COLORS } from '../../../../constants/ChartColors'
import {
  getPremiumToL1,
  getRankFromDotGraph,
  getPremiumToAverage,
  roundOff,
} from 'utils/helperFunctions'
import { useSearchItemDescription } from 'pages/Review/Review.hooks'
import { WEIGHT_LABELS } from '../../../../constants'
import { SelectClientLable } from './constant'
import './style.css'

export default function Chart({
  country,
  chartData,
  selectedClient,
  allSuppliers = [],
}: any) {
  const tempSuppliers: { key: any; color: string }[] = []
  const { unit, currencyCode } = useSearchItemDescription()
  const ref = useRef<any>(null)

  const tooltip: any = useRef<any>(null)
  const tooltipContent: any = useRef<any>(null)
  const [showLoader, setShowLoader] = useState<boolean>(false)

  useEffect(() => {
    // just to re render the UI to make the element align properly
    setShowLoader(true)
    // alert("hi")
    setTimeout(() => {
      setShowLoader(false)
    }, 1000)
  }, [])

  for (let supplier of allSuppliers) {
    const colorObj = MCK_CAHRT_COLORS[0]
    tempSuppliers.push({
      key: supplier,
      color: selectedClient === supplier ? '#FFA500' : colorObj.fill,
    })
  }

  const pointsData =
    selectedClient && selectedClient !== SelectClientLable
      ? chartData.map((val: any) => {
          const { rank, rankOutOf } = getRankFromDotGraph(
            chartData,
            selectedClient,
            val.month,
            allSuppliers
          )

          return {
            month: val.month,
            premiumToL1: getPremiumToL1(
              chartData,
              selectedClient,
              val.month,
              allSuppliers
            ),
            premiumToAvg: getPremiumToAverage(
              chartData,
              selectedClient,
              val.month,
              allSuppliers
            ),
            rank,
            rankOutOf,
          }
        })
      : []

  const handleDownloadTwo = () => {
    const filter = (node: any) => {
      if (node.tagName) {
        const exclusionClasses = ['dont-download']
        return !exclusionClasses.some((classname) =>
          node.classList.contains(classname)
        )
      }
      return true
    }

    toPng(ref.current, { filter })
      .then((dataUrl) => {
        // console.log(dataUrl)
        // saveAs(dataUrl, `${Date.now()}.png`)

        try {
          let fileName = `importer-price-${
            selectedClient !== SelectClientLable
              ? selectedClient.split(' ').join('-')
              : ''
          }-${country.split(' ').join('-')}.png`
          saveAs(dataUrl, fileName)
        } catch (e) {
          console.error('Exception ', e)
          saveAs(dataUrl, `${Date.now()}.png`)
        }
      })
      .catch(function (error) {
        console.error('oops, something went wrong!', error)
      })
  }

  const onMouseOver = (e: any) => {
    const [monthData, priceData] = e.tooltipPayload

    const tempArr: { name: string; price: number }[] = []
    for (let obj of chartData) {
      if (obj[monthData.name] === monthData.value) {
        // matching object found
        const allClientNames = Object.keys(obj).filter(
          (key) => !['monthlyAveragePrice', 'month'].includes(key)
        )
        for (let client of allClientNames) {
          if (obj[client] === priceData.value) {
            tempArr.push({ name: client, price: priceData.value })
          }
        }
      }
    }

    const htmlString =
      `<div class="tooltip-heading">${monthData.value}</div>` +
      tempArr
        .map(
          (val) =>
            `<div class="qty ${
              selectedClient === val.name ? 'orange' : ''
            }" key=${val.name}>
        <div class="name">${val.name}: </div>
        <div class="value">${roundOff(val.price)}</div>
      </div>`
        )
        .join(' ')

    tooltipContent.current.innerHTML = htmlString

    const { clientWidth, clientHeight } = tooltip.current
    let x = Math.round(e.tooltipPosition.x)
    let y = Math.round(e.tooltipPosition.y)

    tooltip.current.style.opacity = '1'
    tooltip.current.style.transform = `translate(${x}px, ${y}px)`
  }

  const onMouseLeave = (e: any) => {
    tooltip.current.style.opacity = '0'
  }

  const renderLegend = (props: any) => {
    const { payload } = props

    return (
      <div style={{ marginTop: '10px', paddingRight: '5px' }}>
        {payload
          .filter(
            (entry: any) =>
              entry.value === selectedClient &&
              entry.value !== SelectClientLable
          )
          .map((entry: any, index: any) => (
            <div
              style={{
                display: 'flex',
                justifyContent: 'end',
                alignItems: 'center',
              }}
            >
              <div
                key={`item-${index}`}
                style={{
                  width: '15px',
                  height: '15px',
                  backgroundColor: 'orange',
                  borderRadius: '50%',
                }}
              ></div>
              <span style={{ marginLeft: '5px', fontSize: '15px' }}>
                {entry.value}
              </span>
            </div>
          ))}
      </div>
    )
  }

  // to bring the orange dot in front of all dots
  const indexOfSelectedClient = tempSuppliers.findIndex((x) => {
    return x.key === selectedClient
  })
  if (indexOfSelectedClient > -1) {
    tempSuppliers.push(tempSuppliers.splice(indexOfSelectedClient, 1)[0])
  }

  return (
    <div style={{ width: '100%', backgroundColor: '#ffffff' }} ref={ref}>
      <div
        style={{
          marginBottom: '15px',
          display: 'flex',
          justifyContent: 'space-between',
        }}
      >
        {country ? (
          <Typography.Title level={5}>
            Price points for different importers(buyer) in {country}
          </Typography.Title>
        ) : null}
        <Button
          type="primary"
          onClick={handleDownloadTwo}
          size={'small'}
          className="dont-download"
        >
          <DownloadOutlined />
          Download
        </Button>
      </div>

      <div style={{ position: 'relative' }}>
        {showLoader ? (
          <div
            style={{
              position: 'absolute',
              backgroundColor: 'rgba(255,255,255,0.9)',
              width: '100%',
              height: '100%',
              zIndex: 100,
              display: 'flex',
            }}
          >
            <Spin style={{ margin: 'auto' }} size="large" />
          </div>
        ) : null}

        <ResponsiveContainer height={400}>
          <ComposedChart
            margin={{
              top: 0,
              // right: 0,
              // bottom: 20,
              left: 18,
            }}
            data={chartData}
          >
            <XAxis
              type="category"
              dataKey="month"
              name="month"
              padding={{ left: 50, right: 50 }}
            />

            <YAxis
              hide={false}
              type="number"
              name="price"
              domain={['dataMin', 'dataMax']}
              padding={{ top: 30, bottom: 20 }}
              label={{
                stroke: 2,
                value: `Price ${currencyCode}/${WEIGHT_LABELS[unit]}`,
                // angle: -90,
                position: 'insideTopLeft',
                offset: -18,
              }}
              axisLine={true}
              tickLine={true}
              tick={true}
            ></YAxis>

            <Tooltip cursor={false} wrapperStyle={{ display: 'none' }} />

            <Legend
              verticalAlign="top"
              align="right"
              height={36}
              content={renderLegend}
            />

            {tempSuppliers.map((val) => (
              <Scatter
                name={val.key}
                dataKey={val.key}
                fill={val.color}
                key={val.key}
                onMouseEnter={onMouseOver}
                onMouseLeave={onMouseLeave}
              />
            ))}
          </ComposedChart>
        </ResponsiveContainer>
      </div>

      <div className="ui-chart-tooltip" ref={tooltip}>
        <div className="ui-chart-tooltip-content" ref={tooltipContent}></div>
      </div>

      {selectedClient && selectedClient !== SelectClientLable ? (
        <table style={{ width: '100%', marginTop: '30px' }}>
          <tr>
            <th style={{ width: '160px' }}></th>
            {pointsData.map((val: any) => (
              <th key={`month-${val.month}`}>{val.month}</th>
            ))}
          </tr>

          <tr>
            <td>Premium to L1</td>
            {pointsData.map((val: any) => (
              <th key={`pl1-${val.month}`}>
                <Tag
                  color="#034B6F"
                  style={{ minWidth: '45px', textAlign: 'center' }}
                >
                  {[NaN, -Infinity, Infinity, undefined, null].includes(
                    val.premiumToL1
                  )
                    ? 'NA'
                    : `${val.premiumToL1} %`}
                </Tag>
              </th>
            ))}
          </tr>

          <tr>
            <td>Premium to avg</td>
            {pointsData.map((val: any) => (
              <th key={`pta-${val.month}`}>
                <Tag
                  color="#034B6F"
                  style={{ minWidth: '45px', textAlign: 'center' }}
                >
                  {[NaN, -Infinity, Infinity, undefined, null].includes(
                    val.premiumToAvg
                  )
                    ? 'NA'
                    : `${val.premiumToAvg} %`}
                </Tag>
              </th>
            ))}
          </tr>

          <tr>
            <td>Rank</td>
            {pointsData.map((val: any) => (
              <th key={`rank-${val.month}`}>
                <Tag
                  color="#034B6F"
                  style={{ minWidth: '45px', textAlign: 'center' }}
                >
                  {val.rank ? `${val.rank}/${val.rankOutOf}` : 'NA'}
                </Tag>
              </th>
            ))}
          </tr>
        </table>
      ) : null}
    </div>
  )
}
