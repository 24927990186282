import {
  getSearchDatabaseSummary,
  SearchResultsAPIData,
  SearchDatabaseSummaryAPIData,
  getSavedSearchResults,
} from 'api/search'
import { useQuery, useQueryCache } from 'react-query'
import { AxiosApiError } from 'api/utils'
import { useLocation } from 'react-router-dom'

export const SEARCH_HISTORY_QUERY_KEY = 'search-history'

export function getSavedSearchResultsQueryKey({
  searchId,
}: {
  searchId: number
  fromDate?: string
  toDate?: string
}) {
  return ['search-results', { searchId }] as const
}

export function useSavedSearchResultsQuery() {
  const { searchId } = useLocation<{ searchId: number }>().state
  const { data } = useQuery({
    queryKey: getSavedSearchResultsQueryKey({ searchId }),
    queryFn: () => getSavedSearchResults({ searchId }),
    config: {
      suspense: true,
      refetchOnWindowFocus: false,
    },
  })

  return data as SearchResultsAPIData
}

export function useSearchResultsDataFromQueryCache() {
  const { searchId } = useLocation<{ searchId: number }>().state
  const queryCache = useQueryCache()
  return queryCache.getQueryData<SearchResultsAPIData>(
    getSavedSearchResultsQueryKey({ searchId })
  )
}

export function getSearchItemQueryKey({
  searchId,
  searchItemId,
}: {
  searchId: number
  searchItemId: number
}) {
  return ['search-item-result', { searchId, searchItemId }] as const
}

export function useSearchDatabaseSummary() {
  const searchDatabaseQueryResult = useQuery<
    SearchDatabaseSummaryAPIData,
    AxiosApiError
  >({
    queryKey: 'search-database-summary',
    queryFn: getSearchDatabaseSummary,
    config: {
      staleTime: Infinity,
    },
  })

  return searchDatabaseQueryResult
}
