import React from 'react'
import { Layout, Row, Avatar, Typography, Popover, Space, Select } from 'antd'
import {
  UserOutlined,
  ArrowRightOutlined,
  LogoutOutlined,
} from '@ant-design/icons'
import './Header.less'
import { useHistory, useLocation, Link } from 'react-router-dom'
import { pagePaths, LOCALES } from '../../constants'
import { useAppWideStore } from 'App.state'
import logoImage from '../../assets/header/logo.png'
import { LinkButton } from 'components/Layout/Layout'
const { Option } = Select

function LocaleSelector() {
  const { locale, changeLocale } = useAppWideStore()

  return (
    <Select
      size={'small'}
      defaultValue={locale}
      onSelect={(value) => {
        changeLocale(value)
      }}
      dropdownMatchSelectWidth={false}
    >
      {Object.entries(LOCALES).map(([locale, description]) => {
        return (
          <Option key={locale} value={locale} label={locale}>
            <Typography.Text>
              {locale} {description}
            </Typography.Text>
          </Option>
        )
      })}
    </Select>
  )
}

export default function Header() {
  const history = useHistory()

  const { pathname } = useLocation()

  const { logout, isAdmin, info } = useAppWideStore()

  const menu = (
    <Space direction="vertical">
      <Typography.Text>{info?.idInfo.name}</Typography.Text>
      {isAdmin &&
        (pathname.startsWith('/admin') ? (
          <LinkButton
            icon={<ArrowRightOutlined />}
            onClick={() => history.push(pagePaths.home)}
          >
            Go to search
          </LinkButton>
        ) : (
          <LinkButton
            icon={<ArrowRightOutlined />}
            onClick={() => history.push(pagePaths.admin)}
          >
            Go to admin
          </LinkButton>
        ))}
      <LinkButton icon={<LogoutOutlined />} onClick={logout}>
        logout
      </LinkButton>
    </Space>
  )
  return (
    <Layout.Header className="site-layout-header">
      <Row justify="space-between" align="middle" style={{ height: '100%' }}>
        <Typography.Title level={4} style={{ marginBottom: 0 }}>
          <Link to={pagePaths.home} style={{ color: 'unset' }}>
            <img style={{ height: 40 }} src={logoImage} alt="logo" />| Sourcing
            Search Engine
          </Link>
        </Typography.Title>
        <Space align="center" size="large">
          <LocaleSelector />
          <Popover placement="bottomRight" content={menu}>
            <Avatar icon={<UserOutlined />} />
          </Popover>
        </Space>
      </Row>
    </Layout.Header>
  )
}
