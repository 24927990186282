import create from 'zustand'
import { ADMIN_GROUP, TRIAL_USER_GROUP } from './constants'
import { mid } from 'utils/midBuilder'

export type UserInfo = {
  accessInfo:
    | {
        aud: string
        cid: string // "0oatb3f0gyvNorbL80h7"
        exp: number // 1598390947
        iat: number // 1598389147
        iss: string // "https://043bb3f8-mckid-us-dev.oktapreview.com/oauth2/austb3iwx59U2bWMm0h7"
        jti: string // "AT.5KrxI3tM5Q27w3oyFMbRpoYtwkTQTC-mHX-kYC7LmjY"
        scp: Array<'email' | 'profile' | 'openid'> // ["email", "profile", "openid"]
        sub: string // "Sreekar_Nimbalkar@mckinsey.com"
        uid: string // "00uq43duhx15fh2po0h7"
        ver: number // 1
        groups: Array<string>
      }
    | undefined
    | null
  accessToken: string
  idInfo: {
    [key: string]: any
    email: string
    name: string
  }
  idToken: string
  midfetch: (x: any, y: any) => void
  tenant: {
    appId: string // "0oatb3f0gyvNorbL80h7"
    audience: string // "https://gpp-org-gpp-frontend.alpha.mdl.cloud"
    authDriver: 'okta'
    displayName: 'GPP'
    domain: string // "https://043bb3f8-mckid-us-dev.oktapreview.com/oauth2/austb3iwx59U2bWMm0h7"
    oidcConfigUrl: string // "https://043bb3f8-mckid-us-dev.oktapreview.com/oauth2/austb3iwx59U2bWMm0h7/.well-known/openid-configuration"
  }
}

export type AppWideState = {
  isAuthed: 'LOADING' | 'NOT_AUTHED' | 'AUTHED'
  info: UserInfo | null
  checkIfAuth: () => Promise<void>
  login: () => Promise<void>
  logout: () => Promise<void>
  isAdmin: boolean
  locale: string
  changeLocale: (locale: string) => void
  isTrialUser: boolean
}

export const [useAppWideStore, appWideStoreAPI] = create<AppWideState>(
  (set, get) => ({
    isAuthed: 'LOADING',
    isAdmin: false,
    isTrialUser: false,
    info: null,
    checkIfAuth: async () => {
      try {
        const isAuthed = await mid.isAuthed()
        set((prev) => ({
          ...prev,
          isAuthed: isAuthed ? 'AUTHED' : 'NOT_AUTHED',
        }))
      } catch (err) {
        set({ isAuthed: 'NOT_AUTHED', info: null })
      }
    },
    login: async () => {
      try {
        const info = ((await mid.login()) as unknown) as UserInfo
        const groups = info.accessInfo?.groups
        const isAdmin = Boolean(groups?.includes(ADMIN_GROUP))
        const isTrialUser = Boolean(groups?.includes(TRIAL_USER_GROUP))
        set((prev) => ({ ...prev, info: info, isAdmin, isTrialUser }))
        get().checkIfAuth()
      } catch (err) {
        set((prev) => ({ ...prev, isAuthed: 'NOT_AUTHED', info: null }))
      }
    },
    logout: async () => {
      try {
        await mid.logout()
        get().checkIfAuth()
      } catch (err) {
        set({ isAuthed: 'NOT_AUTHED', info: null })
      }
    },
    locale: Intl.NumberFormat().resolvedOptions().locale,
    changeLocale: (locale: string) => set((state) => ({ locale })),
  })
)
