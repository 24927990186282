import React, { useEffect, useState, useRef } from 'react'
import Header from 'components/Header/Header'
import {
  Layout,
  PageHeader,
  Form,
  Table,
  Space,
  Row,
  Typography,
  Button,
  Upload,
  message,
  Divider,
  Select,
} from 'antd'
import { DownloadOutlined } from '@ant-design/icons'
import './Search.less'
import {
  SearchItem,
  useSearchFormStore,
  searchFormStoreAPI,
  SearchItemValidationErrors,
  currencyCodeSelector,
  COLUMN_DATA_INDEX,
  useSearchPageDescription,
  checkIfEmptySelector,
  unitSelector,
  weightRangeSelector,
} from './Search.state'
import {
  convertPropsFromNullToUndefined,
  convertPropsFromUndefinedToNull,
  handleDownloadOnClick,
  TableDefaultRowProps,
  uploadProgressConfig,
  getAuthHeader,
  getBackendServiceURL,
} from 'utils'
import {
  DeleteSearchItem,
  AddNewSearchItem,
  NewSearchButtonWrapper,
  SavedSearchResultsButtonWrapper,
} from './components/buttons'
import { ColumnsType } from 'antd/lib/table'
import { useHistory } from 'react-router-dom'
import { useForceReRender, useSearchId } from 'utils/hooks'
import {
  XlsxUploadSearchItemsAPIResponseBody,
  downloadBulkUploadTemplate,
} from 'api/search'
import { ErrorBoundryAndSuspenseFallback } from 'components/Fallback/Fallback'
import { UploadChangeParam } from 'antd/lib/upload'
import { UploadFile } from 'antd/lib/upload/interface'
import { SearchDatabaseSummary } from 'components/SearchDatabaseSummary/SearchDatabaseSummary'
import {
  CURRENCY_CODES_LIST,
  CURRENCY_SYMBOLS,
  WeightCode,
} from '../../constants'
import {
  NameFormItem,
  HSCodeFormItem,
  TypeFormItem,
  GradeFormItem,
  CurrentPriceFormItem,
  TotalAnnualVolumeFormItem,
} from './components/FormItems/FormItems'
import { LargeVerticalSpacer, HideForTrialUser } from 'components/Layout/Layout'
import ToggleWeightUnit from './ToggleWeightUnit'
import { useSavedSearchResultsQuery } from 'utils/reactQuery'

import eventBus, { EventKeys } from '../../utils/EventBus'
import { WEIGHT_LABELS } from '../../constants'
import WeightRangeInput from './WeightRangeInput'
const { dispatch } = searchFormStoreAPI

function CurrentPricingColumnTitle() {
  console.log('[CurrentPricingColumnTitle] calculating title again')
  const currencyCode = useSearchFormStore(currencyCodeSelector)
  const unit = useSearchFormStore(unitSelector)
  return (
    <>
      Current Pricing ({CURRENCY_SYMBOLS[currencyCode]} / {WEIGHT_LABELS[unit]})
    </>
  )
}

function AnnualizedVolumeColumnTitle() {
  const unit = useSearchFormStore(unitSelector)
  return <>Total Annualized Volume ({WEIGHT_LABELS[unit]})</>
}

const columns: ColumnsType<SearchItem> = [
  {
    title: 'Name Of Material',
    dataIndex: COLUMN_DATA_INDEX.name,
    width: '16%',
    render: NameFormItem,
  },
  {
    title: 'HS Code',
    dataIndex: COLUMN_DATA_INDEX.hsCode,
    width: '16%',
    render: (idx, val) => {
      return HSCodeFormItem(idx, val)
    },
  },
  {
    title: 'Type Of Material',
    dataIndex: COLUMN_DATA_INDEX.type,
    width: '16%',
    render: TypeFormItem,
  },
  {
    title: 'Grade',
    dataIndex: COLUMN_DATA_INDEX.grade,
    width: '16%',
    render: GradeFormItem,
  },
  {
    title: <CurrentPricingColumnTitle />,
    dataIndex: COLUMN_DATA_INDEX.currentPricingPerUnit,
    width: '16%',
    render: CurrentPriceFormItem,
  },
  {
    title: <AnnualizedVolumeColumnTitle />,
    dataIndex: COLUMN_DATA_INDEX.totalAnnualizedVolume,
    width: '16%',
    render: TotalAnnualVolumeFormItem,
  },
  {
    title: undefined,
    dataIndex: 'operation',
    render: (_, searchItem, rowIndex) => {
      const {
        searchItems: { length },
      } = searchFormStoreAPI.getState()
      if (length === 1) {
        return
      }
      return <DeleteSearchItem rowKey={searchItem.rowKey} />
    },
  },
]

type SearchItemWithUndefinedDefaults = {
  [propName in keyof SearchItem]: null extends SearchItem[propName]
    ? Exclude<SearchItem[propName], null> | undefined
    : SearchItem[propName]
}

interface SearchFormRowSpecialProps {
  rowIndex: number
  searchItem: SearchItem
  onClick: () => void
}

type SearchFormRowCompProps = TableDefaultRowProps & SearchFormRowSpecialProps

function SearchFormRow({
  rowIndex,
  searchItem,
  ...props
}: SearchFormRowCompProps) {
  const [form] = Form.useForm()
  const [renderKey, setrenderKey] = useState(Date.now())
  useEffect(function validateFormFields() {
    form.validateFields()
  })

  const { rowKey, moleculeId, hsCode } = searchItem
  const [oldMoleculeId, setOldMoleculeId] = useState(moleculeId)

  function handleFormFieldsChange() {
    const updatedSearchItemValidationErrors: SearchItemValidationErrors = {
      rowKey,
      fieldErrors: form.getFieldsError(),
    }

    const formValues = form.getFieldsValue() as Omit<
      SearchItemWithUndefinedDefaults,
      'rowKey'
    >
    const updatedSearchItem: SearchItem = {
      ...convertPropsFromUndefinedToNull(formValues),
      rowKey,
    }

    dispatch({
      type: 'UPDATE_SEARCH_ITEM',
      payload: {
        updatedSearchItem,
        updatedSearchItemValidationErrors,
      },
    })
  }

  let formInitialValues: SearchItemWithUndefinedDefaults = convertPropsFromNullToUndefined(
    searchItem
  )

  useEffect(() => {
    eventBus.on(EventKeys.OnHsCodeChange, (data: any) => {
      console.log('[OnHsCodeChange] ', data)
      // update material if HS Code value is changed
      if (data.rowKey === rowKey) {
        setrenderKey(Date.now())
      }
    })

    eventBus.on(EventKeys.OnMaterialChange, (data: any) => {
      console.log('[OnMaterialChange] ', data)
      if (data.rowKey === rowKey) {
        form.setFieldsValue({ hsCode: null })
        setrenderKey(Date.now())
      }
    })

    return () => {
      // remvoe event listener
      eventBus.remove(EventKeys.OnHsCodeChange, () => {})
      eventBus.remove(EventKeys.OnMaterialChange, () => {})
    }
  }, [])

  return (
    <Form
      form={form}
      key={`form:${rowKey}-${renderKey}`}
      name={`form:${rowKey}`} // this will appended to the form field id
      onFieldsChange={handleFormFieldsChange}
      component={false}
      initialValues={formInitialValues}
    >
      <tr {...props} />
    </Form>
  )
}

const TABLE_PAGE_SIZE = 5

/**
 * Page number `SHOULD NOT` start from `ZERO`, it starts from `ONE`, using `0` as page number `🔥🔥BLOWS UP THE Table component🔥🔥`
 */
function getLastPageNumber({
  dataSourceLength,
  pageSize,
}: {
  dataSourceLength: number
  pageSize: number
}) {
  if (dataSourceLength < pageSize) {
    return 1
  } else if (dataSourceLength % pageSize === 0) {
    return dataSourceLength / pageSize
  } else {
    return 1 + Math.floor(dataSourceLength / pageSize)
  }
}

function FormTable() {
  const { forceReRender: reRender } = useForceReRender()

  // using the hook `useSearchFormStore` will cause this component  to re-render on every key stroke in the form
  // hence using the non-rective .getState() method
  const { searchItems } = searchFormStoreAPI.getState()

  const dataSourceLength = searchItems.length

  const previousDataSourceLengthRef = useRef(dataSourceLength)

  const [currentPage, setCurrentPage] = useState(1)

  useEffect(function decideWhenToReRender() {
    return searchFormStoreAPI.subscribe(
      function listenerThatReRenders() {
        reRender()
      },
      function stateSelector({ searchItems, dataVersionFromFileUpload }) {
        return { searchItems, dataVersionFromFileUpload }
      },
      /**
       * Return `true` if you DO NOT want to re-render the component. `true` implies that previous and current props are
       * considered equal in terms of it's effect on rendering the component. Return `false` if you want to re-render,
       * the listener callback will trigger when false is returned.
       * @param previous
       * @param current
       */
      function reRenderingDecider(
        previous,
        current: {
          searchItems: SearchItem[]
          dataVersionFromFileUpload: number
        }
      ) {
        //    Idea is to only re-render this FormTable component when there is change in the number of rows, hence length is checked.
        // ----Or----
        // Whenever any data from a file is uploaded.

        const areNumberOfRowsChanged =
          previous.searchItems.length !== current.searchItems.length

        const isNewDataUploadedFromExcel =
          previous.dataVersionFromFileUpload !==
          current.dataVersionFromFileUpload

        let isReRenderingOutputConsideredEqual = true

        if (areNumberOfRowsChanged || isNewDataUploadedFromExcel) {
          isReRenderingOutputConsideredEqual = false
        }

        return isReRenderingOutputConsideredEqual
      }
    )
  }, [])

  useEffect(
    function seekToLastPageWhenDataLengthChanges() {
      // 🚨 Dont seek to last page when you are using the pagination buttons to view around the available pages.
      if (
        dataSourceLength === 0 ||
        dataSourceLength === previousDataSourceLengthRef.current
      ) {
        return
      }
      const previousDataSourceLength = previousDataSourceLengthRef.current

      previousDataSourceLengthRef.current = dataSourceLength

      const lastPageNumber = getLastPageNumber({
        dataSourceLength,
        pageSize: TABLE_PAGE_SIZE,
      })

      // 🚨 Dont seek to last page when you are in some page before and delete a row, you would want to remain in the same page.
      if (
        dataSourceLength < previousDataSourceLength &&
        currentPage < lastPageNumber
      ) {
        return
      }

      if (lastPageNumber === currentPage) {
        return
      }

      setCurrentPage(lastPageNumber)
    },
    [dataSourceLength, currentPage]
  )

  useEffect(function showAlertMessage() {
    if (dataSourceLength !== 0) {
      return
    }
    message.warn(
      'Did not find any saved search items, please enter new search items now'
    )
  }, [])

  return (
    <Table
      // 🔥🔥IMPORTANT NOTE🔥🔥
      // dataSourceLength === 0 check is important, otherwise <SearchFormRow /> comp will get called with `searchItem` as `undefined` and 🔥🔥BLOW UP THE Table component!🔥🔥
      components={
        dataSourceLength === 0
          ? undefined
          : {
              body: {
                row: SearchFormRow,
              },
            }
      }
      size="large"
      dataSource={searchItems}
      columns={columns}
      // Pass data you want available in <SearchFormRow /> component
      onRow={(searchItem, rowIndex): SearchFormRowSpecialProps => {
        return {
          searchItem,
          rowIndex: rowIndex as number,
          onClick: () => {},
        }
      }}
      rowKey={(searchItem) => searchItem.rowKey}
      rowClassName="editable-row"
      pagination={{
        showSizeChanger: false,
        pageSize: TABLE_PAGE_SIZE,
        current: currentPage, //🔥🔥 IMPORTANT NOTE 🔥🔥: current value HAS TO BE GREATER THAN ZERO, PASSING ZERO WILL 🔥🔥BLOW UP THE TABLE COMPONENT!🔥🔥
        position: ['topRight'],
        style: {
          position: 'absolute',
          right: 0,
          zIndex: 2,
          margin: 0,
          top: -60,
        },
        onChange: (page, pageSize) => {
          setCurrentPage(page)
        },
      }}
      id="molecule-search-form-table"
      footer={() => (
        <Row justify="center">
          <Space size="large">
            <NewSearchButtonWrapper />
            <SavedSearchResultsButtonWrapper />
          </Space>
        </Row>
      )}
    />
  )
}

function FileUploadSearch() {
  const searchId = useSearchId()

  function handleOnChange({
    file,
  }: UploadChangeParam<UploadFile<XlsxUploadSearchItemsAPIResponseBody>>) {
    if (file.status === 'done') {
      const { response } = file
      if (response === undefined) {
        message.error(
          `Some error occurred, did not receive parsed data from successful file upload.`
        )
        return
      }
      dispatch({
        type: 'HYDRATE_FROM_FILE_UPLOAD_RESPONSE_DATA',
        payload: response.data,
      })
      dispatch({
        type: 'SET_FLOW_TYPE',
        payload: { flowType: 'BULK_UPLOAD_SEARCH' },
      })
      message.success(`${file.name} file uploaded successfully`)
    } else if (file.status === 'error') {
      message.error(`${file.name} file upload failed.`)
    }
  }

  const { authHeader } = getAuthHeader()

  return (
    <LargeVerticalSpacer>
      <Row justify="space-between" align="middle">
        <Typography.Text type="secondary" style={{ fontSize: 16 }}>
          Search by bulk uploading materials list in excel
        </Typography.Text>
        <Button
          icon={<DownloadOutlined />}
          onClick={() =>
            handleDownloadOnClick({
              downloadAPI: () => downloadBulkUploadTemplate({ searchId }),
            })
          }
        >
          Download Template
        </Button>
      </Row>
      <Upload
        accept=".xlsx"
        method="PUT"
        headers={authHeader}
        action={`${getBackendServiceURL()}/search/${searchId}/search-item/xlsx`}
        onChange={handleOnChange}
        progress={uploadProgressConfig}
      >
        <Button type="primary" style={{ padding: '0 30px 0 30px' }}>
          Upload File
        </Button>
      </Upload>
    </LargeVerticalSpacer>
  )
}

const CURRENCY_CODE_OPTIONS = CURRENCY_CODES_LIST.map((currencyCode) => ({
  value: currencyCode,
}))

function CurrencyCodeSelector() {
  const currencyCode = useSearchFormStore(currencyCodeSelector)

  return (
    <label>
      <Space>
        <Typography.Text strong>Currency</Typography.Text>
        <Select
          options={CURRENCY_CODE_OPTIONS}
          value={currencyCode}
          onSelect={(selection) => {
            dispatch({
              type: 'CHANGE_CURRENCY_CODE',
              payload: { currencyCode: selection },
            })
          }}
        />
      </Space>
    </label>
  )
}

function onWeightUnitChange({
  unit,
  excludeMin,
  excludeMax,
}: {
  unit: WeightCode
  excludeMin: number
  excludeMax: number
}) {
  console.log('[onInputChange] ', { unit, excludeMin, excludeMax })
  dispatch({
    type: 'CHANGE_WEIGHT_UNIT',
    payload: { unit },
  })
}

function onWeightLimitChange({
  unit,
  excludeMin,
  excludeMax,
}: {
  unit: WeightCode
  excludeMin: number
  excludeMax: number
}) {
  console.log('[onInputChange] ', { unit, excludeMin, excludeMax })
  dispatch({
    type: 'CHANGE_WEIGHT_RANGE',
    payload: { excludeMin, excludeMax },
  })
}

function AddNewSearchItemAndCurrencyCodeRow() {
  const unit = useSearchFormStore(unitSelector)
  const { excludeMin, excludeMax } = useSearchFormStore(weightRangeSelector)
  return (
    <Space size="large">
      <HideForTrialUser>
        <AddNewSearchItem />
      </HideForTrialUser>
      <CurrencyCodeSelector />
      <ToggleWeightUnit unit={unit} onInputChange={onWeightUnitChange} />
      <WeightRangeInput
        unit={unit}
        excludeMin={excludeMin}
        excludeMax={excludeMax}
        onInputChange={onWeightLimitChange}
      />
    </Space>
  )
}

function NewSearchJourneyFlowHeader() {
  const flowType = useSearchFormStore((state) => state.flowType)

  if (flowType === 'NOT_YET_CHOOSEN') {
    return (
      <>
        <Divider orientation="left">Or</Divider>
        <Typography.Text type="secondary" style={{ fontSize: 16 }}>
          Search by adding material names directly
        </Typography.Text>
      </>
    )
  }

  return (
    <Typography.Text type="secondary" style={{ fontSize: 16 }}>
      Parsed data for search
    </Typography.Text>
  )
}

export function NewSearchJourney() {
  const isEmpty = useSearchFormStore(checkIfEmptySelector)
  // It's better UX if the user sees one empty search form row that prompts, instead of the user seeing nothing and forcing him/her to go to the
  // "+ Add New Material" button to populate a search form row.
  if (isEmpty) {
    dispatch({
      type: 'ADD_NEW_SEARCH_ITEM',
    })
  }

  return (
    <LargeVerticalSpacer>
      <HideForTrialUser>
        <FileUploadSearch />
      </HideForTrialUser>
      <HideForTrialUser>
        <NewSearchJourneyFlowHeader />
      </HideForTrialUser>
      <AddNewSearchItemAndCurrencyCodeRow />
      <FormTable />
    </LargeVerticalSpacer>
  )
}

export function SavedSearchJourney() {
  const savedSearchResults = useSavedSearchResultsQuery()

  dispatch({
    type: 'HYDRATE_FROM_SAVED_SEARCH_API_DATA',
    payload: savedSearchResults,
  })

  return (
    <LargeVerticalSpacer>
      <HideForTrialUser>
        <FileUploadSearch />
      </HideForTrialUser>
      <AddNewSearchItemAndCurrencyCodeRow />
      <FormTable />
    </LargeVerticalSpacer>
  )
}

export default function Search() {
  const history = useHistory()

  const {
    isSavedSearchJourney,
    searchId: searchIdWhatShouldBe,
  } = useSearchPageDescription()

  const searchIdInSearchFormStore = useSearchFormStore(
    (state) => state.searchId
  )
  // (1) When coming from the "home" page,
  //    1. By "create new search" button, the store's state has to be re-set to empty state
  //    2. By "edit the results" button,
  //       (a) For a search id that is different than the one in store, store's state has to be re-set to empty and then hydrate from the query cache for that specific search id.
  //       (b) For a search id that is same as the one in store, store's state doesn't have to re-set, it already has the state of the search id user wants to edit the ressults for.
  // (2) When coming from the "search-results" page by stepping back in the history stack, search id in the store is
  //     same as that of the page's location state. We dont change it.
  if (searchIdWhatShouldBe !== searchIdInSearchFormStore) {
    dispatch({
      type: 'RESET_STATE',
    })
    dispatch({
      type: 'SET_SEARCH_ID',
      payload: {
        searchId: searchIdWhatShouldBe,
      },
    })
  }

  return (
    <Layout id="search-page">
      <Header />
      <Layout.Content>
        <PageHeader
          onBack={history.goBack}
          title={'Search Materials'}
          extra={<SearchDatabaseSummary />}
        />
        {isSavedSearchJourney ? (
          <ErrorBoundryAndSuspenseFallback>
            <SavedSearchJourney />
          </ErrorBoundryAndSuspenseFallback>
        ) : (
          <NewSearchJourney />
        )}
      </Layout.Content>
    </Layout>
  )
}
