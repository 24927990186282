import { Button, Tooltip, message, Skeleton } from 'antd'
import {
  DeleteTwoTone,
  SearchOutlined,
  LoadingOutlined,
} from '@ant-design/icons'
import {
  searchFormStoreAPI,
  useSearchFormStore,
  totalErrorCountSelector,
  ValidatedSearchItem,
  currencyCodeSelector,
  unitSelector,
} from '../Search.state'
import React from 'react'
import { useHistory } from 'react-router-dom'
import qs from 'qs'
import { useMutation, useQueryCache } from 'react-query'
import { createNewSearchResults } from 'api/search'
import {
  getSavedSearchResultsQueryKey,
  useSearchDatabaseSummary,
  useSearchResultsDataFromQueryCache,
  SEARCH_HISTORY_QUERY_KEY,
  useSavedSearchResultsQuery,
} from 'utils/reactQuery'
import { parseISO, subMonths } from 'date-fns'
import {
  DEFAULT_SELECTED_PERCENTILE,
  pagePaths,
  TRIAL_USER_SEARCH_DATE_RANGE,
  WEIGHT_SYMBOLS,
} from '../../../constants'
import { SearchResultsPageParamsInURL as SearchResultsPageLocationState } from 'pages/SearchResults/SearchResults'
import { useSearchId } from 'utils/hooks'
import { useAppWideStore } from 'App.state'
import { ErrorBoundryAndSuspenseFallback } from 'components/Fallback/Fallback'
import { FallbackProps } from 'react-error-boundary'

const { dispatch } = searchFormStoreAPI

export function DeleteSearchItem({ rowKey }: { rowKey: string }) {
  return (
    <Button
      type="link"
      onClick={() =>
        dispatch({
          type: 'DELETE_SEARCH_ITEM',
          payload: { rowKey },
        })
      }
      icon={<DeleteTwoTone twoToneColor={'red'} />}
      className="delete-record-button"
    />
  )
}

export function AddNewSearchItem() {
  function handleOnClick() {
    const { searchItems } = searchFormStoreAPI.getState()
    if (searchItems.length < 100) {
      dispatch({
        type: 'ADD_NEW_SEARCH_ITEM',
      })
    } else {
      message.error(
        'Maximum number of materials limit reached. Cannot add more than 100 materials per search'
      )
    }
  }
  return (
    <Button
      type="link"
      className="add-new-molecule-button"
      onClick={handleOnClick}
    >
      + Add New Material
    </Button>
  )
}

function NewSearchButton() {
  const searchId = useSearchId()
  const totalErrorsCount = useSearchFormStore(totalErrorCountSelector)
  const currencyCode = useSearchFormStore(currencyCodeSelector)
  const unit = useSearchFormStore(unitSelector)
  const history = useHistory()
  const {
    data: searchDatabaseSummary,
    status: searchDatabaseSummaryQueryStatus,
  } = useSearchDatabaseSummary()

  const isSearchAlreadyMade =
    useSavedSearchResultsQuery().searchItems.length !== 0

  const { isTrialUser } = useAppWideStore()

  const queryCache = useQueryCache()

  const [searchFreshResults, { status }] = useMutation(createNewSearchResults, {
    onSuccess: (data) => {
      message.success('Successful search')
      queryCache.invalidateQueries(SEARCH_HISTORY_QUERY_KEY, {
        exact: true,
        refetchInactive: true,
      })
      queryCache.setQueryData(getSavedSearchResultsQueryKey({ searchId }), data)
      const searchResultsPageParamsInURL: SearchResultsPageLocationState = {
        searchId,
        currencyCode,
        unit,
      }
      const searchResultsPageParamsInURLStringified = qs.stringify(
        searchResultsPageParamsInURL,
        {
          addQueryPrefix: true,
          strictNullHandling: true,
        }
      )
      history.push(
        `${pagePaths.searchResults}${searchResultsPageParamsInURLStringified}`,
        searchResultsPageParamsInURL
      )
    },
  })

  function handleOnClick() {
    const {
      searchItems,
      unit,
      excludeMin,
      excludeMax,
    } = searchFormStoreAPI.getState()
    if (searchDatabaseSummary === undefined) {
      message.error(
        'Could not retrive the transaction date range information required to make the search due to some error'
      )
      return
    }
    const { latestTransactionDate } = searchDatabaseSummary
    if (latestTransactionDate === null) {
      message.error(
        'Cannot proceed search because the retrieval of latest transaction date required to make the search failed due to some error'
      )
      return
    }
    if (isTrialUser) {
      searchFreshResults({
        searchItems: searchItems as ValidatedSearchItem[],
        searchId,
        fromDateISO: TRIAL_USER_SEARCH_DATE_RANGE.fromDateISO,
        toDateISO: TRIAL_USER_SEARCH_DATE_RANGE.toDateISO,
        percentile: DEFAULT_SELECTED_PERCENTILE,
        currencyCode,
        unit,
        excludeMin,
        excludeMax,
      })
      return
    }
    const fromDateISO = subMonths(
      parseISO(latestTransactionDate),
      24
    ).toISOString()

    searchFreshResults({
      searchItems: searchItems as ValidatedSearchItem[],
      searchId,
      fromDateISO,
      toDateISO: latestTransactionDate,
      percentile: DEFAULT_SELECTED_PERCENTILE,
      currencyCode,
      unit,
      excludeMin,
      excludeMax,
    })
  }

  const isFormInvalidToSearchFor =
    totalErrorsCount !== 0 ||
    typeof searchDatabaseSummary?.latestTransactionDate !== 'string'

  let tooltipTitle
  if (isFormInvalidToSearchFor) {
    if (totalErrorsCount !== 0) {
      tooltipTitle = 'Cannot proceed search due to above errors'
    } else {
      if (searchDatabaseSummaryQueryStatus === 'loading') {
        tooltipTitle =
          'Retrieving the latest date range information to make the search. Please wait...'
      } else if (
        searchDatabaseSummaryQueryStatus === 'success' &&
        typeof searchDatabaseSummary?.latestTransactionDate !== 'string'
      ) {
        tooltipTitle =
          'Cannot proceed search because the retrieval of latest transaction date required to make the search failed due to some error'
      } else {
        tooltipTitle =
          'Could not retrive the transaction date range information required to make the search due to some error'
      }
    }
  } else {
    tooltipTitle = null
  }

  if (isTrialUser && isSearchAlreadyMade) {
    return null
  }

  return (
    <Tooltip
      title={tooltipTitle}
      color={isFormInvalidToSearchFor ? 'red' : undefined}
    >
      <Button
        type="primary"
        size="large"
        onClick={handleOnClick}
        disabled={isFormInvalidToSearchFor}
        icon={status === 'loading' ? <LoadingOutlined /> : <SearchOutlined />}
      >
        New Search
      </Button>
    </Tooltip>
  )
}

export function NewSearchButtonWrapper() {
  return (
    <ErrorBoundryAndSuspenseFallback
      suspenseFallback={
        <Skeleton.Input style={{ width: 140 }} active size="large" />
      }
      errorFallbackRender={ButtonErrorFallback}
    >
      <NewSearchButton />
    </ErrorBoundryAndSuspenseFallback>
  )
}

function ButtonErrorFallback(props: FallbackProps) {
  return (
    <Button type="primary" size="large" disabled>
      Error occurred
    </Button>
  )
}

function SavedSearchResultsButton() {
  const history = useHistory()

  const searchId = useSearchId()
  const currencyCode = useSearchResultsDataFromQueryCache()?.currencyCode
  const unit = useSearchResultsDataFromQueryCache()?.unit || WEIGHT_SYMBOLS.KGS

  const isThisASearchWithZeroSearchItems =
    useSavedSearchResultsQuery().searchItems.length === 0

  function handleOnClick() {
    if (currencyCode === undefined) {
      message.error(
        'Unexpected missing of currency code in query cache, cannot proceeed witth search.'
      )
      return
    }
    const searchResultsPageLocationState: SearchResultsPageLocationState = {
      searchId,
      currencyCode,
      unit,
    }
    const searchResultsPageLocationStateStringified = qs.stringify(
      searchResultsPageLocationState,
      {
        addQueryPrefix: true,
        strictNullHandling: true,
      }
    )
    history.push(
      `${pagePaths.searchResults}${searchResultsPageLocationStateStringified}`,
      searchResultsPageLocationState
    )
  }

  if (isThisASearchWithZeroSearchItems) {
    return null
  }

  return (
    <Button type="primary" size="large" onClick={handleOnClick}>
      See Saved Search Results
    </Button>
  )
}

export function SavedSearchResultsButtonWrapper() {
  return (
    <ErrorBoundryAndSuspenseFallback
      suspenseFallback={
        <Skeleton.Input style={{ width: 140 }} active size="large" />
      }
      errorFallbackRender={ButtonErrorFallback}
    >
      <SavedSearchResultsButton />
    </ErrorBoundryAndSuspenseFallback>
  )
}
