import React from 'react'
import isNumber from 'lodash/isNumber'
import { formatCurrency, formatQuantity, convertRangeToString } from 'utils'
import { Statistic as AntdStatistic } from 'antd'
import { StatisticProps } from 'antd/lib/statistic/Statistic'
import { useAppWideStore } from 'App.state'
import { useCurrencyCode } from 'utils/hooks'
import { CURRENCY_SYMBOLS, DATE_FORMAT } from '../../constants'
import { format, parseISO } from 'date-fns'
import { PriceSuperscript } from 'components/Layout/Layout'

type ScalarType = 'currency' | 'quantity'

type FormatterProps = { type: ScalarType; value: number | null }

function formatScaler({
  type,
  value,
  locale,
}: FormatterProps & { locale: string }) {
  let formatedValue: string

  if (type === 'currency') {
    formatedValue = isNumber(value) ? formatCurrency({ value, locale }) : '-'
  } else {
    formatedValue = isNumber(value)
      ? formatQuantity({ quantity: value, locale })
      : '-'
  }

  return formatedValue
}

export function QuantityScalar({ value }: { value: number | null }) {
  const { locale } = useAppWideStore()

  const formatedValue: string = formatScaler({
    type: 'quantity',
    value,
    locale,
  })

  return <>{formatedValue}</>
}

export function CurrencyScalar({
  txnType,
  value,
}: {
  txnType: string | null
  value: number | null
}) {
  const { locale } = useAppWideStore()

  const formatedValue: string = formatScaler({
    type: 'currency',
    value,
    locale,
  })

  if (value === null) {
    return <>{formatedValue}</>
  }

  return (
    <>
      {formatedValue} <PriceSuperscript txnType={txnType} />
    </>
  )
}

type RangeStatisticProps = Omit<StatisticProps, 'prefix'> & {
  range: { low: number | null; high: number | null }
}

export function QuantityRangeStatistic({
  range,
  ...props
}: RangeStatisticProps) {
  const { locale } = useAppWideStore()

  return (
    <AntdStatistic
      value={convertRangeToString({
        ...range,
        locale,
      })}
      {...props}
    />
  )
}

export function CurrencyRangeStatistic({
  range,
  ...props
}: RangeStatisticProps) {
  const { locale } = useAppWideStore()
  const currencyCode = useCurrencyCode()

  return (
    <AntdStatistic
      value={convertRangeToString({
        ...range,
        locale,
      })}
      prefix={CURRENCY_SYMBOLS[currencyCode]}
      {...props}
    />
  )
}

export function TextualStatictic({
  value,
  ...props
}: Omit<StatisticProps, 'value'> & { value: string | number | null }) {
  let formattedValue: string | number
  if (value === '' || value === null) {
    formattedValue = '-'
  } else {
    formattedValue = value
  }
  return <AntdStatistic groupSeparator="" value={formattedValue} {...props} />
}

type ScalarProps = Omit<StatisticProps, 'value' | 'prefix'> &
  Omit<FormatterProps, 'type'>

export function CurrencyStatistic({ value, ...props }: ScalarProps) {
  const { locale } = useAppWideStore()
  const currencyCode = useCurrencyCode()
  if (value === null) {
    return (
      <AntdStatistic
        value={'-'}
        title={props.title}
        prefix={CURRENCY_SYMBOLS[currencyCode]}
      />
    )
  }
  return (
    <AntdStatistic
      value={formatScaler({ type: 'currency', value, locale })}
      prefix={CURRENCY_SYMBOLS[currencyCode]}
      {...props}
    />
  )
}

export function QuantityStatistic({ value, ...props }: ScalarProps) {
  const { locale } = useAppWideStore()
  return (
    <AntdStatistic
      value={formatScaler({ type: 'quantity', value, locale })}
      {...props}
    />
  )
}

export function DateStatistic({
  ISODateString,
  formatString,
  ...props
}: Omit<StatisticProps, 'value' | 'prefix'> & {
  ISODateString: string | null
  formatString: DATE_FORMAT
}) {
  const valueToShow = ISODateString
    ? format(parseISO(ISODateString), formatString)
    : 'Not found'
  return <AntdStatistic value={valueToShow} {...props} />
}

export function DateRangeStatistic({
  ISODateStringRange,
  formatString,
  ...props
}: Omit<StatisticProps, 'value' | 'prefix'> & {
  ISODateStringRange: {
    fromDate: string
    toDate: string
  }
  formatString: DATE_FORMAT
}) {
  const valueToShow = [ISODateStringRange.fromDate, ISODateStringRange.toDate]
    .map((ISODateString) => {
      return format(parseISO(ISODateString), formatString)
    })
    .join(' to ')

  return <AntdStatistic value={valueToShow} {...props} />
}
