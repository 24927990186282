import { APIResponseBodyBase, Material } from './utils'
import { axios } from './utils'
import { CurrencyCode, DATE_FORMAT, WeightCode } from '../constants'
import { format, parseISO } from 'date-fns'

export type SearchResultsAPIData = {
  searchItems: Array<SearchItemResults>
  summary: SearchResultsSummary
  id: number
  fromDate: string // ISO formatted
  toDate: string // ISO formatted
  percentile: number
  currencyCode: CurrencyCode
  unit: WeightCode
  excludeMin: number | undefined
  excludeMax: number | undefined
}

export type SearchResultsAPIResponseBody = APIResponseBodyBase<
  SearchResultsAPIData
>

export type MaterialSupplier = {
  id: number
  searchId: number
  searchItemId: number
  eximDataId: number
  supplierId: number
  name: string
  unitPrice: number | null
  unit: string
  supplierCountry: string | null
  quantity: number | null
  beDate: string
  accrediation: Array<string> | null
  customers: Array<string> | null
  included: boolean
  savingsPercentage: number | null
  isExpertData: boolean
  productDescription: string
  txnType: string | null
  hsCode: number | null
}

export type SearchItemResults = {
  id: number
  searchId: number
  molecule: Material
  hsCode: number | null
  type: string | null
  grade: Array<string>
  currentPricingPerUnit: number | null
  totalAnnualizedVolume: number | null
  unit: WeightCode
  duration: number
  estimatedPricePerUnit: number
  estimatedSavingsLow: number | null
  estimatedSavingsHigh: number | null
  searchResults: Array<MaterialSupplier>
  txnType: string | null
}

export type SearchResultsSummary = {
  currentSpend: number | null
  projectedSpendLow: number | null
  projectedSpendHigh: number | null
  averagePercentageSavingsLow: number | null
  averagePercentageSavingsHigh: number | null
}

export type SearchResultsAPIRequestBody = {
  data: Array<{
    type: string | null
    moleculeId: number
    grade: Array<string>
    currentPricingPerUnit: number | null
    totalAnnualizedVolume: number | null
    unit: string
    hsCode: number | null
  }>
}

export function getSavedSearchResults({ searchId }: { searchId: number }) {
  return axios.get<SearchResultsAPIData>(`/search/${searchId}`)
}

export function createNewSearchResults({
  searchItems,
  searchId,
  currencyCode,
  fromDateISO,
  percentile,
  toDateISO,
  unit,
  excludeMin,
  excludeMax,
}: {
  searchItems: SearchResultsAPIRequestBody['data']
  searchId: number
  fromDateISO: string
  toDateISO: string
  percentile: number
  currencyCode: CurrencyCode
  unit: WeightCode
  excludeMin: number | undefined
  excludeMax: number | undefined
}) {
  const fromDateFormatted = format(parseISO(fromDateISO), DATE_FORMAT.yyyyMM)
  const toDateFormatted = format(parseISO(toDateISO), DATE_FORMAT.yyyyMM)
  return axios.put<SearchResultsAPIData>(
    `/search/${searchId}`,
    {
      data: searchItems.map((val) => {
        const seachItemObj: any = { ...val }
        // convert hsCode to String if it exists
        if (seachItemObj.hasOwnProperty('hsCode') && seachItemObj.hsCode) {
          seachItemObj.hsCode = String(seachItemObj.hsCode)
        }
        return seachItemObj
      }), // 'data' prop is added by axios to the request body to the value we provide here,
    },
    {
      params: {
        currencyCode,
        percentile,
        fromDate: fromDateFormatted,
        unit,
        excludeMin,
        excludeMax,
        toDate: toDateFormatted,
      },
    }
  )
}

export type SavedMoleculesSearchFormAPIData = Array<
  Omit<
    SearchItemResults,
    | 'searchResults'
    | 'estimatedPricePerUnit'
    | 'estimatedSavingsLow'
    | 'estimatedSavingsHigh'
  >
>

export type SearchItemResultAPIResponseBody = APIResponseBodyBase<
  SearchItemResults
>

export function getSearchItemResults({
  searchId,
  searchItemId,
}: {
  searchId: number
  searchItemId: number
}) {
  return axios.get<SearchItemResults>(
    `/search/${searchId}/search-items/${searchItemId}`
  )
}

export type SmartChemSupplier = {
  id: number
  companyName: string
  website: string | null
  email: string | null
  country: string | null
  address: string | null
  telNo: string | null
  typeOfCompany: string | null
  accrediation: string[] | null
  dataSource: string
}

export type SmartChemSuppliersAPIData = Array<SmartChemSupplier>

export type SmartChemSuppliersAPIResponseBody = APIResponseBodyBase<
  Array<SmartChemSupplier>
>

export function getSmartChemSuppliers({
  searchId,
  searchItemId,
}: {
  searchId: number
  searchItemId: number
}) {
  return axios.get<SmartChemSuppliersAPIData>(
    `/search/${searchId}/search-items/${searchItemId}/smartchem-exim-suppliers`
  )
}

export type CreateNewSearchAPIResponseBody = APIResponseBodyBase<{
  id: number
}>

export type CreateNewSearchAPIData = {
  id: number
}

export function createNewSearchEntry({ tag }: { tag: string }) {
  return axios.post<CreateNewSearchAPIData>(`/search`, {
    name: tag,
  })
}

export type SearcheHistoryDetailsAPIData = Array<{
  id: number
  averagePercentageSavingsLow: number | null
  averagePercentageSavingsHigh: number | null
  noOfSelectedVendors: number
  noOfMoleculesExplored: number
  name: string
}>

export type SearcheHistoryDetailsAPIResponseBody = APIResponseBodyBase<
  SearcheHistoryDetailsAPIData
>

export function getSearchHistoryDetails() {
  return axios.get<SearcheHistoryDetailsAPIData>('/search')
}

export type ToggleVendorInclusionStatusAPIResponseBody = SearchResultsAPIResponseBody

export function toggleVendorInclusionStatus({
  searchId,
  searchItemId,
  vendorId,
  included,
}: {
  searchId: number
  searchItemId: number
  vendorId: number
  included: boolean
}) {
  return axios.put<SearchResultsAPIData>(
    `/search/${searchId}/search-items/${searchItemId}/supplier/${vendorId}`,
    {
      included,
    }
  )
}

export function downloadSummary({ searchId }: { searchId: number }) {
  return axios.get(`/search/${searchId}/summary/download-csv`, {
    responseType: 'blob',
    headers: {
      accept: '*/*',
    },
  })
}

export function downloadMoleculeSearchResults({
  searchId,
}: {
  searchId: number
}) {
  return axios.get(`/search/${searchId}/download-csv`, {
    responseType: 'blob',
    headers: {
      accept: '*/*',
    },
  })
}

export type DeleteSearchAPIData = { id: number }

export function deleteSearch({ searchId }: { searchId: number }) {
  return axios.delete<DeleteSearchAPIData>(`/search/${searchId}`)
}

export function downloadVendorList({
  searchId,
  searchItemId,
}: {
  searchId: number
  searchItemId: number
}) {
  return axios.get(
    `/search/${searchId}/search-item/${searchItemId}/download-csv`,
    {
      responseType: 'blob',
      headers: {
        accept: '*/*',
      },
    }
  )
}

export function downloadSmartChemVendorsList({
  searchId,
  searchItemId,
}: {
  searchId: number
  searchItemId: number
}) {
  return axios.get(
    `/search/${searchId}/search-items/${searchItemId}/smartchem-exim-suppliers/download-csv`,
    {
      responseType: 'blob',
      headers: {
        accept: '*/*',
      },
    }
  )
}

export type XlsxUploadSearchItemsAPIData = {
  errors: Array<{
    searchItem: {
      type: string | null
      name: string
      grade: Array<string>
      currentPricingPerUnit: number | null
      totalAnnualizedVolume: number | null
      unit: string
      duration: number
      hsCode: number | null
    }
    rowNo: number
    columns: Array<
      | 'name'
      | 'type'
      | 'grade'
      | 'currentPricingPerUnit'
      | 'totalAnnualizedVolume'
      | 'hsCode'
    >
  }>
  validatedSearchItems: Array<{
    type: string | null
    name: string
    moleculeId: number
    grade: Array<string>
    currentPricingPerUnit: number | null
    totalAnnualizedVolume: number | null
    unit: string
    duration: number
    hsCode: number | null
  }>
}

export type XlsxUploadSearchItemsAPIResponseBody = APIResponseBodyBase<
  XlsxUploadSearchItemsAPIData
>

export function downloadBulkUploadTemplate({ searchId }: { searchId: number }) {
  return axios.get(`/search/${searchId}/search-item/xlsx`, {
    responseType: 'blob',
    headers: {
      accept: '*/*',
    },
  })
}

export type SearchDatabaseSummaryAPIData = {
  noOfMolecules: number
  latestTransactionDate: string | null
  earliestTransactionDate: string | null
  filesCount: number
  eximTransactionsCount: number
  eximToBeReparsedCount: number
}
export type SearchDatabaseSummaryAPIResponseBody = APIResponseBodyBase<
  SearchDatabaseSummaryAPIData
>

export function getSearchDatabaseSummary() {
  return axios.get<SearchDatabaseSummaryAPIData>('/search/summary')
}

export function getSupplierTableData(
  searchId: number,
  searchItemId: number,
  importerCountry: string | null
) {
  return axios.get<any>(
    `/search/${searchId}/search-item/${searchItemId}/top-supplier-countries`,
    { params: { importerCountry } }
  )
}
export function getSupplierGraphData(
  searchId: number,
  searchItemId: number,
  importerCountry: string | null
) {
  return axios.get<any>(
    `/search/${searchId}/search-item/${searchItemId}/exporter-bar-graph`,
    { params: { importerCountry } }
  )
}

export function getImporterCountryList(searchId: number, searchItemId: number) {
  return axios.get<any>(
    `/search/${searchId}/search-item/${searchItemId}/importer-country-list`
  )
}

export function getImporterPricePoints(
  searchId: number,
  searchItemId: number,
  importerCountry: string | null
) {
  return axios.get<any>(
    `/search/${searchId}/search-item/${searchItemId}/importer-price-points`,
    { params: { importerCountry } }
  )
}

export function getAllSuppliers(
  searchId: number,
  searchItemId: number,
  importerCountry: string | null
) {
  return axios.get<any>(
    `/search/${searchId}/search-item/${searchItemId}/suppliers`,
    { params: { importerCountry } }
  )
}

export function getSuppliersCustomerProfileData(
  searchId: number,
  searchItemId: number,
  supplierId: string | number,
  importerCountry: string | null
) {
  return axios.get<any>(
    `/search/${searchId}/search-item/${searchItemId}/supplier/${supplierId}/customer-profile`,
    { params: { importerCountry } }
  )
}

export function getCustomerPricePointsData(
  searchId: number,
  searchItemId: number,
  supplierId: string | number,
  importerCountry: string | null
) {
  return axios.get<any>(
    `/search/${searchId}/search-item/${searchItemId}/supplier/${supplierId}/customer-price-points`,
    { params: { importerCountry } }
  )
}
