import { Skeleton, Space, Select, Typography } from 'antd'
import { getImporterPricePoints } from 'api/search'
import React, { useEffect, useState } from 'react'
import { useQuery } from 'react-query'
import ImporterPriceDotGraph from './ImporterPriceDotGraph'
import { useSearchItemDescription } from 'pages/Review/Review.hooks'
import CountrySelectionDropdown from '../shared/CountrySelectionDropdown'
// import { DummyImporterPriceDataNew } from './DummyImporterPriceData'
import { WEIGHT_SYMBOLS } from '../../../../constants'
import { convertPricePerUnitOfWeight } from 'utils/helperFunctions'
import { SelectClientLable } from './constant'

// const dataNew = DummyImporterPriceDataNew
const { Option } = Select

export default function CountriesExportingToIndia() {
  const { searchItemId, searchId, unit } = useSearchItemDescription()
  const [country, setCountry] = useState(null)

  const {
    data: importerPricePointsData,
    isLoading,
    loading,
    error,
  }: any = useQuery({
    queryKey: ['importer-price-points', { searchId, searchItemId, country }],
    queryFn: () => getImporterPricePoints(searchId, searchItemId, country),
    config: {
      suspense: true,
      staleTime: Infinity,
    },
  })

  const [clients, setClients] = useState([
    SelectClientLable,
    ...importerPricePointsData.importerList,
  ])
  const [client, setClient] = useState(
    clients.length > 0 ? clients[0] : undefined
  )

  useEffect(() => {
    if (!error && !loading) {
      setClients([SelectClientLable, ...importerPricePointsData.importerList])
      setClient(clients.length > 0 ? clients[0] : undefined)
    }
  }, [importerPricePointsData, error, loading])

  const onCountryChange = (country: any) => {
    setCountry(country)
  }

  const onClientChange = (client: any) => {
    setClient(client)
  }

  const chartDataNew: any[] = []
  let doesDataExists: boolean = false
  for (let val of importerPricePointsData.data) {
    const tempObj: any = { month: val.month }
    if (val.pricePoints.length > 0) doesDataExists = true
    for (let pricePoint of val.pricePoints) {
      tempObj[pricePoint.importerName] = convertPricePerUnitOfWeight(
        pricePoint.value,
        WEIGHT_SYMBOLS.KGS,
        unit
      )
    }
    tempObj['monthlyAveragePrice'] = convertPricePerUnitOfWeight(
      val.monthlyAveragePrice,
      WEIGHT_SYMBOLS.KGS,
      unit
    )
    chartDataNew.push(tempObj)
  }

  console.log(' data ', importerPricePointsData)

  return (
    <div>
      <Space
        style={{
          marginBottom: '15px',
          display: 'flex',
          justifyContent: 'end',
        }}
      >
        <Typography>Importer Country</Typography>
        <CountrySelectionDropdown onChange={onCountryChange} />

        <Typography>Importer</Typography>
        <Select value={client} style={{ width: 170 }} onChange={onClientChange}>
          {clients.map((val: any) => (
            <Option key={val} value={val}>
              {val}
            </Option>
          ))}
        </Select>
      </Space>

      {isLoading && !country && !client ? (
        <Skeleton />
      ) : (
        <div style={{ marginBottom: '15px' }}>
          {doesDataExists ? (
            <ImporterPriceDotGraph
              country={country}
              chartData={chartDataNew}
              selectedClient={client}
              allSuppliers={clients}
            ></ImporterPriceDotGraph>
          ) : (
            <Typography.Title level={5} style={{ textAlign: 'center' }}>
              No Data
            </Typography.Title>
          )}
        </div>
      )}
    </div>
  )
}
