import React, { useMemo } from 'react'
import {
  Layout,
  PageHeader,
  Descriptions,
  Space,
  Tabs,
  Select,
  Table,
  Typography,
  DatePicker,
  Skeleton,
} from 'antd'
import Header from 'components/Header/Header'
import './VendorDetails.less'
import { useHistory, useLocation } from 'react-router-dom'
import DescriptionsItem from 'antd/lib/descriptions/Item'
import { useQuery } from 'react-query'
import {
  fetchVendorInfo,
  VendorInfoAPIData,
  fetchVendorTransactions,
  VendorTransaction,
  VendorTransactionsAPIData,
} from 'api/vendors'
import {
  ErrorBoundryAndSuspenseFallback,
  DefaultErrorFallback,
} from 'components/Fallback/Fallback'
import { ColumnsType } from 'antd/lib/table'
import { parseISO, format } from 'date-fns'
import {
  getCalenderMonthOptions,
  formatUnit,
  filterCustomers,
  TableDefaultRowProps,
} from 'utils'
import isAfter from 'date-fns/isAfter'
import isNumber from 'lodash/isNumber'
import moment, { Moment } from 'moment'
import { QuantityScalar, CurrencyScalar } from 'components/Scalars/Scalars'
import {
  CurrencyCode,
  DEFAULT_UNIT,
  TRIAL_USER_SEARCH_DATE_RANGE,
  DEFUALT_FOR_EMPTY_OR_NULL,
  WeightCode,
  WEIGHT_LABELS,
  WEIGHT_SYMBOLS,
} from '../../constants'
import { PriceColumnHeader, VendorRowTooltip } from 'components/Layout/Layout'
import create from 'zustand'
import { Material } from 'api/utils'
import produce from 'immer'
import pick from 'lodash/pick'
import { useAppWideStore } from 'App.state'
import {
  convertPricePerUnitOfWeight,
  convertWeight,
} from 'utils/helperFunctions'
const { TabPane } = Tabs

export type VendorDetailsPageLocationState = {
  searchId: number
  vendorId: number
  vendorName: string
  month: number
  year: number
  molecule: Material
  currencyCode: CurrencyCode
  unit: WeightCode
}

function VendorDescription() {
  const {
    companyName,
    country,
    email,
    telNo,
    website,
    molecules,
    accrediation,
    address,
  } = useVendorInfo()

  return (
    <>
      <Descriptions column={4} layout="vertical" colon={false}>
        <DescriptionsItem label="NAME OF COMPANY">
          {companyName}
        </DescriptionsItem>
        <DescriptionsItem label="ADDRESS">{address ?? '-'}</DescriptionsItem>
        <DescriptionsItem label="COUNTRY">{country ?? '-'}</DescriptionsItem>
        <DescriptionsItem label="ACCREDITATION">
          {accrediation.length === 0 ? '-' : accrediation.join(', ')}
        </DescriptionsItem>
        <DescriptionsItem label="TELEPHONE">{telNo ?? '-'}</DescriptionsItem>
        <DescriptionsItem label="WEBSITE">
          {website ? <Typography.Link>{website}</Typography.Link> : '-'}
        </DescriptionsItem>
        <DescriptionsItem label="EMAIL">{email ?? '-'}</DescriptionsItem>
      </Descriptions>
      <Descriptions
        title="MATERIALS MANUFACTURED/TRADED"
        column={4}
        layout="vertical"
        colon={false}
      >
        {molecules.map(({ id, name }) => (
          <DescriptionsItem key={id}>{name}</DescriptionsItem>
        ))}
      </Descriptions>
    </>
  )
}

function useVendorInfo() {
  const { vendorId } = useVendorDetailsDescription()
  const { data } = useQuery<VendorInfoAPIData>({
    queryKey: ['vendor-info', { vendorId }],
    queryFn: () => fetchVendorInfo({ vendorId }),
    config: {
      suspense: true,
    },
  })

  return data as VendorInfoAPIData
}

type VendorTxnsQueryURLParams = {
  month: number | null // goes from 0 to 11 as native Date.getMonth() does
  year: number
  currencyCode: CurrencyCode
}

type VendorTxnsParamsStore = {
  selectedMaterial: Material | null
  vendorTxnsQueryURLParams: VendorTxnsQueryURLParams | null
  set: (fn: (arg0: VendorTxnsParamsStore) => void) => void
}

const [useVendorTxnsParamsStore, vendorTxnsParamsStoreAPI] = create<
  VendorTxnsParamsStore
>((set, get) => ({
  selectedMaterial: null,
  vendorTxnsQueryURLParams: null,
  set: (fn) => set(produce(fn)),
}))

function useDefaultMaterial() {
  return useVendorDetailsDescription().molecule
}

function useDefaultVendorTxnsQueryURLParams() {
  return pick(
    useVendorDetailsDescription(),
    'year',
    'month',
    'currencyCode'
  ) as VendorTxnsQueryURLParams & { month: number }
}

function useSelectedVendorTxnQueryURLParams() {
  return (
    useVendorTxnsParamsStore((state) => state.vendorTxnsQueryURLParams) ??
    useDefaultVendorTxnsQueryURLParams()
  )
}

function useSelectedMaterial() {
  return (
    useVendorTxnsParamsStore((state) => state.selectedMaterial) ??
    useDefaultMaterial()
  )
}

function useVendorTxns() {
  const { vendorId } = useVendorDetailsDescription()
  const selectedMaterial = useSelectedMaterial()
  const moleculeId = selectedMaterial.id
  const queryURLParams = useSelectedVendorTxnQueryURLParams()
  const { data } = useQuery({
    queryKey: [
      'vendor-transactions',
      { vendorId, moleculeId, ...queryURLParams },
    ],
    queryFn: () =>
      fetchVendorTransactions({ vendorId, moleculeId, ...queryURLParams }),
    config: {
      suspense: true,
    },
  })

  return {
    vendorTxns: data as VendorTransactionsAPIData,
    ...queryURLParams,
    selectedMaterial,
  }
}

function useVendorDetailsDescription() {
  return useLocation<VendorDetailsPageLocationState>().state
}

const CALENDER_MONTH_OPTIONS = getCalenderMonthOptions()

/**
 * @param month `0` to `11` or `null`
 */
function getMonthName(month: number | null) {
  let monthName: string
  if (month === null) {
    monthName = 'All'
  } else {
    monthName = CALENDER_MONTH_OPTIONS[month].value
  }
  return monthName
}

const MONTH_SELECTOR_OPTIONS = [
  { value: 'All', id: null },
  ...CALENDER_MONTH_OPTIONS,
]

function MonthSelector() {
  const defaultVendorTxnsQueryURLParams = useDefaultVendorTxnsQueryURLParams()

  function handleOnChange(
    value: string,
    optionData: { id: number; value: number }
  ) {
    vendorTxnsParamsStoreAPI.getState().set((state) => {
      const selectedMonthId = optionData.id
      if (state.vendorTxnsQueryURLParams === null) {
        state.vendorTxnsQueryURLParams = defaultVendorTxnsQueryURLParams
      }
      state.vendorTxnsQueryURLParams.month = selectedMonthId
    })
  }

  return (
    <Select
      style={{ width: 120 }}
      options={MONTH_SELECTOR_OPTIONS}
      defaultValue={getMonthName(defaultVendorTxnsQueryURLParams.month)}
      //@ts-ignore
      onChange={handleOnChange}
    />
  )
}

function YearSelector() {
  const defaultVendorTxnsQueryURLParams = useDefaultVendorTxnsQueryURLParams()
  const { isTrialUser } = useAppWideStore()

  function handleOnChange(dateMoment: Moment | null, year: string) {
    vendorTxnsParamsStoreAPI.getState().set((state) => {
      const selectedYear = Number(year)
      if (state.vendorTxnsQueryURLParams === null) {
        state.vendorTxnsQueryURLParams = defaultVendorTxnsQueryURLParams
      }
      state.vendorTxnsQueryURLParams.year = selectedYear
    })
  }

  function disabledDate(current: Moment) {
    if (isTrialUser) {
      const disableYearsBeforeTrailUserRange =
        current.year() < moment(TRIAL_USER_SEARCH_DATE_RANGE.fromDateISO).year()
      const disableYearsAfterTrialUserRange =
        current.year() > moment(TRIAL_USER_SEARCH_DATE_RANGE.toDateISO).year()
      return disableYearsBeforeTrailUserRange || disableYearsAfterTrialUserRange
    }
    const disableYearGreaterThanPresentYear =
      current.year() > new Date().getFullYear()
    return disableYearGreaterThanPresentYear
  }

  return (
    <DatePicker
      onChange={handleOnChange}
      picker="year"
      defaultValue={moment(defaultVendorTxnsQueryURLParams.year, 'yyyy')}
      allowClear={false}
      disabledDate={disabledDate}
    />
  )
}

function MaterialSelector() {
  const vendorInfo = useVendorInfo()
  const defaultMaterial = useDefaultMaterial()
  const materialsFilterOptions = useMemo(
    () =>
      vendorInfo.molecules.map(({ id, name }) => ({
        value: name,
        id,
      })),
    [vendorInfo]
  )

  function handleOnChange(
    value: string,
    optionData: { id: number; value: string }
  ) {
    vendorTxnsParamsStoreAPI.getState().set((state) => {
      if (state.selectedMaterial === null) {
        state.selectedMaterial = defaultMaterial
      }
      state.selectedMaterial.id = optionData.id
      state.selectedMaterial.name = value
    })
  }

  return (
    <Select
      defaultValue={defaultMaterial.name}
      style={{ width: 180 }}
      options={materialsFilterOptions}
      // @ts-ignore
      onChange={handleOnChange}
    />
  )
}

function MaterialSelectorWrapper() {
  const selectedMaterial = useSelectedMaterial()
  return (
    <ErrorBoundryAndSuspenseFallback
      errorFallbackRender={(props) => {
        return (
          <Select
            defaultValue={selectedMaterial.name}
            style={{ width: 180 }}
            notFoundContent={
              <DefaultErrorFallback
                {...props}
                customMessage="Could not retrieve material list from server due to an error"
              />
            }
          />
        )
      }}
      suspenseFallback={
        <Select
          defaultValue={selectedMaterial.name}
          style={{ width: 180 }}
          loading
          notFoundContent={<Skeleton active />}
        />
      }
    >
      <MaterialSelector />
    </ErrorBoundryAndSuspenseFallback>
  )
}

export default function VendorDetails() {
  const history = useHistory()

  const { vendorName } = useVendorDetailsDescription()

  return (
    <Layout id="vendor-details-page">
      <Header />
      <Layout.Content>
        <PageHeader onBack={history.goBack} title={vendorName} />
        <Space direction="vertical" size="large" style={{ width: '100%' }}>
          <ErrorBoundryAndSuspenseFallback>
            <VendorDescription />
          </ErrorBoundryAndSuspenseFallback>
          <Tabs
            defaultActiveKey="1"
            tabBarExtraContent={
              <Space size="large">
                <YearSelector />
                <MonthSelector />
                <MaterialSelectorWrapper />
              </Space>
            }
          >
            <TabPane tab="Details of transactions" key="1">
              <VendorTransactionsTableWrapper />
            </TabPane>
          </Tabs>
        </Space>
      </Layout.Content>
    </Layout>
  )
}

const dataIndex: { [prop in keyof VendorTransaction]: prop } = {
  id: 'id',
  accessValue: 'accessValue',
  beDate: 'beDate',
  importerCountry: 'importerCountry',
  importerName: 'importerName',
  quantity: 'quantity',
  supplierName: 'supplierName',
  unit: 'unit',
  unitPrice: 'unitPrice',
  grade: 'grade',
  txnType: 'txnType',
  hsCode: 'hsCode',
  productDescription: 'productDescription',
}

function VendorTransactionsTableWrapper() {
  const selectedMaterialId = useSelectedMaterial().id
  const queryURLParams = useSelectedVendorTxnQueryURLParams()
  return (
    <ErrorBoundryAndSuspenseFallback
      resetKeys={[
        selectedMaterialId,
        queryURLParams.month,
        queryURLParams.year,
      ]}
    >
      <VendorTransactionsTable />
    </ErrorBoundryAndSuspenseFallback>
  )
}

function VendorTransactionsTable() {
  const { vendorTxns, selectedMaterial, year, month } = useVendorTxns()
  const title = `${getMonthName(month)} ${year}`
  const { unit } = useVendorDetailsDescription()

  const vendorTxnsTableColumns: ColumnsType<VendorTransaction> = [
    {
      title: 'Sr. No',
      dataIndex: undefined,
      render: (_, __, rowIndex) => {
        return rowIndex + 1
      },
      width: '7%',
    },
    {
      title: 'Material',
      dataIndex: dataIndex.grade,
      render: (grade: VendorTransaction['grade']) =>
        `${selectedMaterial.name} (${grade})`,
      width: '14%',
    },
    {
      title: 'Buyer',
      dataIndex: dataIndex.importerName,
      width: '21%',
      render: (buyerName: VendorTransaction['importerName']) => {
        if (buyerName === null) {
          return DEFUALT_FOR_EMPTY_OR_NULL
        }
        const { filteredCustomers } = filterCustomers({
          customers: [buyerName],
        })

        if (filterCustomers.length === 0) {
          return DEFUALT_FOR_EMPTY_OR_NULL
        }

        return filteredCustomers[0]
      },
    },
    {
      title: 'Buyer location',
      dataIndex: dataIndex.importerCountry,
      width: '21%',
      render: (buyerLocation: VendorTransaction['importerCountry']) => {
        if (buyerLocation === null) {
          return DEFUALT_FOR_EMPTY_OR_NULL
        }
        return buyerLocation
      },
    },
    {
      title: <PriceColumnHeader unit={WEIGHT_LABELS[unit]} />,
      dataIndex: dataIndex.unitPrice,
      render: (unitPrice: VendorTransaction['unitPrice'], { txnType }) => {
        return (
          <CurrencyScalar
            value={convertPricePerUnitOfWeight(
              unitPrice,
              WEIGHT_SYMBOLS.KGS,
              unit
            )}
            txnType={txnType}
          />
        )
      },
      width: '10%',
      sorter: (prev, next) => {
        if (isNumber(prev.unitPrice) && isNumber(next.unitPrice)) {
          return prev.unitPrice - next.unitPrice
        }
        return 0
      },
      defaultSortOrder: 'ascend',
      sortDirections: ['ascend', 'descend', 'ascend'],
      className: 'unit-price-column-header',
    },
    {
      title: `Qty transacted (${WEIGHT_LABELS[unit]})`,
      dataIndex: dataIndex.quantity,
      render: (quantity: VendorTransaction['quantity']) => {
        return (
          <QuantityScalar
            value={convertWeight(quantity, WEIGHT_SYMBOLS.KGS, unit)}
          />
        )
      },
      width: '10%',
      sorter: (prev, next) => {
        if (isNumber(prev.quantity) && isNumber(next.quantity)) {
          return prev.quantity - next.quantity
        }
        return 0
      },
      sortDirections: ['ascend', 'descend', 'ascend'],
    },
    {
      title: 'Date of transaction',
      dataIndex: dataIndex.beDate,
      render: (dateISOString: VendorTransaction['beDate']) => {
        return format(parseISO(dateISOString), 'dd MMM yyyy')
      },
      width: '14%',
      sorter: (prev, next) => {
        if (isAfter(parseISO(next.beDate), parseISO(prev.beDate))) {
          return -1
        } else {
          return 1
        }
      },
      sortDirections: ['ascend', 'descend', 'ascend'],
    },
  ]

  function CustomRow({
    vendorTxn,
    ...defaultProps
  }: { vendorTxn: VendorTransaction } & TableDefaultRowProps) {
    return (
      <VendorRowTooltip vendor={vendorTxn}>
        <tr {...defaultProps} />
      </VendorRowTooltip>
    )
  }

  return (
    <Table
      title={() => <Typography.Title level={4}>{title}</Typography.Title>}
      columns={vendorTxnsTableColumns}
      // 🔥 IMPORTANT NOTE 🔥
      // vendorTxns.length === 0 check is important, otherwise <CustomRow /> comp will get called with `vendorTTxn` as undefined and BLOW UP THE PAGE!
      components={
        vendorTxns.length === 0
          ? undefined
          : {
              body: {
                row: CustomRow,
              },
            }
      }
      onRow={(vendorTxn) => {
        return {
          vendorTxn,
          onClick: () => {},
        }
      }}
      dataSource={vendorTxns}
      bordered={false}
      size="middle"
      pagination={{
        defaultPageSize: 10,
        position: ['topRight'],
        style: {
          position: 'absolute',
          right: 0,
          top: 16,
          zIndex: 2,
          margin: 0,
        },
      }}
      rowKey={(txn) => txn.id}
      id="vendor-txns-table"
    />
  )
}
