import { useMutation, useQueryCache } from 'react-query'
import {
  createNewSearchResults,
  SearchItemResults,
  SearchResultsAPIRequestBody,
} from 'api/search'
import {
  getSavedSearchResultsQueryKey,
  useSavedSearchResultsQuery,
} from 'utils/reactQuery'
import { message, Select, Space, Typography, DatePicker, Skeleton } from 'antd'
import React, {
  useState,
  useEffect,
  useMemo,
  ReactNode,
  ReactElement,
} from 'react'
import moment, { Moment } from 'moment'
import {
  ACCEPTED_PERCENTILE_VALUES,
  TRIAL_USER_SEARCH_DATE_RANGE,
} from '../../../../constants'
import { InfoTooltip } from 'components/Layout/Layout'
import { useSearchItemsResultsStore } from './Filters.state'
import { uniqBy, uniq } from 'lodash'
import { useSearchId } from 'utils/hooks'
import { ErrorBoundryAndSuspenseFallback } from 'components/Fallback/Fallback'
import { useAppWideStore } from 'App.state'

type SelectedMaterial = {
  value: string
  id: number
}

function massageSearchItemsRequestFromSearchItemsResults({
  searchItemsResults,
}: {
  searchItemsResults: Array<SearchItemResults>
}): SearchResultsAPIRequestBody['data'] {
  const searchItemsRequest: SearchResultsAPIRequestBody['data'] = []
  for (const {
    molecule: { id: moleculeId },
    totalAnnualizedVolume,
    type,
    currentPricingPerUnit,
    grade,
    hsCode,
    unit,
  } of searchItemsResults) {
    searchItemsRequest.push({
      currentPricingPerUnit,
      grade,
      hsCode,
      moleculeId,
      totalAnnualizedVolume,
      type,
      unit,
    })
  }
  return searchItemsRequest
}

function useSearchResultsFilterMutation() {
  const searchId = useSearchId()

  const queryCache = useQueryCache()

  const [applyFilter, { status: filterApplicationStatus }] = useMutation(
    createNewSearchResults,
    {
      onSuccess: (dataAfterFilterApplication) => {
        queryCache.setQueryData(
          getSavedSearchResultsQueryKey({ searchId }),
          dataAfterFilterApplication
        )
        message.success('Successfully applied the filter')
      },
      throwOnError: true,
    }
  )

  const searchResults = useSavedSearchResultsQuery()

  const searchItemsRequest = useMemo(
    () =>
      massageSearchItemsRequestFromSearchItemsResults({
        searchItemsResults: searchResults?.searchItems ?? [],
      }),
    [searchResults]
  )

  function filterSearchResultsMutation({
    fromDateISO,
    toDateISO,
    percentile,
  }: {
    fromDateISO?: string
    toDateISO?: string
    percentile?: number
  }) {
    if (searchResults === undefined) {
      message.warning(
        'Could not make filter API request due to unexpected missing of query cache data'
      )
      throw Error(
        'Could not make filter API request due to unexpected missing of query cache data'
      )
    }

    const { currencyCode, unit, excludeMin, excludeMax } = searchResults

    return applyFilter({
      searchItems: searchItemsRequest,
      currencyCode,
      searchId,
      fromDateISO: fromDateISO ?? searchResults.fromDate,
      toDateISO: toDateISO ?? searchResults.toDate,
      percentile: percentile ?? searchResults.percentile,
      unit,
      excludeMin,
      excludeMax,
    })
  }

  useEffect(function showFilterApplicationLoader() {
    if (filterApplicationStatus === 'loading') {
      message.loading('Applying filter...')
    }
  })

  return { filterSearchResultsMutation, filterApplicationStatus }
}

function getUniqueMaterialNameOptions({
  searchItemsResults,
}: {
  searchItemsResults: Array<SearchItemResults>
}) {
  return uniqBy(
    searchItemsResults.map(({ molecule }) => molecule),
    'id'
  ).map(({ id, name }) => ({
    value: name,
    id,
  }))
}

function getUniqueGradeOptions({
  searchItemsResults,
}: {
  searchItemsResults: Array<SearchItemResults>
}) {
  return uniq(searchItemsResults.map(({ grade }) => grade).flat(1)).map(
    (grade) => ({
      value: grade,
    })
  )
}

function MaterialSelector() {
  const { dispatch, selectedMaterial } = useSearchItemsResultsStore(
    ({ dispatch, selectedMaterial }) => ({
      dispatch,
      selectedMaterial,
    })
  )

  const searchResultsAPIData = useSavedSearchResultsQuery()

  const materialNamesFilterOptions = useMemo(
    () =>
      getUniqueMaterialNameOptions({
        searchItemsResults: searchResultsAPIData.searchItems,
      }),
    [searchResultsAPIData]
  )

  return (
    <Select
      allowClear
      placeholder="Filter by material"
      defaultValue={undefined}
      value={selectedMaterial?.name}
      options={materialNamesFilterOptions}
      onClear={() => dispatch({ type: 'RESET MATERIAL NAME FILTER' })}
      // @ts-ignore
      onSelect={(_, optionData: SelectedMaterial) => {
        const { id, value: name } = optionData
        dispatch({
          type: 'FILTER BY MATERIAL NAME',
          payload: {
            material: { id, name },
          },
        })
      }}
    />
  )
}

function GradeSelector() {
  const { dispatch, selectedGrade } = useSearchItemsResultsStore(
    ({ dispatch, selectedGrade }) => ({
      dispatch,
      selectedGrade,
    })
  )

  const searchResultsAPIData = useSavedSearchResultsQuery()

  const gradesFilterOptions = useMemo(
    () =>
      getUniqueGradeOptions({
        searchItemsResults: searchResultsAPIData?.searchItems ?? [],
      }),
    [searchResultsAPIData]
  )

  return (
    <Select
      defaultValue={undefined}
      value={selectedGrade ?? undefined}
      options={gradesFilterOptions}
      onClear={() => dispatch({ type: 'RESET GRADE FILTER' })}
      onSelect={(value) =>
        dispatch({ type: 'FILTER BY GRADE', payload: { grade: value } })
      }
      placeholder="Filter by grade"
      allowClear
    />
  )
}

function trialUserDiasbleDate(current: Moment) {
  const disableMonthsBeforeTrialRange =
    current < moment(TRIAL_USER_SEARCH_DATE_RANGE.fromDateISO)
  const disableMonthsAfterTrialRange =
    current > moment(TRIAL_USER_SEARCH_DATE_RANGE.toDateISO)
  return disableMonthsBeforeTrialRange || disableMonthsAfterTrialRange
}

function FromDateSelector() {
  const { isTrialUser } = useAppWideStore()

  const { filterSearchResultsMutation } = useSearchResultsFilterMutation()

  const {
    fromDate: fromDateISO,
    toDate: toDateISO,
  } = useSavedSearchResultsQuery()

  const [fromDateMoment, setFromDateMoment] = useState(moment(fromDateISO))

  async function handleOnChange(selectedFromDateMoment: Moment | null) {
    if (selectedFromDateMoment === null) {
      return
    }

    if (selectedFromDateMoment.isAfter(moment(toDateISO))) {
      message.error('From date cannot be AFTER To date')
      return
    }

    setFromDateMoment(selectedFromDateMoment)
    try {
      await filterSearchResultsMutation({
        fromDateISO: selectedFromDateMoment.toISOString(),
      })
    } catch (error) {
      message.warn("Resetting the 'From Date' filter to it's previous value")
      setFromDateMoment(fromDateMoment)
    }
  }

  function disabledDate(current: Moment) {
    if (!isTrialUser) {
      return false
    }

    return trialUserDiasbleDate(current)
  }

  return (
    <DatePicker
      placeholder="Select from date"
      picker="month"
      value={fromDateMoment}
      defaultValue={fromDateMoment}
      onChange={handleOnChange}
      allowClear={false}
      disabledDate={disabledDate}
    />
  )
}

function ToDateSelector() {
  const { isTrialUser } = useAppWideStore()

  const { filterSearchResultsMutation } = useSearchResultsFilterMutation()

  const {
    toDate: toDateISO,
    fromDate: fromDateISO,
  } = useSavedSearchResultsQuery()

  const [toDateMoment, setToDateMoment] = useState(moment(toDateISO))

  async function handleOnChange(selectedToDateMoment: Moment | null) {
    if (selectedToDateMoment === null) {
      return
    }

    if (selectedToDateMoment.isBefore(moment(fromDateISO))) {
      message.error('To date cannot be BEFORE From date')
      return
    }
    setToDateMoment(selectedToDateMoment)
    try {
      await filterSearchResultsMutation({
        toDateISO: selectedToDateMoment.toISOString(),
      })
    } catch (error) {
      message.warn("Resetting the 'To Date' filter to it's previous value")
      setToDateMoment(toDateMoment)
    }
  }

  function disabledDate(current: Moment) {
    if (!isTrialUser) {
      return false
    }

    return trialUserDiasbleDate(current)
  }

  return (
    <DatePicker
      placeholder="Select to date"
      picker="month"
      defaultValue={toDateMoment}
      value={toDateMoment}
      onChange={handleOnChange}
      allowClear={false}
      disabledDate={disabledDate}
    />
  )
}

const PERCENTILE_OPTIONS = ACCEPTED_PERCENTILE_VALUES.map((value) => ({
  value,
}))

function PercentileFilter() {
  const { filterSearchResultsMutation } = useSearchResultsFilterMutation()

  const [percentile, setPercentile] = useState(
    useSavedSearchResultsQuery().percentile
  )

  async function handleOnChange(value: number) {
    setPercentile(value)
    try {
      await filterSearchResultsMutation({
        percentile: value,
      })
    } catch (error) {
      message.warn("Resetting the 'Percentile' filter to it's previous value")
      setPercentile(percentile)
    }
  }

  return (
    <Select
      value={percentile}
      defaultValue={percentile}
      options={PERCENTILE_OPTIONS}
      onChange={handleOnChange}
    />
  )
}

function FilterWrapper({
  label,
  filter,
  errorFallback,
}: {
  label: ReactNode
  filter: ReactNode
  errorFallback: ReactElement
}) {
  return (
    <label>
      <Space size="middle">
        <Typography.Text type="secondary">{label}</Typography.Text>
        <ErrorBoundryAndSuspenseFallback
          suspenseFallback={<Skeleton.Input active style={{ width: 135 }} />}
          errorFallbackRender={(props) => {
            return errorFallback
          }}
        >
          {filter}
        </ErrorBoundryAndSuspenseFallback>
      </Space>
    </label>
  )
}

export function PercentileFilterWrapper() {
  return (
    <FilterWrapper
      label={
        <>
          Percentile{' '}
          <InfoTooltip message="0 refers to minimum price benchmark, 25 refers to top quartile price benchmark" />
        </>
      }
      filter={<PercentileFilter />}
      errorFallback={<Select disabled />}
    />
  )
}

export function ToDateWrapper() {
  return (
    <FilterWrapper
      label="To Date"
      filter={<ToDateSelector />}
      errorFallback={<DatePicker disabled />}
    />
  )
}

export function FromDateWrapper() {
  return (
    <FilterWrapper
      label="From Date"
      filter={<FromDateSelector />}
      errorFallback={<DatePicker disabled />}
    />
  )
}

export function MaterialSelectorWrapper() {
  return (
    <ErrorBoundryAndSuspenseFallback
      suspenseFallback={<Skeleton.Input active style={{ width: 135 }} />}
      errorFallbackRender={() => (
        <Select disabled placeholder="Filter by material" />
      )}
    >
      <MaterialSelector />
    </ErrorBoundryAndSuspenseFallback>
  )
}

export function GradeSelectorWrapper() {
  return (
    <ErrorBoundryAndSuspenseFallback
      suspenseFallback={<Skeleton.Input active style={{ width: 135 }} />}
      errorFallbackRender={() => (
        <Select disabled placeholder="Filter by grade" />
      )}
    >
      <GradeSelector />
    </ErrorBoundryAndSuspenseFallback>
  )
}
