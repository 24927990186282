import React from 'react'
import { CheckCircleTwoTone } from '@ant-design/icons'
import './SuggestedVendorsTable.less'
import { MaterialSupplier } from 'api/search'
import { Button, Table, Tooltip, Space } from 'antd'
import {
  useToggleVendorInclusionStatus,
  useSearchItemResults,
} from 'pages/Review/Review.hooks'
import { ColumnsType } from 'antd/lib/table'
import isNumber from 'lodash/isNumber'
import { CurrencyScalar } from 'components/Scalars/Scalars'
import {
  MATERIAL_SUPPLIER_PROPS,
  DEFUALT_FOR_EMPTY_OR_NULL,
  WEIGHT_LABELS,
  WEIGHT_SYMBOLS,
} from '../../../../constants'
import { PriceColumnHeader } from 'components/Layout/Layout'
import { convertPricePerUnitOfWeight } from 'utils/helperFunctions'

export default function SuggestedVendorsTable() {
  const { searchResults: vendors, unit } = useSearchItemResults()

  console.log('[SEARCH RES] searchResults ', unit)

  const [toggleInclusionStatus] = useToggleVendorInclusionStatus()
  const suggestedVendorsTableData = vendors.filter((vendor) => vendor.included)

  const suggestedVendorsTableColumns: ColumnsType<MaterialSupplier> = [
    {
      title: 'VENDOR',
      dataIndex: MATERIAL_SUPPLIER_PROPS.name,
    },
    {
      title: 'VENDOR COUNTRY',
      dataIndex: MATERIAL_SUPPLIER_PROPS.supplierCountry,
      render: (vendorCountry: MaterialSupplier['supplierCountry']) => {
        if (vendorCountry === null) {
          return DEFUALT_FOR_EMPTY_OR_NULL
        }

        return vendorCountry
      },
    },
    {
      title: <PriceColumnHeader capitalize unit={WEIGHT_LABELS[unit]} />,
      dataIndex: MATERIAL_SUPPLIER_PROPS.unitPrice,
      render: (price: number | null, { txnType }) => {
        return (
          <CurrencyScalar
            value={convertPricePerUnitOfWeight(price, WEIGHT_SYMBOLS.KGS, unit)}
            txnType={txnType}
          />
        )
      },
      sorter: (prev, next) => {
        if (isNumber(prev.unitPrice) && isNumber(next.unitPrice)) {
          return prev.unitPrice - next.unitPrice
        }
        return 0
      },
      defaultSortOrder: 'ascend',
      sortDirections: ['ascend', 'descend', 'ascend'],
      className: 'unit-price-column-header',
    },
    {
      title: 'SAVINGS PERCENTAGE',
      dataIndex: MATERIAL_SUPPLIER_PROPS.savingsPercentage,
      render: (savingsPercentage: number | null) => {
        return isNumber(savingsPercentage)
          ? `${savingsPercentage.toFixed(1)} %`
          : '-'
      },
      sorter: (prev, next) => {
        if (
          isNumber(prev.savingsPercentage) &&
          isNumber(next.savingsPercentage)
        ) {
          return prev.savingsPercentage - next.savingsPercentage
        }
        return 0
      },
      sortDirections: ['ascend', 'descend', 'ascend'],
    },
    {
      title: 'ACTION',
      dataIndex: MATERIAL_SUPPLIER_PROPS.isExpertData,
      render: (isExpertData: boolean, vendor: MaterialSupplier) => {
        const {
          searchItemId,
          supplierId: vendorId,
          included,
          searchId,
        } = vendor
        function handleExcludeOnClick() {
          toggleInclusionStatus({
            vendorId,
            included: !included,
            searchItemId,
            searchId,
          })
        }
        const noOfSuggestedVendors = suggestedVendorsTableData.length
        // Have atleat 1 suggested vendor
        if (noOfSuggestedVendors === 1) {
          return null
        }

        return (
          <Space size="small" align="center">
            <Button onClick={handleExcludeOnClick} type="link">
              X Exclude
            </Button>
            {isExpertData ? (
              <Tooltip title="Data from McKinsey experts">
                <CheckCircleTwoTone style={{ fontSize: 20 }} />
              </Tooltip>
            ) : null}
          </Space>
        )
      },
    },
  ]

  return (
    <Table
      id="suggested-vendors-table"
      title={() => 'Suggested Vendors'}
      columns={suggestedVendorsTableColumns}
      dataSource={suggestedVendorsTableData}
      rowKey={MATERIAL_SUPPLIER_PROPS.supplierId}
      pagination={false}
      size="small"
    />
  )
}
