// ref. https://mds.mckinsey.com/design/foundations/colors
export const MCK_CAHRT_COLORS = [
  {
    fill: '#034B6F',
    color: '#ffffff',
  },
  {
    fill: '#027AB1',
    color: '#ffffff',
  },
  {
    fill: '#39BDF3',
    color: '#000000',
  },
  {
    fill: '#71D2F1',
    color: '#000000',
  },
  {
    fill: '#AFC3FF',
    color: '#000000',
  },
  {
    fill: '#3C96B4',
    color: '#ffffff',
  },
  {
    fill: '#AAE6F0',
    color: '#000000',
  },
  {
    fill: '#8C5AC8',
    color: '#ffffff',
  },
  {
    fill: '#E6A0C8',
    color: '#000000',
  },
  {
    fill: '#E5546C',
    color: '#ffffff',
  },
  {
    fill: '#FAA082',
    color: '#000000',
  },
  {
    fill: '#333333',
    color: '#ffffff',
  },
  {
    fill: '#4D4D4D',
    color: '#ffffff',
  },
  {
    fill: '#757575',
    color: '#ffffff',
  },
  {
    fill: '#B3B3B3',
    color: '#000000',
  },
  {
    fill: '#D0D0D0',
    color: '#000000',
  },
  {
    fill: '#E6E6E6',
    color: '#000000',
  },
]
