import React, { useState, useMemo } from 'react'
import {
  CheckOutlined,
  ArrowRightOutlined,
  DownloadOutlined,
  CheckCircleTwoTone,
} from '@ant-design/icons'
import { MaterialSupplier } from 'api/search'
import { Button, Table, Row, Tooltip, Typography } from 'antd'
import './TotalIdentifiedVendorsTable.less'
import { useHistory } from 'react-router-dom'
import qs from 'qs'
import { parseISO } from 'date-fns'
import { ColumnsType } from 'antd/lib/table'
import {
  useToggleVendorInclusionStatus,
  useSearchItemDescription,
  useSearchItemResults,
} from 'pages/Review/Review.hooks'
import { CustomColumnFilterDropdown } from '../shared/CustomColumnFilterDropdown'
import { Choose } from 'react-extras'
import isNumber from 'lodash/isNumber'
import { VendorDetailsPageLocationState } from 'pages/VendorDetails/VendorDetails'
import {
  handleDownloadOnClick,
  TableDefaultRowProps,
  formatUnit,
  filterCustomers,
} from 'utils'
import { downloadVendorList } from 'api/search'
import {
  pagePaths,
  DEFAULT_UNIT,
  MATERIAL_SUPPLIER_PROPS,
  TRIAL_USER_SEARCH_DATE_RANGE,
  DEFUALT_FOR_EMPTY_OR_NULL,
  WEIGHT_LABELS,
  WEIGHT_SYMBOLS,
} from '../../../../constants'
import { QuantityScalar, CurrencyScalar } from 'components/Scalars/Scalars'
import produce from 'immer'
import {
  VendorRowTooltip,
  PriceColumnHeader,
  HideForTrialUser,
} from 'components/Layout/Layout'
import { useAppWideStore } from 'App.state'
import moment from 'moment'
import {
  convertPricePerUnitOfWeight,
  convertWeight,
} from 'utils/helperFunctions'
const { When, Otherwise } = Choose

function getColumnFilterOptions({
  filteredVendors,
}: {
  filteredVendors: MaterialSupplier[]
}) {
  const customerOptionsDraft = new Set<string>()
  const supplierCountryOptionsDraft = new Set<string>()
  for (const vendor of filteredVendors) {
    if (vendor.supplierCountry) {
      supplierCountryOptionsDraft.add(vendor.supplierCountry)
    }
    if (vendor.customers) {
      vendor.customers.forEach((customer) => {
        customerOptionsDraft.add(customer)
      })
    }
  }

  const [customerOptions, supplierCountryOptions] = [
    customerOptionsDraft,
    supplierCountryOptionsDraft,
  ]
    .map((set) => Array.from(set))
    .map((list) => list.sort().map((value) => ({ value, text: value })))

  return {
    customerOptions,
    supplierCountryOptions,
  }
}

function filterVendorCustomers({ vendors }: { vendors: MaterialSupplier[] }) {
  return produce(vendors, (draft) => {
    for (const vendor of draft) {
      const { filteredCustomers } = filterCustomers({
        customers: vendor.customers ?? [],
      })
      vendor.customers = filteredCustomers
    }
  })
}

function VendorListDownloadLink() {
  const { searchId, searchItemId } = useSearchItemDescription()

  return (
    <Button
      type="link"
      size="large"
      onClick={() =>
        handleDownloadOnClick({
          downloadAPI: () => downloadVendorList({ searchId, searchItemId }),
        })
      }
    >
      Download <DownloadOutlined />
    </Button>
  )
}

export default function TotalIdentifiedVendorsTable() {
  const { currencyCode, molecule } = useSearchItemDescription()

  const { searchResults: vendors, unit } = useSearchItemResults()
  const { isTrialUser } = useAppWideStore()

  const {
    filteredVendors,
    customerOptions,
    supplierCountryOptions,
    noOfSuggestedVendors,
  } = useMemo(() => {
    const filteredVendors = filterVendorCustomers({ vendors })
    const { customerOptions, supplierCountryOptions } = getColumnFilterOptions({
      filteredVendors,
    })
    const noOfSuggestedVendors = filteredVendors.filter(
      (vendor) => vendor.included
    ).length
    return {
      filteredVendors,
      customerOptions,
      supplierCountryOptions,
      noOfSuggestedVendors,
    }
  }, [vendors])

  const [currentPage, setCurrentPage] = useState(1) // 1 is the default page number to show

  const history = useHistory()

  const [toggleInclusionStatus] = useToggleVendorInclusionStatus()

  const columns: ColumnsType<MaterialSupplier> = [
    {
      title: 'Vendor',
      dataIndex: MATERIAL_SUPPLIER_PROPS.name,
    },
    {
      title: 'Vendor Country',
      dataIndex: MATERIAL_SUPPLIER_PROPS.supplierCountry,
      render: (vendorCountry: MaterialSupplier['supplierCountry']) => {
        if (vendorCountry === null) {
          return DEFUALT_FOR_EMPTY_OR_NULL
        }

        return vendorCountry
      },
      onFilter: (value, record) => {
        return record.supplierCountry === value
      },
      filters: supplierCountryOptions,
      filterDropdown: CustomColumnFilterDropdown,
    },
    {
      title: <PriceColumnHeader unit={WEIGHT_LABELS[unit]} />,
      dataIndex: MATERIAL_SUPPLIER_PROPS.unitPrice,
      render: (price: number | null, { txnType }) => {
        return (
          <CurrencyScalar
            value={convertPricePerUnitOfWeight(price, WEIGHT_SYMBOLS.KGS, unit)}
            txnType={txnType}
          />
        )
      },
      sorter: (prev, next) => {
        if (isNumber(prev.unitPrice) && isNumber(next.unitPrice)) {
          return prev.unitPrice - next.unitPrice
        }
        return 0
      },
      defaultSortOrder: 'ascend',
      sortDirections: ['ascend', 'descend', 'ascend'],
      className: 'unit-price-column-header',
    },
    {
      title: `Qty Transacted (${WEIGHT_LABELS[unit]})`,
      dataIndex: MATERIAL_SUPPLIER_PROPS.quantity,
      render: (quantity: number | null) => {
        return (
          <QuantityScalar
            value={convertWeight(quantity, WEIGHT_SYMBOLS.KGS, unit)}
          />
        )
      },
    },
    {
      title: 'Customers',
      dataIndex: MATERIAL_SUPPLIER_PROPS.customers,
      render: function CollaplibleCustomersList(customerList: string[] | null) {
        const [isExpanded, setIsExpanded] = useState(false)
        const [key, setKey] = useState(0)
        const displayString = useMemo(() => customerList?.join(', '), [
          customerList,
        ])
        function onExpand() {
          setIsExpanded(true)
        }
        function onSeeLess() {
          setKey((key) => key + 1)
        }
        return (
          <Typography.Paragraph
            ellipsis={{
              rows: 3,
              expandable: true,
              symbol: (
                <Typography.Link strong underline>
                  See more
                </Typography.Link>
              ),
              onExpand,
            }}
            key={key}
          >
            {displayString}{' '}
            {isExpanded && (
              <Typography.Link underline strong onClick={onSeeLess}>
                See less
              </Typography.Link>
            )}
          </Typography.Paragraph>
        )
      },
      onFilter: (value, record) => {
        return Boolean(record.customers?.includes(value as string))
      },
      filters: customerOptions,
      filterDropdown: CustomColumnFilterDropdown,
    },
    {
      title: 'Action',
      dataIndex: MATERIAL_SUPPLIER_PROPS.included,
      render: (
        isIncluded: boolean,
        vendor: MaterialSupplier,
        index: number
      ) => {
        const {
          beDate,
          searchItemId,
          supplierId: vendorId,
          included,
          searchId,
        } = vendor
        function handleOnClick() {
          toggleInclusionStatus({
            included: !included,
            searchId,
            searchItemId,
            vendorId,
          })
        }
        const date = parseISO(beDate)
        const month = date.getMonth()
        const year = date.getFullYear()

        function handleVendorDetailsOnClick() {
          const vendorDetailsPageLocationState: VendorDetailsPageLocationState = {
            searchId,
            vendorId,
            molecule,
            vendorName: vendor.name,
            month,
            year,
            unit: unit,
            currencyCode,
          }
          if (isTrialUser) {
            vendorDetailsPageLocationState.year = moment(
              TRIAL_USER_SEARCH_DATE_RANGE.fromDateISO
            ).year()
          }
          const querySearchParamas = qs.stringify(
            vendorDetailsPageLocationState,
            {
              addQueryPrefix: true,
              strictNullHandling: true,
            }
          )
          history.push(
            `${pagePaths.vendor}${querySearchParamas}`,
            vendorDetailsPageLocationState
          )
        }

        return (
          <Row
            justify="space-between"
            align="middle"
            style={{ width: 'max-content' }}
          >
            <Choose>
              <When condition={noOfSuggestedVendors === 1 && isIncluded}>
                {<div></div>}
              </When>
              <Otherwise>
                <Button onClick={handleOnClick} type="link">
                  {isIncluded ? (
                    'X Exclude'
                  ) : (
                    <>
                      <CheckOutlined /> Include
                    </>
                  )}
                </Button>
              </Otherwise>
            </Choose>
            <ArrowRightOutlined onClick={handleVendorDetailsOnClick} />
          </Row>
        )
      },
    },
    {
      title: '',
      dataIndex: MATERIAL_SUPPLIER_PROPS.isExpertData,
      render: (isExpertData: boolean) => {
        return isExpertData ? (
          <Tooltip title="Data from McKinsey experts">
            <CheckCircleTwoTone style={{ fontSize: 20 }} />
          </Tooltip>
        ) : null
      },
    },
  ]

  function CustomRow({
    vendor,
    ...defaultProps
  }: { vendor: MaterialSupplier } & TableDefaultRowProps) {
    return (
      <VendorRowTooltip vendor={vendor}>
        <tr {...defaultProps} />
      </VendorRowTooltip>
    )
  }

  return (
    <Table
      id="total-identified-vendors-table"
      title={() => (
        <Row
          justify="space-between"
          align="middle"
          className="table-custom-comp"
        >
          {`Exact Results (${vendors.length})`.toUpperCase()}
          <HideForTrialUser>
            <VendorListDownloadLink />
          </HideForTrialUser>
        </Row>
      )}
      // 🔥 IMPORTANT NOTE 🔥
      // filteredVendors.length === 0 check is important, otherwise <CustomRow /> comp will get called with `vendor` as undefined and BLOW UP THE PAGE!
      components={
        filteredVendors.length === 0
          ? undefined
          : {
              body: {
                row: CustomRow,
              },
            }
      }
      columns={columns}
      dataSource={filteredVendors}
      onRow={(vendor) => {
        return {
          vendor,
          className: vendor.included ? 'included-vendor-row' : '',
        }
      }}
      pagination={{
        defaultPageSize: 10,
        position: ['topRight'],
        defaultCurrent: 1,
        current: currentPage,
        style: {
          position: 'absolute',
          right: isTrialUser ? 15 : 150, // is its a trial user, download link wont be rendered, so push the pagination comp to the right
          zIndex: 2,
          margin: 0,
          top: 8,
        },
        onChange: (page) => {
          setCurrentPage(page)
        },
      }}
      size="small"
      rowKey={MATERIAL_SUPPLIER_PROPS.supplierId}
    />
  )
}
