import React, { useRef } from 'react'

import { Table, Tooltip, Space, Button, Typography } from 'antd'
import { DownloadOutlined } from '@ant-design/icons'
// import { DummySupplierTableData } from './DummySupplierTableData'
import { WEIGHT_LABELS, WEIGHT_SYMBOLS } from '../../../../constants'
import { useSearchItemDescription } from 'pages/Review/Review.hooks'
import { toPng } from 'html-to-image'
import { saveAs } from 'file-saver'
import {
  convertPricePerUnitOfWeight,
  getMonthNameFromDateString,
} from 'utils/helperFunctions'

// const data = DummySupplierTableData

export default function CountriesExportingToIndiaTable({
  fromDate,
  country,
  toDate,
  tableData,
}: {
  fromDate: string
  country: string | null
  toDate: string
  tableData: any[]
}) {
  const { unit, currencyCode } = useSearchItemDescription()
  const tableElRef = useRef<any>(null)

  const sortedData = tableData.sort(function (a, b) {
    var x = a['averagePrice']
    var y = b['averagePrice']
    return x < y ? -1 : x > y ? 1 : 0
  })

  const columns = [
    {
      title: 'Source Countries',
      dataIndex: 'country',
      key: 'country',
      width: '250px',
      render: (text: any) => (
        <span>{text === 'null' || !text ? 'NOT KNOWN' : text}</span>
      ),
    },
    {
      title: `Average Price (${currencyCode}/${WEIGHT_LABELS[unit]})`,
      dataIndex: 'averagePrice',
      key: 'averagePrice',
      render: (text: any) => (
        <span>
          {convertPricePerUnitOfWeight(text, WEIGHT_SYMBOLS.KGS, unit)?.toFixed(
            2
          )}
        </span>
      ),
      width: '120px',
    },
    {
      title: 'Key suppliers',
      dataIndex: 'topSuppliers',
      key: 'topSuppliers',
      // ellipsis: {
      //   showTitle: false,
      // },
      render: (text: any) => (
        <Tooltip placement="topLeft" title={text.join(', ')}>
          {text.join(', ')}
        </Tooltip>
      ),
    },
  ]

  const handleDownload = () => {
    const filter = (node: any) => {
      if (node.tagName) {
        const exclusionClasses = ['dont-download']
        return !exclusionClasses.some((classname) =>
          node.classList.contains(classname)
        )
      }
      return true
    }

    toPng(tableElRef.current, { filter })
      .then((dataUrl) => {
        // console.log(dataUrl)
        try {
          saveAs(
            dataUrl,
            `countries-exporting-to-${country?.split(' ').join('-')}.png`
          )
        } catch (e) {
          saveAs(dataUrl, `${Date.now()}.png`)
        }
      })
      .catch(function (error) {
        console.error('oops, something went wrong!', error)
      })
  }

  const getTitle = () => {
    const fromYear = new Date(fromDate).getFullYear()
    const toYear = new Date(toDate).getFullYear()
    const fromMonth = getMonthNameFromDateString(fromDate)
    const toMonth = getMonthNameFromDateString(toDate)

    if (fromYear === toYear && fromMonth === toMonth) {
      return `Comparison for ${fromMonth} ${fromYear}`
    } else if (fromYear === toYear) {
      return `Comparison for ${fromMonth} - ${toMonth} ${fromYear}`
    } else {
      return `Comparison for ${fromMonth} ${fromYear}  - ${toMonth} ${toYear}`
    }
  }

  return (
    <div ref={tableElRef} style={{ backgroundColor: '#fff' }}>
      <div
        style={{
          marginBottom: '15px',
          display: 'flex',
          justifyContent: 'space-between',
        }}
      >
        <Typography.Title level={5} style={{ marginBottom: 0 }}>
          {getTitle()}
        </Typography.Title>
        <Button
          type="primary"
          className="dont-download"
          onClick={handleDownload}
          size={'small'}
        >
          <DownloadOutlined />
          Download
        </Button>
      </div>

      <div>
        <Table
          pagination={false}
          size="small"
          columns={columns}
          dataSource={sortedData}
        />
      </div>
    </div>
  )
}
